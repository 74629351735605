import { ServicesManager } from '@ohif/core';
import { DICOM_JSON_EVENTS } from './services/DicomJSONService';
import { Internal } from '@gleamer/types';
import { labelPass } from '@gleamer/ui';

type LabelTaskItem = Internal.LabelTaskItem;

export function initDefaultValues(servicesManager: ServicesManager) {
  const { DicomJSONService, ReduxStoreService } = servicesManager.services;

  DicomJSONService.subscribe(
    DICOM_JSON_EVENTS.UPDATE_CURRENT_ITEM,
    currentItem => {
      const { labelTask } = ReduxStoreService.getState();
      if (labelTask.task) {
        ReduxStoreService.dispatch(
          labelPass.actions.setDefaultCharacterisationValues({
            labelTask: labelTask.task,
            labelTaskItem: currentItem as LabelTaskItem,
          })
        );
      }
    }
  );
}
