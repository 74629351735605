import { ServicesManager } from '@ohif/core';
import React, { PropsWithChildren, useEffect } from 'react';
import { useCurrentItem } from '../../../hooks/useCurrentItem';
import { UserActivityService } from '../../../services/UserActivityService';

export function UserActivityTaskListener({
  children,
  servicesManager,
}: PropsWithChildren<{
  servicesManager: ServicesManager;
}>) {
  const { UserActivityService } =
    servicesManager.services as ServicesManager['services'] & {
      UserActivityService: UserActivityService;
    };
  const current = useCurrentItem({ servicesManager });
  const taskId = current?.taskId;
  const itemId = current?.id;

  useEffect(() => {
    if (taskId && itemId) {
      UserActivityService.sessionStarted(taskId, itemId);
    }
    return () => {
      UserActivityService.sessionEnded();
    };
  }, [UserActivityService, taskId, itemId]);

  return <>{children}</>;
}
