import { OHIF } from '@gleamer/types';
import { Middleware } from '@reduxjs/toolkit';
import { actions } from './labelPass.slice';
import { getItemLabelPassState } from './labelPass.selectors';

const { deleteObservation, deleteRegion } = actions;

export function measurementsMiddleware(servicesManager: OHIF.ServicesManager): Middleware {
  const { MeasurementService } = servicesManager.services;

  return store => next => action => {
    const { observations } = getItemLabelPassState(store.getState());
    const result = next(action);

    if (deleteObservation.match(action)) {
      const observationUid = action.payload;

      const obs = observations.find(observation => observation.uid === observationUid);
      if (obs) {
        obs.regions.forEach(region => {
          MeasurementService.remove(region.uid, region.source);
        });
      }
    }

    if (deleteRegion.match(action)) {
      const regionUid = action.payload;
      const region = observations.flatMap(obs => obs.regions).find(reg => reg.uid === regionUid);

      if (region) {
        MeasurementService.remove(region.uid, region.source);
      }
    }

    return result;
  };
}
