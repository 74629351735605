import classNames from 'classnames';
import React from 'react';
import { Internal } from '@gleamer/types';
type ObservationSubmission = Internal.ObservationInternal;

type ObservationsIndicatorsProps = {
  observations: ObservationSubmission[];
  className?: string;
};

export function ObservationsIndicators({
  observations,
  className,
}: ObservationsIndicatorsProps) {
  if (!observations || observations.length === 0) {
    return null;
  }

  const countByObservation = {} as Record<string, number>;
  observations.forEach(obs => {
    const key = `${obs.code} ${obs.status}`;
    if (countByObservation[key]) {
      countByObservation[key]++;
    } else {
      countByObservation[key] = 1;
    }
  });

  if (
    Object.keys(countByObservation).length >= 4 ||
    Object.values(countByObservation).some(count => count > 8)
  ) {
    return (
      <div
        className={classNames(
          'absolute flex flex-wrap items-center gap-1',
          className
        )}
      >
        {Object.entries(countByObservation).map(([obsLabel, count]) => {
          const [code, status] = obsLabel.split(' ');
          const { color } = observations.find(
            obs => obs.code === code && obs.status === status
          );
          return (
            <span
              key={obsLabel}
              className="rounded-md px-2 text-sm text-white"
              style={{ backgroundColor: color }}
            >
              x{count}
            </span>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'absolute flex flex-wrap items-center gap-1',
        className
      )}
    >
      {observations.map(obs => (
        <span
          key={obs.uid}
          className="rounded-md px-2 text-sm text-white"
          style={{ backgroundColor: obs.color }}
        >
          {obs.index}
        </span>
      ))}
    </div>
  );
}
