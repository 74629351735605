export type TaskItemId = {
  taskId?: number;
  itemId?: string;
};

export function getTaskItemId(): TaskItemId {
  const searchParams = new URLSearchParams(window.location.search);
  const urlTaskId = searchParams.get('task');
  const itemId = searchParams.get('item');

  const taskId = urlTaskId ? parseInt(urlTaskId, 10) : undefined;

  return { taskId, itemId };
}

export function getMode(): string | null {
  const location = window.location;
  const matches = location.pathname.match(/^\/ohif\/(\w+)/);
  return matches ? matches[1] : null;
}
