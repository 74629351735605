import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Internal } from '@gleamer/types';

type Tool = Internal.Tool;

type NextObs = {
  code: string;
  status: string;
  index: number;
};

type Control = {
  title: string;
  const: string;
};

export type ToolsState = {
  nextObs: {
    code: string;
    status: string;
    index: number;
  };
  tool: Tool | null;
  currentControl: Control | null;
  availableControls: Control[];
};

const initialState: ToolsState = {
  nextObs: {
    code: '',
    status: '',
    index: 0,
  },
  tool: null,
  currentControl: null,
  availableControls: [],
};

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setNextObs: (state, action: PayloadAction<NextObs>) => {
      state.nextObs = action.payload;
    },

    setTool: (state, action: PayloadAction<Tool | null>) => {
      state.tool = action.payload;
    },

    setControls: (state, action: PayloadAction<Control[]>) => {
      state.availableControls = action.payload;
      if (action.payload?.length > 0) {
        state.currentControl = action.payload[0];
      } else {
        state.currentControl = null;
      }
    },

    setNextControl: state => {
      const { availableControls, currentControl } = state;
      if (!availableControls) {
        return;
      }

      if (!currentControl) {
        state.currentControl = availableControls[0];
      } else {
        const currentIndex = availableControls.findIndex(
          control => control.const === currentControl.const
        );
        state.currentControl = availableControls[(currentIndex + 1) % availableControls.length];
      }
    },

    clear: state => {
      state.nextObs = {
        code: '',
        status: '',
        index: 0,
      };
      state.tool = null;
      state.currentControl = null;
      state.availableControls = [];
    },
  },
});

export const actions = toolsSlice.actions;
export const reducer = toolsSlice.reducer;
