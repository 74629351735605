import { ServicesManager, Types } from '@ohif/core';
import { camelCase, uniqBy } from 'lodash';
import { Internal } from '@gleamer/types';

type DicomJSONMetadata = Internal.DicomJSONMetadata;

export function getDisplaySetId(view: string, laterality: string): string {
  return camelCase(`${view} ${laterality} DisplaySet`);
}

const CRANIO_CAUDAL_VALUE = 'cranio-caudal';
const MEDIO_LATERAL_OBLIQUE_VALUE = 'medio-lateral oblique';

const RIGHT_LATERALITY = 'R';
const LEFT_LATERALITY = 'L';

const MAMMO_SOP_CLASS_UID = '1.2.840.10008.5.1.4.1.1.1.2';

function generateDiplaySetSelector(): Types.HangingProtocol.DisplaySetSelector {
  return {
    seriesMatchingRules: [
      {
        id: 'SeriesMammoModality',
        weight: 1,
        attribute: 'Modality',
        constraint: {
          equals: {
            value: 'MG',
          },
        },
        required: false,
      },
      {
        id: 'NotTomography',
        weight: 1,
        attribute: 'SOPClassUID',
        constraint: {
          notEquals: '1.2.840.10008.5.1.4.1.1.13.1.3',
        },
        required: false,
      },
    ],
  };
}

function getInstanceWithViewLaterality({
  study,
  view,
  laterality,
}: {
  study: any;
  view: string;
  laterality: string;
}) {
  for (const series of study.series) {
    for (const instance of series.instances) {
      if (
        instance.ImageLaterality === laterality &&
        instance.ViewCodeSequence?.[0]?.CodeMeaning === view &&
        instance.SOPClassUID === MAMMO_SOP_CLASS_UID
      ) {
        return instance;
      }
    }
  }

  return null;
}

export const MammoHangingProtocol: Types.HangingProtocol.ProtocolGenerator = ({
  servicesManager,
}) => {
  const { displaySetService, hangingProtocolService } = (
    servicesManager as ServicesManager
  ).services;

  const activeDisplaySets = displaySetService.getActiveDisplaySets();
  const activeStudy = hangingProtocolService.activeStudy;

  if (!activeStudy.ModalitiesInStudy.includes('MG')) {
    return { protocol: null };
  }

  const matchingViewsAndLateralities = [
    CRANIO_CAUDAL_VALUE,
    MEDIO_LATERAL_OBLIQUE_VALUE,
  ].flatMap(view =>
    [RIGHT_LATERALITY, LEFT_LATERALITY].map(laterality => {
      return {
        view,
        laterality,
        matchingInstance: getInstanceWithViewLaterality({
          study: activeStudy,
          view,
          laterality,
        }),
      };
    })
  );

  const matches = matchingViewsAndLateralities.filter(
    ({ matchingInstance }) => !!matchingInstance
  );

  if (matches.length === 0) {
    return { protocol: null };
  }

  const lateralities = uniqBy(matches, ({ laterality }) => laterality);
  const views = uniqBy(matches, ({ view }) => view);

  const viewports = [];
  const displaySetSelectors = {};

  lateralities.forEach(({ laterality }) => {
    views.forEach(({ view }) => {
      displaySetSelectors[getDisplaySetId(view, laterality)] =
        generateDiplaySetSelector();
    });
  });

  const isRightLateralityOnly =
    matches.every(({ laterality }) => laterality === RIGHT_LATERALITY) &&
    matches.length === 2;

  const isLeftLateralityOnly =
    matches.every(({ laterality }) => laterality === LEFT_LATERALITY) &&
    matches.length === 2;

  const isCranioCaudalViewOnly =
    matches.every(({ view }) => view === CRANIO_CAUDAL_VALUE) &&
    matches.length === 2;

  const isMedioLateralObliqueViewOnly =
    matches.every(({ view }) => view === MEDIO_LATERAL_OBLIQUE_VALUE) &&
    matches.length === 2;

  const isTwoViewportsOnly =
    isRightLateralityOnly ||
    isLeftLateralityOnly ||
    isCranioCaudalViewOnly ||
    isMedioLateralObliqueViewOnly;

  matchingViewsAndLateralities.forEach(match => {
    if (!match.matchingInstance) {
      if (!isTwoViewportsOnly && matches.length > 1) {
        viewports.push({});
      }
      return;
    }

    const { StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID } =
      match.matchingInstance;
    const isMatchingImage = (image: DicomJSONMetadata) => {
      return (
        image.StudyInstanceUID === StudyInstanceUID &&
        image.SeriesInstanceUID === SeriesInstanceUID &&
        image.SOPInstanceUID === SOPInstanceUID
      );
    };

    const matchingDisplaySet = activeDisplaySets.find(displaySet => {
      const { images = [] } = displaySet;

      return images.some(isMatchingImage);
    });

    const matchingImage = matchingDisplaySet.images.find(isMatchingImage);
    const initialIndex = matchingDisplaySet.images.findIndex(isMatchingImage);
    const { PresentationLUTShape, PhotometricInterpretation } = matchingImage;

    const displaySetId = getDisplaySetId(match.view, match.laterality);

    displaySetSelectors[displaySetId].seriesMatchingRules.push({
      id: 'SeriesInstanceUID',
      weight: 1,
      attribute: 'SeriesInstanceUID',
      constraint: {
        equals: {
          value: matchingImage.SeriesInstanceUID,
        },
      },
    });

    const viewport = {
      viewportOptions: {
        viewportType: 'stack',
        toolGroupId: 'default',
        initialImageOptions: {
          preset: 'first',
          index: initialIndex,
        },
      },
      displaySets: [
        {
          id: displaySetId,
          options: {
            voiInverted:
              PresentationLUTShape === 'INVERSE' ||
              PhotometricInterpretation === 'MONOCHROME1',
          },
        },
      ],
    };
    viewports.push(viewport);
  });

  return {
    protocol: {
      // id has to be unique for each study as this is cached by HangingProtocolService
      // but may not be the same for different studies
      id: `@gleamer/gdata-extension.hangingProtocolModule.mammo-${activeStudy.StudyInstanceUID}`,
      imageLoadStrategy: 'default',
      protocolMatchingRules: [
        {
          id: 'MammoModalityInStudy',
          weight: 0,
          attribute: 'ModalitiesInStudy',
          constraint: {
            contains: 'MG',
          },
          required: true,
        },
      ],
      // Default viewport is used to define the viewport when
      // additional viewports are added using the layout tool
      defaultViewport: {
        viewportOptions: {
          viewportType: 'stack',
          toolGroupId: 'default',
          allowUnmatchedView: true,
        },
        displaySets: [
          {
            id: 'defaultDisplaySetId',
            matchedDisplaySetsIndex: -1,
          },
        ],
      },
      displaySetSelectors,
      stages: [
        {
          id: 'mammo-stage',
          name: 'mammo',
          viewportStructure: {
            layoutType: 'grid',
            properties: {
              rows: views.length,
              columns: lateralities.length,
              layoutOptions: views.flatMap((_v, viewIndex) =>
                lateralities.map((_l, lateralityIndex) => {
                  return {
                    x: lateralityIndex / lateralities.length,
                    y: viewIndex / views.length,
                    width: 1 / lateralities.length,
                    height: 1 / views.length,
                  };
                })
              ),
            },
          },
          viewports,
        },
      ],
    },
  };
};
