import { createSelector } from '@reduxjs/toolkit';
import { getTaskItemId } from '../../../utils';
import { RootState } from '../store';
import { TasksSeenItems } from './tasksSeenItems.slice';

function getSeenItems(state: RootState) {
  return state.tasksSeenItems;
}

export const getPreviousItemId = createSelector(
  getSeenItems,
  getTaskItemId,
  (seenTasksItems, { taskId, itemId }) => {
    if (!taskId || !itemId) {
      return null;
    }
    const taskItems = seenTasksItems[taskId];
    if (!taskItems) {
      return null;
    }

    const currentItemIdIndex = taskItems.findIndex(item => item.id === itemId);
    const previousItem = taskItems.slice(0, currentItemIdIndex).at(-1);
    return previousItem?.id || null;
  }
);

export const getNextItemId = createSelector(
  getSeenItems,
  getTaskItemId,
  (seenTasksItems, { taskId, itemId }) => {
    if (!taskId || !itemId) {
      return null;
    }

    const taskItems = seenTasksItems[taskId];
    if (!taskItems) {
      return null;
    }

    const currentItemIdIndex = taskItems.findIndex(item => item.id === itemId);
    const nextItem = taskItems.slice(currentItemIdIndex + 1).at(0);
    return nextItem?.id || null;
  }
);

export const isLastSeenItem = createSelector(
  getSeenItems,
  getTaskItemId,
  (seenTasksItems, { taskId, itemId }) => {
    const taskItems = seenTasksItems[taskId];
    if (!taskItems) {
      return true;
    }

    const currentItemIdIndex = taskItems.findIndex(item => item.id === itemId);
    return currentItemIdIndex === taskItems.length - 1;
  }
);

export const hasPreviousItems = createSelector(
  getSeenItems,
  getTaskItemId,
  (seenTasksItems: TasksSeenItems, { taskId, itemId }) => {
    const taskItems = seenTasksItems[taskId];
    if (!taskItems) {
      return false;
    }

    const currentItemIdIndex = taskItems.findIndex(item => item.id === itemId);
    return currentItemIdIndex >= 1;
  }
);

export const getPreviousItemIds = createSelector(
  getSeenItems,
  getTaskItemId,
  (seenTasksItems: TasksSeenItems, { taskId, itemId }) => {
    const taskItems = seenTasksItems[taskId];
    if (!taskItems) {
      return [];
    }

    const currentItemIdIndex = taskItems.findIndex(item => item.id === itemId);
    return taskItems.slice(0, currentItemIdIndex).map(item => item.id);
  }
);

export const getNextItemIds = createSelector(
  getSeenItems,
  getTaskItemId,
  (seenTasksItems: TasksSeenItems, { taskId, itemId }) => {
    const taskItems = seenTasksItems[taskId];
    if (!taskItems) {
      return [];
    }

    const currentItemIdIndex = taskItems.findIndex(item => item.id === itemId);
    return taskItems.slice(currentItemIdIndex + 1).map(item => item.id);
  }
);
