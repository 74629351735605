import { Middleware } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { compress } from 'lz-string';
import { ActionTypes } from 'redux-undo';
import { isReadonly } from '../../../readonly-state';
import { getMode, getTaskItemId } from '../../../utils';
import { getItemLabelPassState } from '../labelPass/labelPass.selectors';
import { actions as labelPassActions } from '../labelPass/labelPass.slice';
import { AppDispatch, RootState } from '../store';

export const storeTasksSeenItems: Middleware<AppDispatch, RootState> = store => next => action => {
  const result = next(action);
  const { tasksSeenItems } = store.getState() as RootState;

  if (!isReadonly() && action.type.startsWith('tasksSeenItems/')) {
    localStorage.setItem('tasksSeenItems', JSON.stringify(tasksSeenItems));
  }
  return result;
};

export const storeLabelPass: Middleware<AppDispatch, RootState> = store => next => action => {
  const prevLabelPassState = getItemLabelPassState(store.getState());
  const result = next(action);

  if (isReadonly()) {
    return result;
  }

  const currentLabelPassState = getItemLabelPassState(store.getState());

  if (isEqual(prevLabelPassState, currentLabelPassState)) {
    return result;
  }

  const { taskId, itemId } = getTaskItemId();
  if (!taskId || !itemId) {
    return result;
  }

  const mode = getMode();

  const storageKey = `labelPass-${taskId}-${itemId}-${mode}`;
  if (labelPassActions.clear.match(action)) {
    localStorage.removeItem(storageKey);
  } else if (
    action.type.startsWith('labelPass/') ||
    action.type === ActionTypes.UNDO ||
    action.type === ActionTypes.REDO
  ) {
    localStorage.setItem(storageKey, compress(JSON.stringify(currentLabelPassState)));
  }

  return result;
};
