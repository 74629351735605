import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuthenticationService } from '../types/UserAuthenticationService';
import { Auth0Handler } from './Auth0Handler';
import { GleamerAuth0Provider } from './GleamerAuth0Provider';

type ProviderProps = {
  UserAuthenticationService: UserAuthenticationService;
};

export function Provider({
  children,
  UserAuthenticationService,
}: PropsWithChildren<ProviderProps>) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    localStorage.setItem('_expiredTime', `${Date.now() + 2 * 60_000}`);
    navigate(appState?.returnTo || window.location.pathname);
  };

  const { auth0 } = window as unknown as Window &
    typeof globalThis & {
      auth0: {
        domain: string;
        clientId: string;
        audience: string;
      };
    };

  return (
    <GleamerAuth0Provider
      cacheLocation="localstorage"
      useRefreshTokens
      {...auth0}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0Handler UserAuthenticationService={UserAuthenticationService}>
        {children}
      </Auth0Handler>
    </GleamerAuth0Provider>
  );
}
