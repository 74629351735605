import { Types as ToolsTypes } from '@cornerstonejs/tools';
import { Assets, Internal } from '@gleamer/types';
import { utils } from '@ohif/core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEqual, omit, sortBy } from 'lodash';
import { TaskItemId, getTaskItemId } from '../../../utils';
import {
  getDefaultAssetsCharacterisations,
  getObservationWithDefaults,
  getRegionWithDefaultCharacterisations,
} from './default-characterisations-mapper';

type Region = Internal.RegionInternal;
type ObservationSubmission = Internal.ObservationInternal;
type CharacterisationValues = Internal.CharacterisationValues;
type CharacterisationValue = Internal.CharacterisationValue;
type AssetCharacterisationSubmission = Internal.AssetCharacterisationInternal;
type AssetRef = Assets.AssetRef;
type Feedback = Internal.Feedback;
type LabelTask = Internal.LabelTask;
type LabelTaskItem = Internal.LabelTaskItem;

const { guid } = utils;

export type ItemLabelPassState = {
  initialObservations: ObservationSubmission[];
  observations: ObservationSubmission[];
  lastUpdatedObs: ObservationSubmission;
  lastUpdatedRegionUID?: string | null;
  initialCharacterisations: AssetCharacterisationSubmission[];
  characterisations: AssetCharacterisationSubmission[];
  feedbacks?: Feedback[];
  labeledBy?: string;
  sourceLabelPassesIds: string[];
};

export type LabelPassState = {
  data: {
    [taskId: number]: {
      [itemId: string]: ItemLabelPassState;
    };
  };
  lastUpdatedRegionUID: string | null;
};

type SetLabelPassPayload = {
  labelPass: Partial<ItemLabelPassState>;
  labelTask: Internal.LabelTask;
  labelTaskItem: Internal.LabelTaskItem;
};

type AddObservationPayload = {
  code: string;
  status: string;
  region: Region;
  colors: string[];
  labelTask: LabelTask;
  labelTaskItem: LabelTaskItem;
  characterisations?: CharacterisationValue[];
  uid?: string;
  author?: string;
};

type AddRegionPayload = {
  region: Region;
  labelTask: LabelTask;
  labelTaskItem: Internal.LabelTaskItem;
  observationToAddRegion?: ObservationSubmission;
};

type UpdateObservationChararacterisationPayload = {
  observationUid: string;
  asset: AssetRef;
  formData: CharacterisationValues;
};

type UpdateRegionCharacterisationPayload = {
  observationUid: string;
  regionUid: string;
  formData: CharacterisationValues;
};

function getObservationIndex(
  state: ItemLabelPassState,
  observationCode: string,
  observationStatus: string
): number {
  const obsIndexes = state.observations
    .filter(
      observation =>
        observation.code === observationCode && observation.status === observationStatus
    )
    .map(obs => obs.index);

  if (obsIndexes.length === 0) {
    return 1;
  }

  return Math.max(...obsIndexes) + 1;
}

type EnhancedPayloadAction<Data, T extends string = string> = PayloadAction<Data, T, TaskItemId>;

type EnhancedReducer<T> = (
  state: LabelPassState,
  action: EnhancedPayloadAction<T>
) => void | LabelPassState;

function fillObservationWithDefaults(
  labelTask: Internal.LabelTask,
  labelTaskItem: Internal.LabelTaskItem
): (obs: ObservationSubmission) => ObservationSubmission {
  return obs => {
    const obsSpec = labelTask?.observations?.find(
      obsSpec => obsSpec.code === obs.code && obsSpec.status === obs.status
    );
    return getObservationWithDefaults(obsSpec, labelTaskItem, obs);
  };
}

function withTaskItemId<T>(reducer: EnhancedReducer<T>) {
  return {
    reducer,
    prepare: (payload: T) => {
      return {
        payload,
        meta: getTaskItemId(),
      };
    },
  };
}

export const defaultLabelPassState: ItemLabelPassState = {
  observations: [],
  initialObservations: [],
  lastUpdatedObs: null,
  characterisations: [],
  initialCharacterisations: [],
  labeledBy: undefined,
  sourceLabelPassesIds: [],
};

const initialState: LabelPassState = {
  data: {},
  lastUpdatedRegionUID: null,
};

const sliceName = 'labelPass';

export const undoExcludedActions = [
  `${sliceName}/updateDrawnRegion`,
  `${sliceName}/updateObservationCharacterisations`,
  `${sliceName}/updateRegionCharacterisations`,
  `${sliceName}/clearLastUpdatedRegion`,
  `${sliceName}/clear`,
  `${sliceName}/setLastUpdated`,
  `${sliceName}/updateAssetCharacterisations`,
  `${sliceName}/setDefaultCharacterisationValues`,
  `${sliceName}/initObservations`,
  `${sliceName}/initRegions`,
  `${sliceName}/initAssetCharacterisations`,
];

export const observationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialLabelPass: withTaskItemId(
      (state, action: EnhancedPayloadAction<SetLabelPassPayload>) => {
        const { payload, meta } = action;
        const { labelPass, labelTask, labelTaskItem } = payload;
        const { taskId, itemId } = meta;

        const {
          observations = [],
          initialObservations = observations,
          characterisations = [],
          initialCharacterisations = characterisations,
          labeledBy,
          sourceLabelPassesIds = [],
        } = labelPass || ({} as ItemLabelPassState);

        if (!state.data[taskId]) {
          state.data[taskId] = {};
        }

        if (!state.data[taskId][itemId]) {
          state.data[taskId][itemId] = structuredClone(defaultLabelPassState);
        }

        const itemState = state.data[taskId][itemId];
        const sortedObservations = sortBy(observations, ['code', 'index']);
        itemState.observations = sortedObservations.map(
          fillObservationWithDefaults(labelTask, labelTaskItem)
        );

        itemState.initialObservations = initialObservations.map(
          fillObservationWithDefaults(labelTask, labelTaskItem)
        );
        itemState.lastUpdatedObs = itemState.observations.at(0);

        itemState.characterisations = getDefaultAssetsCharacterisations(
          labelTask,
          labelTaskItem,
          characterisations
        );

        itemState.initialCharacterisations = getDefaultAssetsCharacterisations(
          labelTask,
          labelTaskItem,
          initialCharacterisations
        );

        observations
          .flatMap(obs => obs.regions)
          .flatMap(region => region.sourceLabelPassesIds)
          .forEach(id => {
            if (!sourceLabelPassesIds.includes(id)) {
              sourceLabelPassesIds.push(id);
            }
          });

        itemState.labeledBy = labeledBy;
        itemState.sourceLabelPassesIds = sourceLabelPassesIds;
      }
    ),

    addObservation: withTaskItemId(
      (state, action: EnhancedPayloadAction<AddObservationPayload>) => {
        const {
          code,
          status,
          region,
          colors,
          labelTaskItem,
          labelTask,
          uid,
          characterisations,
          author,
        } = action.payload;
        const { taskId, itemId } = action.meta;

        if (!state.data[taskId]) {
          state.data[taskId] = {};
        }

        if (!state.data[taskId][itemId]) {
          state.data[taskId][itemId] = structuredClone(defaultLabelPassState);
        }

        const itemState = state.data[taskId][itemId];

        const obs = itemState.observations.find(obs => obs.uid === uid);

        if (obs) {
          itemState.lastUpdatedObs = obs;
          return;
        }

        const index = getObservationIndex(itemState, code, status);
        const addedRegion = omit(region, 'data.cachedStats');

        const baseObservation = {
          uid: uid ?? guid(),
          index,
          code: code,
          status: status,
          characterisations: characterisations ?? [],
          regions: region ? [addedRegion] : [],
          color: colors[index % colors.length],
          label: `${code} ${index}`,
          hidden: false,
          author,
        };

        const newObservation: ObservationSubmission = fillObservationWithDefaults(
          labelTask,
          labelTaskItem
        )(baseObservation);

        itemState.observations.push(newObservation);
        itemState.observations = sortBy(itemState.observations, ['code', 'index']);

        itemState.lastUpdatedObs = newObservation;
      }
    ),

    addRegion: withTaskItemId((state, action: EnhancedPayloadAction<AddRegionPayload>) => {
      const { region, labelTask, labelTaskItem, observationToAddRegion } = action.payload;
      const { taskId, itemId } = action.meta;
      const itemState = state.data?.[taskId]?.[itemId];
      const observation: ObservationSubmission =
        observationToAddRegion ?? itemState?.lastUpdatedObs;

      if (!itemState) {
        console.error('Tried to add a region without an existing observation');
        return;
      }

      if (!observation) {
        console.error('Tried to add a region without selected observation');
        return;
      }

      const obsSpec = labelTask.observations.find(
        obs => obs.code === observation.code && obs.status === observation.status
      );

      if (!obsSpec) {
        console.error('Tried to add a region without an existing observation specification');
        return;
      }

      if (observation.regions.some(reg => reg.uid === region.uid)) {
        return;
      }

      const regionSpecification = obsSpec.regions.find(reg => reg.tool.name === region.toolName);

      const addedRegion = omit(region, 'data.cachedStats');
      const newRegion = getRegionWithDefaultCharacterisations(
        regionSpecification,
        labelTaskItem,
        addedRegion
      );

      observation.regions.push(newRegion);
      const obsIndex = itemState.observations.findIndex(obs => obs.uid === observation.uid);
      itemState.observations[obsIndex] = observation;
    }),

    deleteObservation: withTaskItemId((state, action: EnhancedPayloadAction<string>) => {
      const { taskId, itemId } = action.meta;
      const itemState = state.data?.[taskId]?.[itemId];

      if (!itemState) {
        console.error('Tried to delete an observation without an existing observation');
        return;
      }

      const observationUid = action.payload;
      const observationIndex = itemState.observations.findIndex(obs => obs.uid === observationUid);

      if (observationIndex >= 0) {
        itemState.observations.splice(observationIndex, 1);
        itemState.lastUpdatedObs = itemState.observations[Math.max(observationIndex - 1, 0)];
      }
    }),

    deleteRegion: withTaskItemId((state, action: EnhancedPayloadAction<string>) => {
      const { taskId, itemId } = action.meta;
      const itemState = state.data?.[taskId]?.[itemId];

      if (!itemState) {
        console.error('Tried to delete a region without an existing observation');
        return;
      }

      const regionUID = action.payload;
      const updatedObservation = itemState.observations.find(obs =>
        obs.regions.map(reg => reg.uid).includes(regionUID)
      );

      if (!updatedObservation) {
        console.warn(`Couldn't find observation with region ${regionUID}`);
        return;
      }

      const region = updatedObservation.regions.find(reg => reg.uid === regionUID);

      if (region) {
        updatedObservation.regions = updatedObservation.regions.filter(
          reg => reg.uid !== regionUID
        );
        itemState.lastUpdatedObs = updatedObservation;
      }
    }),

    updateDrawnRegion: withTaskItemId(
      (
        state,
        action: EnhancedPayloadAction<{
          uid: string;
          displaySetInstanceUID: string;
        }>
      ) => {
        const { taskId, itemId } = action.meta;
        const itemState = state.data?.[taskId]?.[itemId];

        if (!itemState) {
          console.error('Tried to update a region without an existing observation');
          return;
        }

        itemState.observations.forEach(obs =>
          obs.regions.forEach(reg => {
            if (reg.uid === action.payload.uid) {
              reg.displaySetInstanceUID = action.payload.displaySetInstanceUID;
            }
          })
        );
        itemState.lastUpdatedObs.regions.forEach(reg => {
          if (reg.uid === action.payload.uid) {
            reg.displaySetInstanceUID = action.payload.displaySetInstanceUID;
          }
        });
      }
    ),

    updateDrawnRegions: withTaskItemId(
      (state, action: EnhancedPayloadAction<{ [regionUid: string]: string }>) => {
        const { taskId, itemId } = action.meta;
        const itemState = state.data?.[taskId]?.[itemId];

        if (!itemState) {
          console.error('Tried to update a region without an existing observation');
          return;
        }

        itemState.observations?.forEach(obs =>
          obs.regions.forEach(reg => {
            if (action.payload[reg.uid]) {
              reg.displaySetInstanceUID = action.payload[reg.uid];
            }
          })
        );
        itemState.lastUpdatedObs?.regions?.forEach(reg => {
          if (action.payload[reg.uid]) {
            reg.displaySetInstanceUID = action.payload[reg.uid];
          }
        });
      }
    ),

    updateRegion: withTaskItemId((state, action: EnhancedPayloadAction<ToolsTypes.Annotation>) => {
      const { taskId, itemId } = action.meta;
      const itemState = state.data?.[taskId]?.[itemId];

      if (!itemState) {
        console.error('Tried to update a region without an existing observation');
        return;
      }

      const { annotationUID, metadata, data } = action.payload;
      const updatedObservation = itemState.observations.find(obs =>
        obs.regions.map(reg => reg.uid).includes(annotationUID)
      );

      if (!updatedObservation) {
        console.warn(`Couldn't find observation with region ${annotationUID}`);
        return;
      }

      const regionIndex = updatedObservation.regions.findIndex(reg => reg.uid === annotationUID);

      if (regionIndex >= 0) {
        const updatedRegion = updatedObservation.regions[regionIndex];
        updatedRegion.uid = annotationUID;
        updatedRegion.metadata = metadata;
        updatedRegion.data = omit(data, 'cachedStats');
        itemState.lastUpdatedObs = updatedObservation;
      }
    }),

    updateObservationCharacterisations: withTaskItemId(
      (state, action: EnhancedPayloadAction<UpdateObservationChararacterisationPayload>) => {
        const { taskId, itemId } = action.meta;
        const itemState = state.data?.[taskId]?.[itemId];

        if (!itemState) {
          console.error('Tried to update a characterisations without an existing observation');
          return;
        }

        const { observationUid, asset, formData } = action.payload;
        const obsIndex = itemState.observations.findIndex(obs => obs.uid === observationUid);

        if (obsIndex < 0) {
          return;
        }
        const changedCharacterisation = {
          asset: asset,
          characterisation: formData,
        };

        const updatedObservation = itemState.observations[obsIndex];
        const characterisations = updatedObservation.characterisations;

        const existingCharacIndex = characterisations.findIndex(charac =>
          isEqual(charac.asset, asset)
        );

        if (existingCharacIndex >= 0) {
          characterisations[existingCharacIndex] = changedCharacterisation;
        } else {
          characterisations.push(changedCharacterisation);
        }

        itemState.lastUpdatedObs = updatedObservation;
      }
    ),

    updateRegionCharacterisations: withTaskItemId(
      (state, action: EnhancedPayloadAction<UpdateRegionCharacterisationPayload>) => {
        const { taskId, itemId } = action.meta;
        const itemState = state.data?.[taskId]?.[itemId];
        if (!itemState) {
          console.error(
            'Tried to update a region characterisations without an existing observation'
          );
          return;
        }

        const { observationUid, regionUid, formData } = action.payload;
        const observation = itemState.observations.find(obs => obs.uid === observationUid);
        if (!observation) {
          return;
        }

        const region = observation.regions.find(reg => reg.uid === regionUid);
        if (!region) {
          return;
        }
        region.characterisation = formData;

        itemState.lastUpdatedObs = observation;
      }
    ),

    clear: withTaskItemId((state, action: EnhancedPayloadAction<void>) => {
      const { taskId, itemId } = action.meta;

      state.lastUpdatedRegionUID = null;
      if (!state.data?.[taskId]?.[itemId]) {
        return;
      }

      delete state.data[taskId][itemId];
    }),

    clearLastUpdatedRegion: withTaskItemId(state => {
      state.lastUpdatedRegionUID = null;
    }),

    setLastUpdatedByRegion: withTaskItemId((state, action: EnhancedPayloadAction<string>) => {
      const { taskId, itemId } = action.meta;
      const itemState = state.data?.[taskId]?.[itemId];

      if (!itemState) {
        return;
      }

      const regionUID = action.payload;
      const updatedObservation = itemState.observations.find(obs =>
        obs.regions.map(reg => reg.uid).includes(regionUID)
      );

      if (!updatedObservation) {
        return;
      }

      itemState.lastUpdatedObs = updatedObservation;

      const region = updatedObservation.regions.find(reg => reg.uid === regionUID);

      if (region) {
        state.lastUpdatedRegionUID = region.uid;
      }
    }),

    setLastUpdated: withTaskItemId(
      (
        state,
        action: EnhancedPayloadAction<{
          observation: ObservationSubmission;
          regionUID?: string;
        }>
      ) => {
        const { taskId, itemId } = action.meta;
        const itemState = state.data?.[taskId]?.[itemId];

        if (!itemState) {
          return;
        }

        const { observation, regionUID } = action.payload;
        itemState.lastUpdatedObs = observation;
        if (regionUID) {
          itemState.lastUpdatedRegionUID = regionUID;
        }
      }
    ),

    toggleObservationVisibility: withTaskItemId((state, action: EnhancedPayloadAction<string>) => {
      const { taskId, itemId } = action.meta;
      const itemState = state.data?.[taskId]?.[itemId];

      if (!itemState) {
        return;
      }

      const uid = action.payload;
      const obs = itemState.observations.find(obs => obs.uid === uid);
      obs.hidden = !obs.hidden;
    }),

    updateAssetCharacterisations: withTaskItemId(
      (
        state,
        action: EnhancedPayloadAction<{
          asset: AssetRef;
          formData: CharacterisationValues;
        }>
      ) => {
        const { taskId, itemId } = action.meta;
        const itemState = state.data?.[taskId]?.[itemId];

        if (!itemState) {
          return;
        }

        const characterisations = itemState.characterisations || [];
        const { asset, formData } = action.payload;

        const existingCharac = characterisations.find(characterisation =>
          isEqual(characterisation.asset, asset)
        );

        if (existingCharac) {
          existingCharac.characterisation = formData;
        } else {
          characterisations.push({
            asset,
            characterisation: formData,
          });
        }
      }
    ),

    initAssetCharacterisations: withTaskItemId(
      (
        state,
        action: EnhancedPayloadAction<{
          asset: AssetRef;
          formData: CharacterisationValues;
        }>
      ) => {
        const { taskId, itemId } = action.meta;
        const itemState = state.data?.[taskId]?.[itemId];

        if (!itemState) {
          return;
        }

        if (itemState.initialPassExists) {
          console.log('pass already initialized for characterisations');
          return;
        }

        if (!itemState.characterisations) {
          itemState.characterisations = [];
        }

        if (!itemState.initialCharacterisations) {
          itemState.initialCharacterisations = [];
        }

        const { initialCharacterisations, characterisations } = itemState;
        const { asset, formData } = action.payload;

        initialCharacterisations.push({
          asset,
          characterisation: formData,
        });

        characterisations.push({
          asset,
          characterisation: formData,
        });
      }
    ),

    setFeedbacks: withTaskItemId((state, action: EnhancedPayloadAction<Feedback[]>) => {
      const { taskId, itemId } = action.meta;
      const itemState = state.data?.[taskId]?.[itemId];

      if (!itemState) {
        return;
      }

      itemState.feedbacks = action.payload;
    }),

    setDefaultCharacterisationValues: withTaskItemId(
      (
        state,
        action: EnhancedPayloadAction<{
          labelTask: LabelTask;
          labelTaskItem: LabelTaskItem;
        }>
      ) => {
        const { payload, meta } = action;
        const { taskId, itemId } = meta;

        if (!state.data[taskId]) {
          state.data[taskId] = {};
        }

        if (!state.data[taskId][itemId]) {
          state.data[taskId][itemId] = structuredClone(defaultLabelPassState);
        }

        const itemState = state.data[taskId][itemId];

        itemState.initialCharacterisations = getDefaultAssetsCharacterisations(
          payload.labelTask,
          payload.labelTaskItem,
          itemState.initialCharacterisations
        );
        itemState.characterisations = getDefaultAssetsCharacterisations(
          payload.labelTask,
          payload.labelTaskItem,
          itemState.characterisations
        );
      }
    ),
  },
});

export const actions = observationsSlice.actions;
export const reducer = observationsSlice.reducer;
