import { OHIF } from '@gleamer/types';
import { Accordion } from '@gleamer/ui';
import React from 'react';
import { WithStatus } from '../../../atoms/WithStatus';
import { ReportCharacterisationButton } from './ReportCharacterisationButton';
import { ReportDisplayProps } from './ReportDisplay';
import { ReportTitle } from './ReportTitle';
import { useFileReport } from './useFileReport';
type ServicesManager = OHIF.ServicesManager;

export function ReportFileEntry({
  report,
  fetchReport,
  servicesManager,
  patientId,
}: ReportDisplayProps & {
  servicesManager: ServicesManager;
  patientId?: string;
}) {
  const { isError, refetch, fetchStatus } = useFileReport({
    report,
    fetchReport,
    enabled: false,
  });

  const onClick = async () => {
    const { data: reportUrl } = await refetch();
    window.open(reportUrl, '_blank');
  };

  return (
    <li className="mb-2 text-base">
      <Accordion onClick={onClick}>
        <div className="flex items-center justify-start">
          <Accordion.Title className="my-2 mr-2 text-base">
            <ReportTitle report={report} />
          </Accordion.Title>
        </div>
        <ReportCharacterisationButton
          className="ml-2"
          servicesManager={servicesManager}
          report={report}
        />
        <WithStatus
          error={isError}
          loading={fetchStatus === 'fetching'}
          data-testid={`report-${report.id}`}
        />
      </Accordion>
    </li>
  );
}
