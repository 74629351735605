import { Api } from '@gleamer/types';
import { ServicesManager } from '@ohif/core';
import { useUserAuthentication } from '@ohif/ui';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FormEventHandler, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { errorsApis, reviewSessionApis, tasksApis } from '@gleamer/apis';
import { PageTitle } from '../atoms/PageTitle';
import { PageWrapper } from '../atoms/PageWrapper';

const { isNoMoreItemsError } = errorsApis;
const { createReviewSession } = reviewSessionApis;
const { fetchTaskDetails } = tasksApis;

type ReviewSessionConfigurationApi = Api.ReviewSessionConfiguration;
type ReviewMode = ReviewSessionConfigurationApi['reviewMode'];
type Direction = ReviewSessionConfigurationApi['labeledAtOrder'];

interface FormElements extends HTMLFormControlsCollection {
  labeler: HTMLSelectElement;
  labeledAtOrder: HTMLSelectElement;
  reviewMode: HTMLSelectElement;
}

type ReviewSessionConfigurationProps = {
  servicesManager: ServicesManager;
};

export function ReviewSessionConfiguration({
  servicesManager,
}: ReviewSessionConfigurationProps) {
  const { taskId: urlTaskId } = useParams();
  const navigate = useNavigate();
  const [, userAuthenticationService] = useUserAuthentication();
  const { DicomJSONService } = servicesManager.services;

  const taskId = parseInt(urlTaskId, 10);

  const [reviewMode, setReviewMode] = useState<ReviewMode>('PER_LABELER');

  const {
    data: task,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['task-details', taskId],
    queryFn: () => fetchTaskDetails(userAuthenticationService, taskId),
  });

  const mutation = useMutation({
    mutationFn: async (form: HTMLFormElement) => {
      const formElements = form.elements as FormElements;

      const sessionConfiguration = {
        labeler: formElements.labeler?.value || null,
        labeledAtOrder: formElements.labeledAtOrder?.value as Direction,
        reviewMode: formElements.reviewMode?.value as ReviewMode,
      };

      await createReviewSession(
        userAuthenticationService,
        taskId,
        sessionConfiguration
      );
      return sessionConfiguration;
    },
    onSuccess: async sessionConfiguration => {
      await DicomJSONService.fetchNextItem(taskId);

      const { labeler, reviewMode } = sessionConfiguration;
      const urlParams = new URLSearchParams({
        task: taskId.toString(),
        reviewMode,
      });
      if (reviewMode === 'PER_LABELER' && labeler) {
        urlParams.append('labeledBy', labeler);
      }
      navigate(`/ohif/review/review?${urlParams.toString()}`);
    },
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    mutation.mutate(event.currentTarget);
  };

  if (isLoading || !task) {
    return (
      <PageWrapper>
        <PageTitle title="New review session" />
        <div>Retrieving details...</div>
      </PageWrapper>
    );
  }

  if (isError) {
    return (
      <PageWrapper>
        <PageTitle title="New review session" />
        <div>An error occured. Please try again</div>
      </PageWrapper>
    );
  }

  const { taskers, reviewConfiguration, requiredLabelers } = task;
  const { reviewers = [] } = reviewConfiguration || { reviewers: [] };
  const labelers = taskers.filter(tasker => !reviewers.includes(tasker));

  return (
    <PageWrapper className="max-w-xl">
      <PageTitle title="New review session" />
      {mutation.error && (
        <div className="text-red-600">
          {isNoMoreItemsError(mutation.error) ? (
            <div>There are no more items to review in this configuration</div>
          ) : (
            <div>An error occured. Please try again</div>
          )}
        </div>
      )}
      <form className="flex flex-col gap-2" onSubmit={onSubmit}>
        <div className="flex flex-col">
          <label htmlFor="reviewMode">Review mode</label>
          <select
            required
            disabled={requiredLabelers === 1}
            id="reviewMode"
            name="reviewMode"
            className="text-black"
            value={reviewMode}
            onChange={e => {
              setReviewMode(e.target.value as ReviewMode);
            }}
          >
            <option value="PER_LABELER">Review a labeler</option>
            {requiredLabelers > 1 && (
              <option value="PER_ITEM">Review case by case</option>
            )}
          </select>
        </div>
        {reviewMode === 'PER_LABELER' && (
          <div className="flex flex-col">
            <label htmlFor="labeler">Labeler</label>
            <select id="labeler" name="labeler" className="text-black" required>
              {labelers.map(labeler => (
                <option key={labeler} value={labeler}>
                  {labeler}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex flex-col">
          <label htmlFor="labeledAtOrder">Cases order</label>
          <select
            id="labeledAtOrder"
            name="labeledAtOrder"
            className="text-black"
            required
          >
            <option value="ASCENDING">Date ascending</option>
            <option value="DESCENDING">Date descending</option>
          </select>
        </div>
        <div className="flex flex-col items-center">
          <input
            type="submit"
            value="Start review session"
            className="w-max-content cursor-pointer rounded-md bg-primary-active px-4 py-2 text-white hover:bg-primary-main focus:bg-primary-main"
          />
        </div>
      </form>
    </PageWrapper>
  );
}
