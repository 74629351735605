import { Internal } from '@gleamer/types';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { WithStatus } from '../../../atoms/WithStatus';
import { useFileReport } from './useFileReport';

type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;
type LabelTask = Internal.LabelTask;

export type ReportDisplayProps = {
  report: EnhancedDicomJSONReport;
  fetchReport: (report: EnhancedDicomJSONReport) => Promise<Blob>;
  embedded?: boolean;
  className?: string;
  task?: LabelTask;
};

export const removeRedundantSpaces = (text: string): string => {
  return text.replace(/(\r\n|\n|\r){3,}/gm, '\n\n').trim();
};

function ReportTextDisplay({
  report,
  fetchReport,
  className,
  embedded,
  task,
}: ReportDisplayProps) {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['report', report.url],
    queryFn: async () => {
      const blob = await fetchReport(report);
      const text = await blob.text();
      return removeRedundantSpaces(text);
    },
    // This caches data for the whole duration of the session
    staleTime: Infinity,
  });

  const contentRef = useRef<HTMLPreElement>(null);
  useEffect(() => {
    const currentContentRef = contentRef.current;
    if (!task || !currentContentRef) {
      return;
    }

    const { reportHighlights } = task;
    if (!reportHighlights) {
      return;
    }

    if (!currentContentRef.innerText) {
      return;
    }

    let innerText = currentContentRef.innerText;
    reportHighlights.forEach(highlight => {
      highlight.regexes.forEach(regex => {
        innerText = innerText.replace(
          new RegExp(`(${regex})`, 'gi'),
          (match, p1) =>
            `<span class="font-bold" style="color: ${highlight.color};">${p1}</span>`
        );
      });
    });
    currentContentRef.innerHTML = innerText;
  }, [task, data]);

  return (
    <WithStatus className={className} loading={isLoading} error={isError}>
      {data && (
        <pre
          className="text-white break-words whitespace-pre-wrap font-sans max-w-3xl"
          data-testid={`report-${report.id}`}
          ref={contentRef}
        >
          {embedded && '\n\n\n'}
          {data}
        </pre>
      )}
    </WithStatus>
  );
}

function ReportFileDisplay({
  report,
  fetchReport,
  className,
}: ReportDisplayProps) {
  const {
    data: url,
    isLoading,
    isError,
  } = useFileReport({
    report,
    fetchReport,
    enabled: true,
  });

  return (
    <WithStatus
      data-testid={`report-${report.id}`}
      loading={isLoading}
      error={isError}
      className={className}
    >
      {url && (
        <iframe
          title={report.id}
          src={url}
          role="document"
          className="h-full w-full"
        />
      )}
    </WithStatus>
  );
}

export function ReportDisplay({
  report,
  fetchReport,
  className,
  embedded,
  task,
}: ReportDisplayProps) {
  if (report.contentType === 'text/plain') {
    return (
      <ReportTextDisplay
        className={className}
        report={report}
        fetchReport={fetchReport}
        embedded={embedded}
        task={task}
      />
    );
  }
  return (
    <ReportFileDisplay
      className={className}
      report={report}
      fetchReport={fetchReport}
      embedded={embedded}
    />
  );
}
