import {
  eventTarget,
  getEnabledElement,
  Types,
  utilities,
} from '@cornerstonejs/core';
import { annotation } from '@cornerstonejs/tools';
import { Internal } from '@gleamer/types';
import { CommandsManager, ExtensionManager, ServicesManager } from '@ohif/core';
import { ActionCreators } from 'redux-undo';
import { BUTTON_IDS } from './components/organisms/toolbar/constants';
import { OBSERVATION_EVENTS } from './contexts/ObservationContext/ObservationContext';
import { OVERLAY_EVENTS } from './contexts/OverlayStateContext/OverlayStateContext';
import { mipProtocolModule } from './hanging-protocols/mip';
import {
  getMPRLayoutId,
  isMprProtocol,
  Layout,
  VOLUME_ORIENTATIONS,
} from './hanging-protocols/mpr';
import {
  PositionPresentation,
  Presentations,
} from '@ohif/extension-cornerstone/src/types/Presentation';

type VolumeOrientation = Internal.VolumeOrientation;

export function getCommandsModule({
  servicesManager,
  commandsManager,
  extensionManager,
}: {
  servicesManager: ServicesManager;
  commandsManager: CommandsManager;
  extensionManager: ExtensionManager;
}) {
  const {
    viewportGridService,
    uiModalService,
    displaySetService,
    hangingProtocolService,
    stateSyncService,
    ReduxStoreService,
    cornerstoneViewportService,
    uiNotificationService,
  } = servicesManager.services;

  const utilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.common'
  );

  const { getEnabledElement: OHIFgetEnabledElement } = utilityModule.exports;

  function _getViewportElement(viewportId: string) {
    const { element } = OHIFgetEnabledElement(viewportId) || {};
    const enabledElement = getEnabledElement(element);
    return enabledElement;
  }

  function _getActiveViewportEnabledElement() {
    const { activeViewportId } = viewportGridService.getState();
    return _getViewportElement(activeViewportId);
  }

  function _getViewportOrientation(
    viewport: Types.IViewport
  ): VolumeOrientation {
    return viewport.options.orientation as VolumeOrientation;
  }

  function _getDisplaySetInstanceUID() {
    const { activeViewportId, viewports } = viewportGridService.getState();
    const viewport = viewports.get(activeViewportId) || {
      displaySetInstanceUIDs: [],
    };
    const {
      displaySetInstanceUIDs: [displaySetInstanceUID],
    } = viewport;

    return displaySetInstanceUID;
  }

  function _getDisplaySet() {
    const displaySetInstanceUID = _getDisplaySetInstanceUID();
    if (!displaySetInstanceUID) {
      return null;
    }

    return displaySetService.getDisplaySetByUID(displaySetInstanceUID);
  }

  function _storeCurrentPresentation() {
    const { activeViewportId } = viewportGridService.getState();
    const csViewport =
      cornerstoneViewportService.getCornerstoneViewport(activeViewportId);

    const currentIndex = csViewport.getCurrentImageIdIndex();

    const { positionPresentationStore } = stateSyncService.getState();
    const currentPresentation: Presentations =
      cornerstoneViewportService.getPresentations(activeViewportId);

    stateSyncService.store({
      previousPresentationId: {
        positionPresentationId: currentPresentation.positionPresentation.id,
        lutPresentationId: currentPresentation.lutPresentation.id,
      },
    });

    positionPresentationStore[currentPresentation.positionPresentation.id] = {
      id: currentPresentation.positionPresentation.id,
      viewportType: csViewport.type,
      presentation: {
        initialImageIndex: currentIndex,
        ...csViewport.getCamera(),
      },
    } as PositionPresentation;

    // Store previous presentation id to restore onMIPExit
    cornerstoneViewportService.storePresentation({
      viewportId: activeViewportId,
    });
  }

  function _updateMipHangingProtocolCamera() {
    const { activeViewportId } = viewportGridService.getState();
    const csViewport =
      cornerstoneViewportService.getCornerstoneViewport(activeViewportId);

    const displaySetInstanceUID = _getDisplaySetInstanceUID();

    const currentIndex = csViewport.getCurrentImageIdIndex();
    const { voiRange } = csViewport.getProperties();
    const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(
      voiRange.lower,
      voiRange.upper
    );

    const { positionPresentationStore } = stateSyncService.getState();

    // TODO: positionPresentationId should be generated dynamically
    const positionPresentationId = `axial&${displaySetInstanceUID}&0`;

    positionPresentationStore[positionPresentationId] = {
      id: positionPresentationId,
      presentation: {
        initialImageIndex: currentIndex,
        ...csViewport.getCamera(),
      },
    } as PositionPresentation;

    // store previous and presentation for positionPresentationId
    _storeCurrentPresentation();

    const mipViewport = mipProtocolModule.protocol.stages[0].viewports[0];
    // Override existing MIP protocol to set the camera
    mipViewport.viewportOptions.presentationIds = {
      positionPresentationId,
    };

    if (stateSyncService.slabThickness > 0) {
      mipViewport.displaySets[0].options.slabThickness =
        stateSyncService.slabThickness;
    } else {
      stateSyncService.slabThickness = 10;
    }

    mipViewport.displaySets[0].options.voi = {
      windowWidth,
      windowCenter,
    };

    // addProtocol override existing protocol with the same id.
    hangingProtocolService.addProtocol(
      mipProtocolModule.protocol.id,
      mipProtocolModule.protocol
    );
  }

  const actions = {
    triggerZoomButton: () => {
      document.getElementById('Zoom')?.click();
    },
    triggerPanButton: () => {
      document.getElementById('Pan')?.click();
    },
    triggerRectangleToolButton: () => {
      document.getElementById('GleamerRectangleTool')?.click();
    },
    characterisePatient: () => {
      if (document.getElementById('patient-characterisation-form')) {
        uiModalService.hide();
      } else {
        document.getElementById(BUTTON_IDS.PATIENT)?.click();
      }
    },
    characteriseInstance: () => {
      if (document.getElementById('instance-characterisation-form')) {
        uiModalService.hide();
      } else {
        document.getElementById(BUTTON_IDS.INSTANCE)?.click();
      }
    },
    characteriseReport: () => {
      if (document.getElementById('studyReport-characterisation-form')) {
        uiModalService.hide();
        return;
      }
      const displaySet = _getDisplaySet();

      if (!displaySet) {
        return;
      }

      const buttonId = `${BUTTON_IDS.REPORT}-${displaySet.StudyInstanceUID}`;
      document.getElementById(buttonId)?.click();
    },
    toggleViewportOverlay: () => {
      window.dispatchEvent(new Event(OVERLAY_EVENTS.OVERLAY_STATE_CHANGED));
    },
    deleteNearbyRegion: () => {
      eventTarget.dispatchEvent(
        new Event(OBSERVATION_EVENTS.DELETE_NEARBY_REGION)
      );
    },
    toggleRegionLabel: () => {
      const { global } = annotation.config.style.getDefaultToolStyles();
      annotation.config.style.setDefaultToolStyles({
        global: {
          ...global,
          textBoxFontSize: global.textBoxFontSize === '1' ? '0' : '1',
        },
      });
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      renderingEngine.render();
    },
    toggleGleamerMPR: () => {
      const DEFAULT_MPR_HP_ID =
        '@gleamer/gdata-extension.hangingProtocolModule.mpr-axial-sagittal-coronal';

      const hpInfo = hangingProtocolService.getState();

      if (hpInfo.protocolId === mipProtocolModule.protocol.id) {
        uiNotificationService.show({
          title: 'Apply MPR',
          message:
            'MPR cannot be applied to MIP views. Exit MIP mode and use MPR with Crosshair tool instead.',
          type: 'error',
          duration: 3000,
        });
        return false;
      }

      const { mpr } = stateSyncService.getState().taskLayout || {
        mpr: DEFAULT_MPR_HP_ID,
      };

      if (isMprProtocol(hpInfo.protocolId)) {
        const { previousHangingProtocol } = stateSyncService.getState();
        commandsManager.run({
          commandName: 'setHangingProtocol',
          commandOptions: {
            protocolId: previousHangingProtocol?.protocolId || 'default',
          },
          context: 'DEFAULT',
        });
      } else {
        _storeCurrentPresentation();
        stateSyncService.getState().previousHangingProtocol = {
          protocolId: hpInfo.protocolId,
        };
        commandsManager.run({
          commandName: 'toggleHangingProtocol',
          commandOptions: {
            protocolId: mpr || DEFAULT_MPR_HP_ID,
          },
          context: 'DEFAULT',
        });
      }
    },
    toggleMIP: () => {
      const hpInfo = hangingProtocolService.getState();
      if (isMprProtocol(hpInfo.protocolId)) {
        uiNotificationService.show({
          title: 'Apply MIP',
          message:
            'MIP cannot be applied to MPR views. Use Crosshair tool instead.',
          type: 'error',
          duration: 3000,
        });
        return false;
      }

      if (hpInfo.protocolId !== mipProtocolModule.protocol.id) {
        _updateMipHangingProtocolCamera();
      }

      commandsManager.run({
        commandName: 'toggleHangingProtocol',
        commandOptions: {
          protocolId: mipProtocolModule.protocol.id,
        },
        context: 'DEFAULT',
      });
    },
    triggerMIPButton: () => {
      const mipButton = document.querySelector(
        '[data-cy="MIP"]'
      ) as HTMLButtonElement | null;
      mipButton?.click();
    },
    onExitMIP: () => {
      const { activeViewportId } = viewportGridService.getState();
      const displaySet = _getDisplaySet();

      const currentPresentation: Presentations =
        cornerstoneViewportService.getPresentations(activeViewportId);

      const {
        positionPresentationStore,
        previousPresentationId,
        lutPresentationStore,
      } = stateSyncService.getState();

      const ohifViewport =
        cornerstoneViewportService.getCornerstoneViewport(activeViewportId);

      const previousPositions: PositionPresentation = positionPresentationStore[
        previousPresentationId.positionPresentationId as string
      ] as PositionPresentation;

      const previousLut =
        lutPresentationStore[
          previousPresentationId.lutPresentationId as string
        ];

      if (previousPositions) {
        const currentIndex = ohifViewport.getCurrentImageIdIndex();
        const initialImageIndex = displaySet.getNumImages() - 1 - currentIndex;
        previousPositions.presentation = {
          ...previousPositions.presentation,
          ...ohifViewport.getCamera(),
          initialImageIndex,
        };
        previousLut.presentation.voiRange =
          currentPresentation.lutPresentation?.presentation?.voiRange;
      }
    },
    switchMPRViews: () => {
      const hpInfo = hangingProtocolService.getState();
      if (!isMprProtocol(hpInfo.protocolId)) {
        return;
      }

      const { activeViewportId, viewports } = viewportGridService.getState();

      const isMainViewportActive =
        viewports.get(activeViewportId).positionId === '0-0';
      if (isMainViewportActive) {
        // No viewport to switch
        return;
      }

      const currentPositionId = viewports.get(activeViewportId).positionId;
      const { viewport } = _getActiveViewportEnabledElement();
      const orientationToUse = _getViewportOrientation(viewport);

      const mainViewportId = Array.from(viewports.values()).find(
        viewport => viewport.positionId === '0-0'
      )?.viewportId;
      const { viewport: mainViewport } = _getViewportElement(mainViewportId);
      const currentOrientation = _getViewportOrientation(mainViewport);

      const unmodifiedOrientation = VOLUME_ORIENTATIONS.find(
        orientation =>
          orientation !== orientationToUse && orientation !== currentOrientation
      );

      const layoutToUse: Layout = {
        main: orientationToUse,
        top:
          currentPositionId === '1-0'
            ? currentOrientation
            : unmodifiedOrientation,
        bottom:
          currentPositionId === '2-0'
            ? currentOrientation
            : unmodifiedOrientation,
      };

      const protocolToUseId = getMPRLayoutId(layoutToUse);

      commandsManager.run({
        commandName: 'setHangingProtocol',
        commandOptions: {
          protocolId: protocolToUseId,
          reset: true,
        },
        context: 'DEFAULT',
      });
    },

    cancel: () => {
      ReduxStoreService.dispatch(ActionCreators.undo());
    },
    redo: () => {
      ReduxStoreService.dispatch(ActionCreators.redo());
    },
  };

  const definitions = {};
  Object.keys(actions).forEach(actionName => {
    definitions[actionName] = {
      commandFn: actions[actionName],
      storeContexts: [],
      options: {},
    };
  });

  return {
    actions,
    definitions,
    defaultContext: 'CORNERSTONE',
  };
}
