import {
  Types,
  VolumeViewport,
  utilities as csUtils,
  eventTarget,
  getEnabledElement,
  triggerEvent,
} from '@cornerstonejs/core';
import {
  Enums,
  ProbeTool,
  Types as ToolsTypes,
  annotation,
  cursors,
  drawing,
  state,
  utilities,
} from '@cornerstonejs/tools';
import merge from 'lodash/merge';
import getAnnotations from './utils/getAnnotations';

type StyleSpecifier = ToolsTypes.AnnotationStyle.StyleSpecifier;
type ProbeAnnotation = ToolsTypes.ToolSpecificAnnotationTypes.ProbeAnnotation;

const {
  selection: { isAnnotationSelected },
} = annotation;
const { transformWorldToIndex } = csUtils;
const { addAnnotation, removeAnnotation } = annotation.state;
const { drawHandles: drawHandlesSvg, drawTextBox: drawTextBoxSvg } = drawing;
const {
  triggerAnnotationRenderForViewportIds,
  viewportFilters: { getViewportIdsWithToolToRender },
} = utilities;
const { hideElementCursor, resetElementCursor } = cursors.elementCursor;

class GleamerKeypointTool extends ProbeTool {
  static toolName;

  touchDragCallback: any;
  mouseDragCallback: any;
  editData: {
    annotation: any;
    viewportIdsToRender: string[];
    newAnnotation?: boolean;
  } | null;
  eventDispatchDetail: {
    viewportId: string;
    renderingEngineId: string;
  };
  isDrawing: boolean;
  isHandleOutsideImage: boolean;

  constructor(
    toolProps: ToolsTypes.PublicToolProps = {},
    defaultToolProps: ToolsTypes.ToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        preventHandleOutsideImage: true,
      },
    }
  ) {
    super(merge({}, defaultToolProps, toolProps));
  }

  // Not necessary for this tool but needs to be defined since it's an abstract
  // method from the parent class.
  isPointNearTool(): boolean {
    return false;
  }

  /**
   * Based on the current position of the mouse and the current imageId to create
   * a Probe Annotation and stores it in the annotationManager
   *
   * @param evt -  EventTypes.NormalizedMouseEventType
   * @returns The annotation object.
   *
   */
  addNewAnnotation = (
    evt: ToolsTypes.EventTypes.InteractionEventType
  ): ToolsTypes.ToolSpecificAnnotationTypes.ProbeAnnotation => {
    const eventDetail = evt.detail;
    const { currentPoints, element } = eventDetail;
    const worldPos = currentPoints.world;

    const enabledElement = getEnabledElement(element);
    const { viewport, renderingEngine } = enabledElement;

    this.isDrawing = true;
    const camera = viewport.getCamera();
    const { viewPlaneNormal, viewUp } = camera;

    const referencedImageId = this.getReferencedImageId(
      viewport,
      worldPos,
      viewPlaneNormal,
      viewUp
    );

    const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

    const annotation = {
      invalidated: true,
      highlighted: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: [...viewPlaneNormal] as Types.Point3,
        viewUp: [...viewUp] as Types.Point3,
        FrameOfReferenceUID,
        referencedImageId,
      },
      data: {
        label: '',
        handles: { points: [[...worldPos] as Types.Point3] },
        cachedStats: {},
      },
    };

    addAnnotation(annotation, element);

    const viewportIdsToRender = getViewportIdsWithToolToRender(
      element,
      this.getToolName()
    );

    this.editData = {
      annotation,
      newAnnotation: true,
      viewportIdsToRender,
    };
    this._activateModify(element);

    hideElementCursor(element);

    evt.preventDefault();

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    return annotation;
  };

  _activateAdd = element => {
    state.isInteractingWithTool = true;

    element.addEventListener(Enums.Events.MOUSE_UP, this._endAddCallback);
    element.addEventListener(Enums.Events.MOUSE_CLICK, this._endAddCallback);

    element.addEventListener(Enums.Events.TOUCH_END, this._endAddCallback);
    element.addEventListener(Enums.Events.TOUCH_TAP, this._endAddCallback);
  };

  _deactivateAdd = element => {
    state.isInteractingWithTool = false;

    element.removeEventListener(Enums.Events.MOUSE_UP, this._endAddCallback);
    element.removeEventListener(Enums.Events.MOUSE_CLICK, this._endAddCallback);

    element.removeEventListener(Enums.Events.TOUCH_END, this._endAddCallback);
    element.removeEventListener(Enums.Events.TOUCH_TAP, this._endAddCallback);
  };

  _endAddCallback = (evt: ToolsTypes.EventTypes.InteractionEventType): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;

    const { annotation, viewportIdsToRender, newAnnotation } = this.editData;

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    const { viewportId } = enabledElement;
    this.eventDispatchDetail = {
      viewportId,
      renderingEngineId: renderingEngine.id,
    };

    this._deactivateAdd(element);

    resetElementCursor(element);

    this.editData = null;
    this.isDrawing = false;

    if (
      this.isHandleOutsideImage &&
      this.configuration.preventHandleOutsideImage
    ) {
      removeAnnotation(annotation.annotationUID);
    }

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    if (newAnnotation) {
      const eventType = Enums.Events.ANNOTATION_COMPLETED;

      const eventDetail: ToolsTypes.EventTypes.AnnotationCompletedEventDetail =
        {
          annotation,
        };

      triggerEvent(eventTarget, eventType, eventDetail);
    }
  };

  /**
   * it is used to draw the probe annotation in each
   * request animation frame. It calculates the updated cached statistics if
   * data is invalidated and cache it.
   *
   * @param enabledElement - The Cornerstone's enabledElement.
   * @param svgDrawingHelper - The svgDrawingHelper providing the context for drawing.
   */
  renderAnnotation = (
    enabledElement: Types.IEnabledElement,
    svgDrawingHelper: ToolsTypes.SVGDrawingHelper
  ): boolean => {
    let renderStatus = false;
    const { viewport } = enabledElement;
    const { element } = viewport;

    let annotations = getAnnotations(this.getToolName(), element);

    if (!annotations?.length) {
      return renderStatus;
    }

    annotations = this.filterInteractableAnnotationsForElement(
      element,
      annotations
    );

    if (!annotations?.length) {
      return renderStatus;
    }

    const targetId = this.getTargetId(viewport);
    const renderingEngine = viewport.getRenderingEngine();

    const styleSpecifier: StyleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i] as ProbeAnnotation;
      const annotationUID = annotation.annotationUID;
      const data = annotation.data;
      const point = data.handles.points[0];
      const canvasCoordinates = viewport.worldToCanvas(point);

      styleSpecifier.annotationUID = annotationUID;

      const color = this.getStyle('color', styleSpecifier, annotation);

      if (
        !data.cachedStats[targetId] ||
        data.cachedStats[targetId].value == null
      ) {
        data.cachedStats[targetId] = {
          Modality: null,
          index: null,
          value: null,
        };

        this._calculateCachedStats(annotation, renderingEngine, enabledElement);
      } else if (annotation.invalidated) {
        this._calculateCachedStats(annotation, renderingEngine, enabledElement);
        // If the invalidated data is as a result of volumeViewport manipulation
        // of the tools, we need to invalidate the related stackViewports data if
        // they are not at the referencedImageId, so that
        // when scrolling to the related slice in which the tool were manipulated
        // we re-render the correct tool position. This is due to stackViewport
        // which doesn't have the full volume at each time, and we are only working
        // on one slice at a time.
        if (viewport instanceof VolumeViewport) {
          const { referencedImageId } = annotation.metadata;

          // invalidate all the relevant stackViewports if they are not
          // at the referencedImageId
          for (const targetId in data.cachedStats) {
            if (targetId.startsWith('imageId')) {
              const viewports = renderingEngine.getStackViewports();

              const invalidatedStack = viewports.find(vp => {
                // The stack viewport that contains the imageId but is not
                // showing it currently
                const referencedImageURI =
                  csUtils.imageIdToURI(referencedImageId);
                const hasImageURI = vp.hasImageURI(referencedImageURI);
                const currentImageURI = csUtils.imageIdToURI(
                  vp.getCurrentImageId()
                );
                return hasImageURI && currentImageURI !== referencedImageURI;
              });

              if (invalidatedStack) {
                delete data.cachedStats[targetId];
              }
            }
          }
        }
      }

      // If rendering engine has been destroyed while rendering
      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');
        return renderStatus;
      }

      const handleGroupUID = '0';

      const width =
        annotation.highlighted || isAnnotationSelected(annotationUID)
          ? '3'
          : '2';

      drawHandlesSvg(
        svgDrawingHelper,
        annotationUID,
        handleGroupUID,
        [canvasCoordinates],
        { color, width }
      );

      renderStatus = true;

      const textLines = [annotation.data.label];
      if (textLines) {
        const textCanvasCoordinates = [
          canvasCoordinates[0] - 6,
          canvasCoordinates[1] - 28,
        ];

        const textBoxFontSize = this.getStyle(
          'textBoxFontSize',
          styleSpecifier,
          annotation
        );

        if (textBoxFontSize === '1') {
          const textUID = '0';
          drawTextBoxSvg(
            svgDrawingHelper,
            annotationUID,
            textUID,
            textLines,
            [textCanvasCoordinates[0], textCanvasCoordinates[1]],
            {
              padding: 0,
              background: 'rgba(0,0,0,0.5)',
              color: 'rgb(255,255,255)',
            }
          );
        }
      }
    }

    return renderStatus;
  };

  _calculateCachedStats(annotation, renderingEngine, enabledElement) {
    const data = annotation.data;
    const { viewportId, renderingEngineId } = enabledElement;

    const worldPos = data.handles.points[0];
    const { cachedStats } = data;

    const targetIds = Object.keys(cachedStats);

    for (let i = 0; i < targetIds.length; i++) {
      const targetId = targetIds[i];

      const image = this.getTargetIdImage(targetId, renderingEngine);

      // If image does not exists for the targetId, skip. This can be due
      // to various reasons such as if the target was a volumeViewport, and
      // the volumeViewport has been decached in the meantime.
      if (!image) {
        continue;
      }

      const { dimensions, imageData, metadata } = image;
      const scalarData =
        'getScalarData' in image ? image.getScalarData() : image.scalarData;

      const modality = metadata.Modality;
      const index = transformWorldToIndex(imageData, worldPos);

      index[0] = Math.round(index[0]);
      index[1] = Math.round(index[1]);
      index[2] = Math.round(index[2]);

      const samplesPerPixel =
        scalarData.length / dimensions[2] / dimensions[1] / dimensions[0];

      if (csUtils.indexWithinDimensions(index, dimensions)) {
        this.isHandleOutsideImage = false;
        const yMultiple = dimensions[0] * samplesPerPixel;
        const zMultiple = dimensions[0] * dimensions[1] * samplesPerPixel;

        const baseIndex =
          index[2] * zMultiple +
          index[1] * yMultiple +
          index[0] * samplesPerPixel;
        const value =
          samplesPerPixel > 2
            ? [
                scalarData[baseIndex],
                scalarData[baseIndex + 1],
                scalarData[baseIndex + 2],
              ]
            : scalarData[baseIndex];

        // Index[2] for stackViewport is always 0, but for visualization
        // we reset it to be imageId index
        if (targetId.startsWith('imageId:')) {
          const imageId = targetId.split('imageId:')[1];
          const imageURI = csUtils.imageIdToURI(imageId);
          const viewports = csUtils.getViewportsWithImageURI(
            imageURI,
            renderingEngineId
          );

          const viewport = viewports[0];

          index[2] = viewport.getCurrentImageIdIndex();
        }

        cachedStats[targetId] = {
          index,
          value,
          Modality: modality,
        };
      } else {
        this.isHandleOutsideImage = true;
        cachedStats[targetId] = {
          index,
          Modality: modality,
        };
      }

      annotation.invalidated = false;

      // Dispatching annotation modified
      const eventType = Enums.Events.ANNOTATION_MODIFIED;

      const eventDetail: ToolsTypes.EventTypes.AnnotationModifiedEventDetail = {
        annotation,
        viewportId,
        renderingEngineId,
      };

      triggerEvent(eventTarget, eventType, eventDetail);
    }

    return cachedStats;
  }
}

GleamerKeypointTool.toolName = 'GleamerKeypointTool';
export default GleamerKeypointTool;
