import { Api, OHIF } from '@gleamer/types';
import { NoMoreItemsError } from './errors';

type UserAuthenticationService = OHIF.UserAuthenticationService;
type ReviewSessionConfiguration = Api.ReviewSessionConfiguration;
type ReviewSession = Api.ReviewSession;

export async function createReviewSession(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  configuration: ReviewSessionConfiguration
) {
  const response = await fetch(`/api/review-sessions/${taskId}`, {
    method: 'POST',
    headers: new Headers({
      ...UserAuthenticationService.getAuthorizationHeader(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    body: JSON.stringify(configuration),
  });

  if (response.status === 204) {
    throw new NoMoreItemsError();
  }

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }

  return response.json();
}

export async function fetchReviewSession(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number
): Promise<ReviewSession> {
  const response = await fetch(`/api/review-sessions/${taskId}`, {
    method: 'GET',
    headers: UserAuthenticationService.getAuthorizationHeader(),
  });

  if (response.status === 204) {
    throw new NoMoreItemsError();
  }

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }

  return response.json();
}

export async function completeReviewSession(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number
) {
  const response = await fetch(`/api/review-sessions/${taskId}/complete`, {
    method: 'PUT',
    headers: UserAuthenticationService.getAuthorizationHeader(),
  });

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }

  return response.json();
}

export async function cancelReviewSession(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number
) {
  const response = await fetch(`/api/review-sessions/${taskId}`, {
    method: 'DELETE',
    headers: UserAuthenticationService.getAuthorizationHeader(),
  });

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }

  return response;
}
