import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { PrimaryButton } from './PrimaryButton';

export type ActionButtonProps = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  disabledReason?: JSX.Element;
  onClick?: () => any;
}>;

export function ActionButton({
  children,
  disabled,
  disabledReason,
  onClick,
  className,
}: ActionButtonProps) {
  if (disabled && disabledReason) {
    return (
      <Popover className={classNames('relative', className)}>
        <Popover.Button className="outline-node h-full w-full overflow-hidden focus:outline-none">
          {children}
        </Popover.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute right-0 w-[30rem] rounded-md border border-white bg-primary-dark p-4 text-base">
            {disabledReason}
          </Popover.Panel>
        </Transition>
      </Popover>
    );
  }

  return (
    <PrimaryButton
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </PrimaryButton>
  );
}
