import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectTools = (state: RootState) => state.tools;

export const getNextObs = createSelector(selectTools, tools => tools.nextObs);

export const getTool = createSelector(selectTools, tools => tools.tool);

export const getCurrentControl = createSelector(selectTools, tools => tools.currentControl);
