import { OHIF } from '@gleamer/types';
import { useAssetCharacterisations, useTask } from '@gleamer/ui';
import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { Icon } from '@ohif/ui';
import classnames from 'classnames';
import _ from 'lodash';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidStudy } from '../../../../utils/validation.utils';

const baseClasses =
  'first:border-0 border-t border-secondary-light select-none outline-none';

type StudyItemProps = {
  date: string;
  modalities: string[];
  numInstances: number;
  isActive?: boolean;
  onClick: (evt?: MouseEvent | KeyboardEvent) => any;
  onFillViewports: (evt?: MouseEvent) => any;
  displaySets: OHIF.DisplaySet[];
  activeDisplaySetInstanceUIDs: string[];
  studyInstanceUID: string;
};

export function StudyItem({
  date,
  numInstances,
  modalities,
  isActive,
  onClick,
  onFillViewports,
  displaySets,
  activeDisplaySetInstanceUIDs,
  studyInstanceUID,
}: StudyItemProps) {
  const { t } = useTranslation();

  const isDisplayed = displaySets.some(
    ds => activeDisplaySetInstanceUIDs?.includes(ds.displaySetInstanceUID)
  );

  const modalitiesString = _.sortBy(modalities).join(', ');

  const handleKeyDown = (evt: KeyboardEvent) => {
    // These are keys that are expected to activate a button
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role#:~:text=This%20includes%20handling%20the%20Enter%20and%20Space%20keypresses
    if (evt.key === ' ' || evt.key === 'Enter') {
      onClick(evt);
    }
  };

  const task = useTask();
  const characterisations = useAssetCharacterisations();
  const isValid = isValidStudy(task, characterisations, studyInstanceUID);

  return (
    <div
      className={classnames(
        {
          'bg-secondary-main': isDisplayed,
        },
        isActive ? 'bg-secondary-dark' : 'bg-black hover:bg-secondary-main',
        baseClasses
      )}
    >
      <div className="flex flex-1 flex-col px-2 py-1">
        <div
          className="grid grid-cols-12 items-center gap-2 pb-2 pt-2"
          onClick={onClick}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
          title={t('Toggle study')}
        >
          {isValid ? (
            <CheckBadgeIcon className="inline h-4 w-4 text-white" />
          ) : (
            <span />
          )}
          <div className="col-span-5 text-base text-white">{date}</div>
          <div className="col-span-2 text-base text-white">
            {modalitiesString}
          </div>
          <div className="col-span-2 flex flex-row items-center text-base text-blue-300">
            <Icon name="group-layers" className="mr-1 h-4 w-4 text-blue-300" />
            {numInstances}
          </div>
          <button
            className="col-span-2 flex flex-row items-center justify-self-end text-base"
            onClick={onFillViewports}
            title={`Display study ${date}`}
          >
            <ChevronDoubleRightIcon className="h-5 w-5 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
}
