import { utils } from '@ohif/core';
import { ViewportOverlay } from '@ohif/ui';
import { parse } from 'tinyduration';
import React from 'react';
import { useOverlayState } from '../../../contexts/OverlayStateContext/OverlayStateContext';
import { OHIF } from '@gleamer/types';
import { useCurrentDisplaySet } from '../../../hooks/useCurrentDisplaySet';
type ServicesManager = OHIF.ServicesManager;

type GleamerViewportOverlayProps = {
  servicesManager: ServicesManager;
  viewportId: string;
  imageIndex: number | null;
};

export function formatPatientAge(PatientAge: string): string {
  if (!PatientAge) {
    return '';
  }

  let PatientAgeToParse = PatientAge;
  if (!/^P/.test(PatientAge)) {
    PatientAgeToParse = `P${PatientAge}`;
  }

  const duration = parse(PatientAgeToParse);

  let years = duration.years || 0;
  let months = duration.months || 0;

  if (duration.months >= 12) {
    years += Math.floor(duration.months / 12);
    months = duration.months % 12;
  }

  let computedPatientAge = '';
  if (years > 0) {
    computedPatientAge += `${years} years`;
  }
  if (months > 0) {
    computedPatientAge += ` ${months} months`;
  }

  return computedPatientAge.trim();
}

function GleamerViewportOverlay({
  servicesManager,
  viewportId,
  imageIndex,
}: GleamerViewportOverlayProps) {
  const { DisplaySetService } = servicesManager.services;

  const displaySet = useCurrentDisplaySet(DisplaySetService, viewportId);
  const visibility = useOverlayState();

  if (imageIndex === null) {
    return null;
  }

  const image = displaySet?.images?.[imageIndex];

  if (visibility === 'hidden' || !image) {
    return null;
  }

  const {
    ViewCodeSequence,
    SliceThickness,
    StudyDate,
    ImageLaterality,
    PatientSex,
    PatientAge,
  } = image;

  const { CodeMeaning: ViewCodeMeaning } = ViewCodeSequence?.[0] || {};

  const computedPatientAge = formatPatientAge(PatientAge);

  const bottomLeftContent = (
    <>
      {!!PatientSex && (
        <div className="flex flex-row">
          <span className="mr-1">Sex: </span>
          <span className="font-light">{PatientSex}</span>
        </div>
      )}
      {!!PatientAge && (
        <div className="flex flex-row">
          <span className="mr-1">Age: </span>
          <span className="font-light">{computedPatientAge}</span>
        </div>
      )}
      {!!SliceThickness && (
        <div className="flex flex-row">
          <span className="mr-1">SliceThickness: </span>
          <span className="font-light">{SliceThickness}</span>
        </div>
      )}
      {!!StudyDate && (
        <div className="flex flex-row">
          <span className="mr-1">Study: </span>
          <span className="font-light">{utils.formatDate(StudyDate)}</span>
        </div>
      )}
    </>
  );

  const bottomRightContent = (
    <div className="flex flex-col text-right">
      {!!ImageLaterality && (
        <div>
          <span className="mr-1">Laterality: </span>
          <span className="font-light">{ImageLaterality}</span>
        </div>
      )}
      {!!ViewCodeMeaning && (
        <div>
          <span className="mr-1">View: </span>
          <span className="font-light">{ViewCodeMeaning}</span>
        </div>
      )}
    </div>
  );

  return (
    <ViewportOverlay
      bottomLeft={bottomLeftContent}
      bottomRight={bottomRightContent}
    />
  );
}

export default GleamerViewportOverlay;
