import { reviewSessionApis } from '@gleamer/apis';
import { useUserAuthentication } from '@ohif/ui';
import { useQuery } from '@tanstack/react-query';

const { fetchReviewSession } = reviewSessionApis;

export function useReviewSession({ taskId }: { taskId: number }) {
  const [, userAuthenticationService] = useUserAuthentication();

  return useQuery({
    queryKey: ['review-session', taskId],
    queryFn: () => fetchReviewSession(userAuthenticationService, taskId),
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
}
