import { OHIF } from '@gleamer/types';
import { useTask } from '@gleamer/ui';
import React from 'react';
import { useReports } from '../../../../contexts/ReportsContext';
import { PanelReportEntry } from './PanelReportEntry';
import { OpenReportsWindowButton } from './secondary/OpenReportsWindowButton';
import { byReportKey, getReportKey } from './utils';
import classNames from 'classnames';

type ServicesManager = OHIF.ServicesManager;

type ReportPanelProps = {
  servicesManager: ServicesManager;
  className?: string;
};

function EmptyReportsPanel({ className }: { className?: string }) {
  return (
    <div
      className={classNames(
        'flex flex-auto flex-grow flex-col justify-around overflow-hidden text-base text-primary-light',
        className
      )}
      data-testid="reports-panel"
    >
      <div className="flex justify-between p-2">No reports to display</div>
    </div>
  );
}

export function ReportPanel({ servicesManager, className }: ReportPanelProps) {
  const { patientReports, reports, fetchReport, patientId } = useReports();
  const task = useTask();

  if (task?.reportsHidden) {
    return <EmptyReportsPanel className={className} />;
  }

  const reportsEntries = [
    ...Object.values(reports).flatMap(r => r),
    ...patientReports,
  ]
    .sort(byReportKey)
    .map(report => {
      const key = getReportKey(report);
      return (
        <PanelReportEntry
          key={key}
          servicesManager={servicesManager}
          fetchReport={fetchReport}
          report={report}
          patientId={patientId}
          task={task}
        />
      );
    });

  if (reportsEntries.length === 0) {
    return <EmptyReportsPanel className={className} />;
  }

  return (
    <div
      className={classNames(
        'flex flex-auto flex-col overflow-hidden',
        className
      )}
      data-testid="reports-panel"
    >
      <OpenReportsWindowButton />
      <div className="flex flex-auto flex-col overflow-hidden">
        <ul className="ohif-scrollbar ml-2 overflow-y-auto pb-2">
          {reportsEntries}
        </ul>
      </div>
    </div>
  );
}
