import { Internal, OHIF } from '@gleamer/types';

type UserAuthenticationService = OHIF.UserAuthenticationService;
type DicomJSONInstance = Internal.DicomJSONInstance;
type DicomJSONReport = Internal.DicomJSONReport;
type PreviewParams = Internal.PreviewParams;

export async function fetchDicomJsonItemsLockedOrLabeledBy(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string,
  labeledBy?: string
): Promise<{ reports: DicomJSONReport[]; instances: DicomJSONInstance[] }> {
  const email = labeledBy || UserAuthenticationService.getUser().email;

  const response = await fetch(
    `/api/label-tasks/${taskId}/items/${itemId}/dicom-json-items/lockedOrLabeledBy/${email}`,
    {
      method: 'GET',
      headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
    }
  );

  if (response.status === 204) {
    throw new Error('No task found');
  }

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

export async function fetchDicomJsonItemsBySha(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string,
  sha256: string
): Promise<{ reports: DicomJSONReport[]; instances: DicomJSONInstance[] }> {
  const response = await fetch(
    `/api/label-tasks/${taskId}/items/${itemId}/dicom-json-items/sha256/${sha256}`,
    {
      method: 'GET',
      headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
    }
  );

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

function getPreviewPathname(params: PreviewParams) {
  let url = '';
  const keys = ['patient', 'study', 'series', 'instance'];

  for (const key of keys) {
    const value = params[key];
    if (!value) {
      return url;
    }

    url += `/${key}/${value}`;
  }

  return url;
}

export async function fetchDicomJsonItemsPreview(
  UserAuthenticationService: UserAuthenticationService,
  params: PreviewParams
) {
  const pathname = getPreviewPathname(params);

  const response = await fetch(`/api/previews${pathname}`, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}
