import { Types as CoreTypes } from '@cornerstonejs/core';
import { Types as ToolsTypes } from '@cornerstonejs/tools';
import GleamerRectangleTool from '../tools/GleamerRectangleTool';
import getSOPInstanceAttributes from './utils/getSOPInstanceAttributes';

export type OhifMeasurement = {
  uid: string;
  SOPInstanceUID: string;
  FrameOfReferenceUID: string;
  points: CoreTypes.Point3[];
  metadata: ToolsTypes.Annotation['metadata'];
  referenceSeriesUID: string;
  referenceStudyUID: string;
  toolName: string;
  displaySetInstanceUID: string;
  label: string;
  displayText?: string;
  data: ToolsTypes.Annotation['data'];
  type: string;
  getReport?: () => any;
  isActive?: boolean;
};

export const Rectangle = {
  toAnnotation: measurement => {},
  toMeasurement: (
    csToolsEventDetail: ToolsTypes.EventTypes.AnnotationAddedEventDetail,
    DisplaySetService: any,
    CornerstoneViewportService: any,
    getValueTypeFromToolType: (toolType: string) => string
  ): OhifMeasurement => {
    const { annotation, viewportId } = csToolsEventDetail;
    const { metadata, data, annotationUID } = annotation;

    if (!metadata || !data) {
      console.warn('Rectangle tool: Missing metadata or data');
      return null;
    }

    const { toolName, referencedImageId, FrameOfReferenceUID } = metadata;
    const validToolType = toolName === GleamerRectangleTool.toolName;

    if (!validToolType) {
      throw new Error('Tool not supported');
    }

    const { SOPInstanceUID, SeriesInstanceUID, StudyInstanceUID } =
      getSOPInstanceAttributes(
        referencedImageId,
        CornerstoneViewportService,
        viewportId
      );

    let displaySet;

    if (SOPInstanceUID) {
      displaySet = DisplaySetService.getDisplaySetForSOPInstanceUID(
        SOPInstanceUID,
        SeriesInstanceUID
      );
    } else {
      displaySet = DisplaySetService.getDisplaySetsForSeries(SeriesInstanceUID);
    }

    const { points } = data.handles;

    return {
      uid: annotationUID,
      SOPInstanceUID,
      FrameOfReferenceUID,
      points,
      metadata,
      referenceSeriesUID: SeriesInstanceUID,
      referenceStudyUID: StudyInstanceUID,
      toolName: metadata.toolName,
      displaySetInstanceUID: displaySet.displaySetInstanceUID,
      label: data.label,
      data,
      type: getValueTypeFromToolType(toolName),
    };
  },
};
