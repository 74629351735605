import {
  Enums as CoreEnums,
  Types as CoreTypes,
  eventTarget,
  getEnabledElement,
} from '@cornerstonejs/core';
import { Enums, Types as ToolsTypes, utilities } from '@cornerstonejs/tools';
import { Internal } from '@gleamer/types';
import {
  labelPass,
  tools,
  useObservationSubmissions,
  useTask,
} from '@gleamer/ui';
import { ServicesManager } from '@ohif/core';
import { cloneDeep, debounce, omit } from 'lodash';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentItem } from '../../hooks/useCurrentItem';
import GleamerRectangleTool, {
  ANNOTATION_DRAWING_FORBIDDEN,
  ANNOTATION_DRAWING_MISSCLICK,
  ANNOTATION_UPDATED,
} from '../../utils/tools/GleamerRectangleTool';

const {
  addObservation,
  addRegion,
  deleteRegion,
  setLastUpdatedByRegion,
  updateRegion,
} = labelPass.actions;

const csCoreEvents = CoreEnums.Events;
const csToolsEvents = Enums.Events;

type Region = Internal.RegionInternal;

type ObservationContextProviderProps = {
  servicesManager: ServicesManager;
};

export const OBSERVATION_EVENTS = {
  DELETE_NEARBY_REGION: 'event::delete_nearby_region',
};

function serialize<T>(region: T): T {
  return JSON.parse(JSON.stringify(region));
}

export function ObservationContextProvider({
  servicesManager,
  children,
}: PropsWithChildren<ObservationContextProviderProps>) {
  const {
    measurementService,
    uiNotificationService,
    cornerstoneViewportService,
  } = servicesManager.services;

  const task = useTask();

  const dispatch = useDispatch();
  const observations = useObservationSubmissions();
  const nextObs = useSelector(tools.selectors.getNextObs);
  const tool = useSelector(tools.selectors.getTool);
  const currentControl = useSelector(tools.selectors.getCurrentControl);
  const labelTaskItem = useCurrentItem({ servicesManager });

  useEffect(() => {
    const addedMeasurementSubscription = measurementService.subscribe(
      measurementService.EVENTS.MEASUREMENT_ADDED,
      async ({ measurement }) => {
        const { code, status, index } = nextObs;
        // @ts-ignore
        const region: Region = cloneDeep(omit(measurement, 'data.cachedStats'));
        if (currentControl) {
          region.characterisation = {
            [tool.control]: currentControl.const,
          };
        }

        const isExistingObs = observations.some(
          obs =>
            obs.code === code && obs.status === status && obs.index === index
        );

        const observationSpec = task.observations.find(
          obs => obs.code === code && obs.status === status
        );
        const colors = observationSpec?.colors;

        if (!isExistingObs) {
          dispatch(
            addObservation({
              code,
              status,
              region,
              colors,
              labelTask: task,
              labelTaskItem,
            })
          );
        } else {
          dispatch(
            addRegion({
              region,
              labelTask: task,
              labelTaskItem,
            })
          );
        }
      }
    );

    return () => {
      addedMeasurementSubscription.unsubscribe();
    };
  }, [
    measurementService,
    task,
    dispatch,
    nextObs,
    observations,
    currentControl,
    tool?.control,
    labelTaskItem,
    tool?.name,
  ]);

  useEffect(() => {
    function handleAnnotationRemoved(
      event: ToolsTypes.EventTypes.AnnotationRemovedEventType
    ) {
      const { detail } = event;
      const { annotationUID } = detail.annotation;
      dispatch(deleteRegion(annotationUID));
    }

    eventTarget.addEventListener(
      csToolsEvents.ANNOTATION_REMOVED,
      handleAnnotationRemoved
    );

    return () => {
      eventTarget.removeEventListener(
        csToolsEvents.ANNOTATION_REMOVED,
        handleAnnotationRemoved
      );
    };
  }, [dispatch]);

  useEffect(() => {
    let currentMousePoints: ToolsTypes.IPoints | null = null;

    function rectangleToolClickHandler(
      event: ToolsTypes.EventTypes.MouseDoubleClickEventType
    ) {
      const { element, currentPoints, event: nativeEvent } = event.detail;
      if (!element || !currentPoints) {
        return;
      }

      const enabledElement = getEnabledElement(element);
      if (!enabledElement) {
        console.warn('No enabled element found for element', element);
        return;
      }

      const nearbyToolData = utilities.getAnnotationNearPoint(
        element,
        currentPoints.canvas
      );

      if (nearbyToolData) {
        const typedNativeEvent = nativeEvent as Event;
        typedNativeEvent.preventDefault();
        typedNativeEvent.stopPropagation();
        dispatch(setLastUpdatedByRegion(nearbyToolData.annotationUID));
      }
    }

    function mouseMoveHandler(event: ToolsTypes.EventTypes.MouseMoveEventType) {
      const { currentPoints } = event.detail;
      currentMousePoints = currentPoints;
    }

    function deleteNearbyRegionHandler(element: HTMLDivElement) {
      return () => {
        if (!element || !currentMousePoints) {
          return;
        }

        const enabledElement = getEnabledElement(element);
        if (!enabledElement) {
          console.warn('No enabled element found for element', element);
          return;
        }

        const nearbyToolData = utilities.getAnnotationNearPoint(
          element,
          currentMousePoints.canvas
        );

        if (nearbyToolData) {
          dispatch(deleteRegion(nearbyToolData.annotationUID));
        }
      };
    }

    function elementEnabledHandler(
      evt: CoreTypes.EventTypes.ElementEnabledEvent
    ) {
      const { element } = evt.detail;

      element.addEventListener(
        csToolsEvents.MOUSE_DOUBLE_CLICK,
        rectangleToolClickHandler
      );

      element.addEventListener(csToolsEvents.MOUSE_MOVE, mouseMoveHandler);
      eventTarget.addEventListener(
        OBSERVATION_EVENTS.DELETE_NEARBY_REGION,
        deleteNearbyRegionHandler(element)
      );
    }

    function elementDisabledHandler(
      evt: CoreTypes.EventTypes.ElementDisabledEvent
    ) {
      const { element } = evt.detail;

      element.removeEventListener(
        csToolsEvents.MOUSE_DOUBLE_CLICK,
        rectangleToolClickHandler
      );
      element.removeEventListener(csToolsEvents.MOUSE_MOVE, mouseMoveHandler);
      eventTarget.removeEventListener(
        OBSERVATION_EVENTS.DELETE_NEARBY_REGION,
        deleteNearbyRegionHandler(element)
      );
    }

    function handleAnnotationForbidden({ detail }) {
      uiNotificationService.show({
        title: 'Failure',
        message: 'Unable to draw a region outside the image boundaries',
        position: 'topCenter',
        type: 'error',
      });

      dispatch(deleteRegion(detail.regionUID));
    }

    function handleAnnotationUpdated(
      evt: ToolsTypes.EventTypes.AnnotationModifiedEventType
    ) {
      const { annotation } = evt.detail;
      dispatch(updateRegion(serialize(annotation)));
    }

    const debouncedHandleAnnotationUpdated = debounce(
      handleAnnotationUpdated,
      200
    );

    function handleAnnotationModified(
      evt: ToolsTypes.EventTypes.AnnotationModifiedEventType
    ) {
      const { annotation } = evt.detail;
      if (annotation.metadata.toolName !== GleamerRectangleTool.toolName) {
        dispatch(updateRegion(serialize(annotation)));
      }
    }

    const debouncedHandleAnnotationModified = debounce(
      handleAnnotationModified,
      200
    );

    function handleAnnotationMissclick({ detail }) {
      console.info('Ignoring drawing - box too small.');
      dispatch(deleteRegion(detail.regionUID));
    }

    const eventsHandlers = [
      {
        event: ANNOTATION_DRAWING_MISSCLICK,
        handler: handleAnnotationMissclick,
      },
      {
        event: ANNOTATION_DRAWING_FORBIDDEN,
        handler: handleAnnotationForbidden,
      },
      {
        event: csCoreEvents.ELEMENT_ENABLED,
        handler: elementEnabledHandler.bind(null),
      },
      {
        event: csCoreEvents.ELEMENT_DISABLED,
        handler: elementDisabledHandler.bind(null),
      },
      {
        event: csToolsEvents.ANNOTATION_MODIFIED,
        handler: debouncedHandleAnnotationModified,
      },
      {
        event: ANNOTATION_UPDATED,
        handler: debouncedHandleAnnotationUpdated,
      },
    ];

    eventsHandlers.forEach(({ event, handler }) => {
      eventTarget.addEventListener(event, handler);
    });

    return () => {
      eventsHandlers.forEach(({ event, handler }) => {
        eventTarget.removeEventListener(event, handler);
      });
    };
  }, [
    cornerstoneViewportService,
    uiNotificationService,
    dispatch,
    observations,
  ]);

  return <>{children}</>;
}
