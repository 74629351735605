import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectTaskItem = (state: RootState) => state.labelTask;

export const getTask = createSelector(selectTaskItem, labelTask => labelTask.task);

export const getStatus = createSelector(selectTaskItem, labelTask => labelTask.status);

export const isTaskDoneLoading = createSelector(getStatus, status => status === 'idle');

export const getObservationsSpecifications = createSelector(
  getTask,
  labelTask => labelTask?.observations || []
);

export const getAvailableTools = createSelector(getTask, labelTask => {
  if (!labelTask) {
    return [];
  }

  return Array.from(
    new Set(
      labelTask.observations
        .flatMap(obs => obs.regions)
        .map(region => region.tool)
        .map(tool => tool.name)
    )
  );
});

export const getOnlyObservation = createSelector(getObservationsSpecifications, observations => {
  if (observations.length !== 1) {
    return null;
  }
  return observations[0];
});
