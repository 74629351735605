/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Assets, Internal } from '@gleamer/types';
import { RJSFSchema, getDefaultFormState } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import { produce } from 'immer';
import { isEqual, merge } from 'lodash';
import { contextMappers } from './characterisations-context';

type LabelTask = Internal.LabelTask;
type LabelTaskItem = Internal.LabelTaskItem;
type AssetCharacterisationSubmission = Internal.AssetCharacterisationInternal;
type AssetRef = Assets.AssetRef;
type InstanceAssetRef = Assets.InstanceAssetRef;
type PatientAssetRef = Assets.PatientAssetRef;
type SeriesAssetRef = Assets.SeriesAssetRef;
type StudyAssetRef = Assets.StudyAssetRef;
type PatientReportAssetRef = Assets.PatientReportAssetRef;
type StudyReportAssetRef = Assets.StudyReportAssetRef;
type ObservationSubmission = Internal.ObservationInternal;
type ObservationsSpecifications = Internal.ObservationsSpecifications;

export function hasSchemaDefaults(schema: RJSFSchema | null): schema is RJSFSchema {
  if (!schema) {
    return false;
  }
  if (schema.default !== undefined) {
    return true;
  }
  if (typeof schema === 'object' && schema !== null) {
    for (const key in schema) {
      if (hasSchemaDefaults(schema[key])) {
        return true;
      }
    }
  }
  return false;
}

function withContextValues(
  data: Record<string, any>,
  context: Record<string, any>
): Record<string, any> {
  // Data should not be modified in place (causes issue with immer and with proxy object)
  const initialData = data ? JSON.parse(JSON.stringify(data)) : {};

  return Object.entries(context).reduce((acc, [key, value]) => {
    acc[`context.${key}`] = value;
    return acc;
  }, initialData);
}

// ChatGPT generated code to get default values before validation
function extractDefaults(schema) {
  const defaults = {};

  if (schema.hasOwnProperty('properties')) {
    for (const prop in schema.properties) {
      const definition = schema.properties[prop];

      if (definition.hasOwnProperty('default')) {
        defaults[prop] = definition.default;
      }

      if (definition.hasOwnProperty('properties')) {
        defaults[prop] = extractDefaults(definition);
      } else if (definition.hasOwnProperty('items')) {
        defaults[prop] = [extractDefaults(definition.items)];
      }
    }
  }

  return defaults;
}

export function validateWithDefaults(schema: RJSFSchema, characValue: Record<string, any>) {
  const validator = customizeValidator({
    ajvOptionsOverrides: {
      useDefaults: true,
    },
  });
  const defaults = extractDefaults(schema);
  const initialValues = merge({}, defaults, characValue);
  return getDefaultFormState(validator, schema, initialValues, schema, false, {
    emptyObjectFields: 'populateAllDefaults',
  });
}

function setAssetCharac(
  initialCharacterisations: AssetCharacterisationSubmission[] = [],
  assetRef: AssetRef,
  assetSchema: RJSFSchema | null | undefined,
  context: any = {}
) {
  if (!assetSchema) {
    return initialCharacterisations;
  }
  const existingCharac = initialCharacterisations.find(
    charac => charac && isEqual(charac.asset, assetRef)
  );
  const existing = existingCharac || {
    asset: assetRef,
    characterisation: {},
  };

  const value = withContextValues(existing.characterisation, context);
  const valueWithDefaults = validateWithDefaults(assetSchema, value);

  if (existingCharac) {
    return initialCharacterisations.map(charac => {
      if (charac === existingCharac) {
        return {
          ...charac,
          characterisation: valueWithDefaults,
        };
      }
      return charac;
    });
  } else {
    return initialCharacterisations.concat({
      asset: assetRef,
      characterisation: valueWithDefaults,
    });
  }
}

function getDefaultCharacterisations(
  characterisationsSchemas: Internal.AssetCharacterisationSpecifications,
  labelTaskItem: LabelTaskItem,
  initialCharacterisations: AssetCharacterisationSubmission[] = [],
  filters: {
    StudyInstanceUIDs?: string[];
    SeriesInstanceUIDs?: string[];
    SOPInstanceUIDs?: string[];
  } = {}
) {
  if (!characterisationsSchemas) {
    return initialCharacterisations;
  }

  if (!labelTaskItem?.studies) {
    return initialCharacterisations;
  }

  const patientSchema = characterisationsSchemas?.patient?.json;
  const studySchema = characterisationsSchemas?.study?.json;
  const seriesSchema = characterisationsSchemas?.series?.json;
  const instanceSchema = characterisationsSchemas?.instance?.json;
  const studyReportSchema = characterisationsSchemas?.studyReport?.json;
  const patientReportSchema = characterisationsSchemas?.patientReport?.json;

  const { PatientID } = labelTaskItem.studies[0];
  const patientAssetRef: PatientAssetRef = {
    kind: 'patient',
    patientId: PatientID,
  };

  const patientContext = contextMappers.Patient(
    labelTaskItem.studies[0].series[0].instances[0].metadata
  );

  let updatedCharacterisations = setAssetCharac(
    initialCharacterisations,
    patientAssetRef,
    patientSchema,
    patientContext
  );

  // Studies
  labelTaskItem.studies
    .filter(study =>
      filters.StudyInstanceUIDs ? filters.StudyInstanceUIDs.includes(study.StudyInstanceUID) : true
    )
    .forEach(study => {
      const { StudyInstanceUID, PatientID } = study;

      const studyAssetRef: StudyAssetRef = {
        kind: 'study',
        patientId: PatientID,
        studyInstanceUID: StudyInstanceUID,
      };

      const studyContext = contextMappers.Study(study.series[0].instances[0].metadata);
      updatedCharacterisations = setAssetCharac(
        updatedCharacterisations,
        studyAssetRef,
        studySchema,
        studyContext
      );

      study.series
        .filter(series =>
          filters.SeriesInstanceUIDs
            ? filters.SeriesInstanceUIDs.includes(series.SeriesInstanceUID)
            : true
        )
        .forEach(series => {
          const { SeriesInstanceUID } = series;
          const seriesAssetRef: SeriesAssetRef = {
            ...studyAssetRef,
            kind: 'series',
            seriesInstanceUID: SeriesInstanceUID,
          };

          const seriesContext = contextMappers.Series(series.instances[0].metadata);
          updatedCharacterisations = setAssetCharac(
            updatedCharacterisations,
            seriesAssetRef,
            seriesSchema,
            seriesContext
          );

          if (hasSchemaDefaults(instanceSchema)) {
            series.instances
              .filter(instance =>
                filters.SOPInstanceUIDs
                  ? filters.SOPInstanceUIDs.includes(instance.metadata.SOPInstanceUID)
                  : true
              )
              .forEach(instance => {
                const { SOPInstanceUID } = instance.metadata;
                const instanceAssetRef: InstanceAssetRef = {
                  ...seriesAssetRef,
                  kind: 'instance',
                  sopInstanceUID: SOPInstanceUID,
                };

                const instanceContext = contextMappers.Instance(instance.metadata);
                updatedCharacterisations = setAssetCharac(
                  updatedCharacterisations,
                  instanceAssetRef,
                  instanceSchema,
                  instanceContext
                );
              });
          }
        });

      // Reports
      labelTaskItem.reports.forEach(report => {
        const kind = report.StudyInstanceUID ? 'studyReport' : 'patientReport';

        if (kind === 'patientReport') {
          const reportRef: PatientReportAssetRef = {
            kind,
            patientId: report.PatientId,
            id: report.id,
          };

          const reportContext = contextMappers.Patient(
            labelTaskItem.studies[0].series[0].instances[0].metadata
          );

          updatedCharacterisations = setAssetCharac(
            updatedCharacterisations,
            reportRef,
            patientReportSchema,
            reportContext
          );
        }

        if (kind === 'studyReport') {
          const reportRef: StudyReportAssetRef = {
            kind,
            patientId: report.PatientId,
            studyInstanceUID: report.StudyInstanceUID,
            id: report.id,
          };

          const study = labelTaskItem.studies?.find(
            study => study.StudyInstanceUID === report.StudyInstanceUID
          );
          let reportContext: any = {};
          if (study) {
            reportContext = contextMappers.Study(study.series[0].instances[0].metadata);
          }
          updatedCharacterisations = setAssetCharac(
            updatedCharacterisations,
            reportRef,
            studyReportSchema,
            reportContext
          );
        }
      });
    });

  return updatedCharacterisations;
}

export function getDefaultAssetsCharacterisations(
  labelTask: LabelTask,
  labelTaskItem: LabelTaskItem,
  characterisations: AssetCharacterisationSubmission[] = []
) {
  const { characterisations: characterisationsSchemas } = labelTask;
  return getDefaultCharacterisations(characterisationsSchemas, labelTaskItem, characterisations);
}

export function getObservationDefaultCharacterisations(
  observationSpecification: Internal.ObservationsSpecifications,
  labelTaskItem: LabelTaskItem,
  observation: ObservationSubmission
): ObservationSubmission['characterisations'] {
  const { characterisations, regions } = observation;

  if (!observationSpecification?.characterisations) {
    return [];
  }

  const filters = {
    StudyInstanceUIDs: regions.map(region => region.referenceStudyUID),
    SeriesInstanceUIDs: regions.map(region => region.referenceSeriesUID),
    SOPInstanceUIDs: regions.map(region => region.SOPInstanceUID),
  };

  return getDefaultCharacterisations(
    observationSpecification.characterisations,
    labelTaskItem,
    characterisations,
    filters
  );
}

export function getRegionWithDefaultCharacterisations(
  regionSpecification: Internal.RegionSpecification,
  labelTaskItem: LabelTaskItem,
  region: Internal.RegionInternal
) {
  if (!regionSpecification?.characterisation) {
    return region;
  }

  const { characterisation } = regionSpecification;
  if (!characterisation) {
    return region;
  }

  const { json: schema } = characterisation;
  if (!schema) {
    return region;
  }

  const study = labelTaskItem.studies?.find(
    study => study.StudyInstanceUID === region.referenceStudyUID
  );
  if (!study) {
    return region;
  }

  const series = study.series.find(
    series => series.SeriesInstanceUID === region.referenceSeriesUID
  );

  if (!series) {
    return region;
  }

  const instance = series.instances.find(
    instance => instance.metadata.SOPInstanceUID === region.SOPInstanceUID
  );
  if (!instance) {
    return region;
  }

  return produce(region, draft => {
    const context = contextMappers.Instance(instance.metadata);
    const initialValue = withContextValues(draft.characterisation, context);
    const value = validateWithDefaults(schema, initialValue);
    region.characterisation = value;
  });
}

export function getObservationWithDefaults(
  observationSpecification: ObservationsSpecifications,
  labelTaskItem: LabelTaskItem,
  observation: ObservationSubmission
): ObservationSubmission {
  if (!observationSpecification) {
    return observation;
  }

  const defaultCharacterisations = getObservationDefaultCharacterisations(
    observationSpecification,
    labelTaskItem,
    observation
  );

  return produce(observation, (draft: ObservationSubmission) => {
    draft.characterisations = defaultCharacterisations;

    draft.regions = draft.regions.map(region => {
      return getRegionWithDefaultCharacterisations(
        observationSpecification.regions.find(
          regionSpecification => regionSpecification.tool.name === region.toolName
        ),
        labelTaskItem,
        region
      );
    });
  });
}
