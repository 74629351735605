import { useAuth0 } from '@auth0/auth0-react';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UserAuthenticationService } from '../types/UserAuthenticationService';

type Auth0HandlerProps = {
  UserAuthenticationService: UserAuthenticationService;
};

export function Auth0Handler({
  UserAuthenticationService,
  children,
}: PropsWithChildren<Auth0HandlerProps>) {
  const {
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const {
    set: setAuthenticationService,
    setUser,
    setServiceImplementation,
    getUser,
  } = UserAuthenticationService;

  const refreshToken = useCallback(
    async function () {
      if (isLoading || !isAuthenticated) {
        return;
      }

      const token = await getAccessTokenSilently();
      const serviceUser = getUser();
      if (!serviceUser || serviceUser.access_token !== token) {
        setUser({
          access_token: token,
          ...user,
        });
      }
    },
    [getAccessTokenSilently, isAuthenticated, isLoading, setUser, user, getUser]
  );

  const handleUnauthenticated = useCallback(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    }

    // return null because this is used in a react component
    return null;
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    window.location.pathname,
    window.location.search,
  ]);

  const getAuthorizationHeader = useCallback(() => {
    const ohifUser = getUser();
    if (!isAuthenticated || !ohifUser) {
      return {};
    }

    // A call is made, just refresh current token
    // Be sure that Auth0 configuration does not invalidate previous token.
    refreshToken();

    return {
      Authorization: `Bearer ${ohifUser.access_token}`,
    };
  }, [getUser, isAuthenticated, refreshToken]);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    setAuthenticationService({ enabled: true });
    setServiceImplementation({
      getAuthorizationHeader,
      handleUnauthenticated,
    });
    setInitialized(true);
  }, [
    setAuthenticationService,
    setServiceImplementation,
    getAuthorizationHeader,
    handleUnauthenticated,
  ]);

  // To avoid useless refresh while Auth0 is fetching user, we render nothing
  if (isLoading || !initialized) {
    return null;
  }

  // Fragment is here to make TypeScript happy in "./Provider.tsx"
  return <>{children}</>;
}
