import { ServicesManager } from '@ohif/core';
import { ToolbarButton } from '@ohif/ui';
import React, { PropsWithChildren, useEffect, useState } from 'react';

type CompositeToolbarButtonProps = PropsWithChildren<{
  id: string;
  servicesManager: ServicesManager;
}> &
  React.ComponentProps<typeof ToolbarButton>;

export function CompositeToolbarButton({
  children,
  id,
  ...props
}: CompositeToolbarButtonProps) {
  const { toolbarService } = props.servicesManager?.services || {};

  const [buttonsState, setButtonState] = useState({
    primaryToolId: '',
    toggles: {},
    groups: {},
  });

  const isActive = buttonsState.toggles[id] === true;

  useEffect(() => {
    const { unsubscribe } = toolbarService.subscribe(
      toolbarService.EVENTS.TOOL_BAR_STATE_MODIFIED,
      state => {
        setButtonState({ ...state });
      }
    );

    return () => {
      unsubscribe();
    };
  }, [toolbarService]);

  return (
    <div key={id} className="flex items-center text-white">
      <ToolbarButton isActive={isActive} id={id} {...props} />
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, { isActive, ...props });
      })}
    </div>
  );
}
