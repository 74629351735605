import { Viewport, getEnabledElement } from '@cornerstonejs/core';
import { ToolGroupManager } from '@cornerstonejs/tools';
import { isReadonly, labelPass, labelTask, tools, useTask } from '@gleamer/ui';
import { useViewportGrid } from '@ohif/ui';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMatchingRegion } from '../../../utils/region.utils';
import { getBindingsForTool } from '../../../utils/tools/mouse-bindings';
import { useNextObservation } from './useNextObservation';

export function useViewportTool({
  viewportId,
  currentModality,
  currentSopClassUID,
  viewport,
}: {
  viewportId: string;
  currentModality?: string;
  currentSopClassUID?: string;
  viewport: Viewport | null;
}) {
  const dispatch = useDispatch();

  const readOnly = isReadonly();
  const [{ activeViewportId }] = useViewportGrid();

  const availableTools = useSelector(labelTask.selectors.getAvailableTools);
  const currentTool = useSelector(tools.selectors.getTool);

  const task = useTask();
  const lastUpdatedObs = useSelector(labelPass.selectors.getLastUpdatedObs);
  const previousObsUid = useRef<string | undefined>(lastUpdatedObs?.uid);

  const nextObs = useSelector(tools.selectors.getNextObs);
  const singleObservationSpec = useSelector(
    labelTask.selectors.getOnlyObservation
  );

  const { selectNextObs } = useNextObservation({
    modality: currentModality,
    sopClassUID: currentSopClassUID,
  });

  const isActiveViewport = !readOnly && activeViewportId === viewportId;

  useEffect(() => {
    if (!currentSopClassUID || !currentModality || !isActiveViewport) {
      return;
    }

    if (singleObservationSpec && !nextObs?.code) {
      selectNextObs(singleObservationSpec);
      const matchingRegionSpec = singleObservationSpec.regions.find(region =>
        isMatchingRegion(region, currentSopClassUID, currentModality)
      );
      if (matchingRegionSpec?.tool) {
        dispatch(tools.actions.setTool(matchingRegionSpec.tool));
      }
      return;
    }

    if (!lastUpdatedObs && previousObsUid.current) {
      previousObsUid.current = undefined;
      dispatch(tools.actions.clear());
      return;
    }

    if (lastUpdatedObs) {
      previousObsUid.current = lastUpdatedObs.uid;

      const { code, status } = lastUpdatedObs;
      const matchingObs = task?.observations.find(
        obs => obs.code === code && obs.status === status
      );

      if (!matchingObs) {
        dispatch(tools.actions.setTool(null));
        return;
      }

      const matchingRegion = matchingObs.regions.find(region =>
        isMatchingRegion(region, currentSopClassUID, currentModality)
      );

      if (!matchingRegion) {
        dispatch(tools.actions.setTool(null));
        return;
      }

      dispatch(tools.actions.setTool(matchingRegion.tool));
    }
  }, [
    currentModality,
    currentSopClassUID,
    dispatch,
    isActiveViewport,
    lastUpdatedObs,
    nextObs?.code,
    selectNextObs,
    singleObservationSpec,
    task?.observations,
  ]);

  const updateTool = useCallback(() => {
    if (!viewport) {
      return;
    }

    const { renderingEngineId, viewportId } = getEnabledElement(
      viewport.element
    );
    const toolGroup = ToolGroupManager.getToolGroupForViewport(
      viewportId,
      renderingEngineId
    );

    availableTools
      .filter(toolName => toolName !== currentTool?.name)
      .forEach(toolName => {
        toolGroup.setToolEnabled(toolName);
      });

    if (!currentTool?.name) {
      dispatch(tools.actions.clear());
      return;
    }

    const currentToolOption = toolGroup?.toolOptions?.[currentTool.name];
    if (!currentToolOption) {
      dispatch(tools.actions.clear());
      return;
    }

    if (currentToolOption.mode === 'Disabled') {
      dispatch(tools.actions.clear());
      return;
    }

    // Activate tool if available in current tool group
    const bindings = getBindingsForTool('Annotation');
    toolGroup.setToolActive(currentTool.name, { bindings });
  }, [availableTools, currentTool?.name, viewport, dispatch]);

  useEffect(() => {
    if (!isActiveViewport) {
      return;
    }

    updateTool();
  }, [isActiveViewport, updateTool]);
}
