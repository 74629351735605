import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { GdataHeader } from '../molecules/GdataLayout/GdataHeader';

const GdataLayout = () => {
  return (
    <>
      <GdataHeader />
      <div className="p-4">
        <Outlet />
      </div>
    </>
  );
};
export default withAuthenticationRequired(GdataLayout);
