import { Api, OHIF } from '@gleamer/types';

type PredictionsApi = Api.PredictionsApi;
type UserAuthenticationService = OHIF.UserAuthenticationService;

export async function fetchPredictions(
  UserAuthenticationService: UserAuthenticationService,
  patientId: string,
  taskId?: number
): Promise<PredictionsApi> {
  let response: Response;

  if (!taskId) {
    response = await fetch(`/api/predictions/patient/${patientId}`, {
      method: 'GET',
      headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
    });
  } else {
    response = await fetch(`/api/predictions/task/${taskId}/patient/${patientId}`, {
      method: 'GET',
      headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
    });
  }

  if (response.status === 404) {
    console.log(`${response.status} : ${response.statusText}`);
    return {
      patientId,
      predictions: [],
    };
  }
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }

  return response.json();
}
