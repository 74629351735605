import React, { useRef } from 'react';
import { Ranger, useRanger } from '@tanstack/react-ranger';
import classNames from 'classnames';

type RangeSliderProps = {
  className?: string;
  values: number[];
  setValues: (values: number[]) => void;
  min?: number;
  max?: number;
  'data-testid'?: string;
};

export function RangeSlider({
  className,
  values,
  setValues,
  min = 0,
  max = 100,
  'data-testid': dataTestId,
}: RangeSliderProps) {
  const rangerRef = useRef<HTMLDivElement>(null);
  const rangerInstance = useRanger<HTMLDivElement>({
    getRangerElement: () => rangerRef.current,
    values,
    min: min ?? 0,
    max: max ?? 100,
    stepSize: 1,
    onChange: (instance: Ranger<HTMLDivElement>) => setValues(instance.sortedValues as number[]),
  });

  return (
    <div
      className={classNames(
        'relative mx-3 flex h-1 w-32 select-none rounded-sm bg-[#ddd]',
        className
      )}
      ref={rangerRef}
      data-testid={dataTestId}
    >
      {rangerInstance.getSteps().map(({ left, width }, i) => (
        <div
          key={i}
          className="absolute h-full"
          style={{
            left: `${left}%`,
            width: `${width}%`,
            background: i === 1 ? '#348cfd' : '#ffffff',
          }}
        />
      ))}
      {rangerInstance
        .handles()
        .map(({ value, onMouseDownHandler, onKeyDownHandler, onTouchStart, isActive }, i) => (
          <button
            className={classNames(
              'absolute top-1/2 flex h-6 w-6 items-center justify-center whitespace-nowrap rounded-full border border-[#888] bg-[#ededed] text-xs font-normal text-black',
              {
                '-translate-x-1/2 -translate-y-1/2 scale-90': !isActive,
                '-translate-x-1/2 -translate-y-full scale-125': isActive,
              }
            )}
            key={i}
            role="slider"
            onKeyDown={onKeyDownHandler}
            onMouseDown={onMouseDownHandler}
            onTouchStart={onTouchStart}
            aria-valuemin={rangerInstance.options.min}
            aria-valuemax={rangerInstance.options.max}
            aria-valuenow={value}
            style={{
              left: `${rangerInstance.getPercentageForValue(value)}%`,
            }}
          >
            {value}
          </button>
        ))}
    </div>
  );
}
