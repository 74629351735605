import { errorsApis } from '@gleamer/apis';
import { Internal } from '@gleamer/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useReviewSession } from '../../hooks/useReviewSession';

const { isNoMoreItemsError } = errorsApis;

type LabelTask = Internal.LabelTask;

type TaskLinkProps = {
  task: Pick<LabelTask, 'taskId' | 'reviewer'>;
};

function LabelerTaskLink({ task }: TaskLinkProps) {
  return (
    <Link to={`/ohif/label?task=${task.taskId}`} className="cursor-pointer">
      {task.taskId}
    </Link>
  );
}

function ReviewerTaskLink({ task }: TaskLinkProps) {
  const { data, isLoading, error } = useReviewSession({ taskId: task.taskId });

  if (isNoMoreItemsError(error as Error)) {
    return (
      <Link to={`/review-session/${task.taskId}`} className="cursor-pointer">
        {task.taskId}
      </Link>
    );
  }

  if (isLoading || error) {
    return <div>{task.taskId}</div>;
  }

  const urlParams = new URLSearchParams();
  urlParams.append('task', `${task.taskId}`);
  urlParams.append('reviewMode', data.reviewMode);
  if (data.itemToReviewId) {
    urlParams.append('item', data.itemToReviewId);
  }
  if (data.labeler) {
    urlParams.append('labeledBy', data.labeler);
  }

  return (
    <Link
      to={`/ohif/review/review?${urlParams.toString()}`}
      className="cursor-pointer"
    >
      {task.taskId}
    </Link>
  );
}

export function TaskLink({ task }: TaskLinkProps) {
  if (task.reviewer) {
    return <ReviewerTaskLink task={task} />;
  }

  return <LabelerTaskLink task={task} />;
}
