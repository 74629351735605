import { useAuth0 } from '@auth0/auth0-react';
import { Duration } from 'date-fns';
import { useCallback, useEffect } from 'react';
import IdleTimer from '../utils/IdleTimeout';

export const DEFAULT_LOGOUT_DELAY: Duration = {
  minutes: process.env.NODE_ENV === 'development' ? 30 : 2,
};

const ALLOWED_REDIRECT_PATH = [
  '',
  '/',
  '/reports',
  '/my-label-tasks',
  '/ohif/label',
  '/ohif/readonly/review',
  '/ohif/review/review',
  '/ohif/preview/preview',
];

function isAllowedRedirectUrl(path: string): boolean {
  return ALLOWED_REDIRECT_PATH.includes(path);
}

type UseAutoLogoutArgs = {
  timeout: Duration;
};

export function useAutoLogout(
  { timeout = DEFAULT_LOGOUT_DELAY }: UseAutoLogoutArgs = {
    timeout: DEFAULT_LOGOUT_DELAY,
  }
) {
  const { logout } = useAuth0();

  const logoutCallback = useCallback(() => {
    const returnTo = isAllowedRedirectUrl(window.location.pathname)
      ? window.location.href
      : window.location.origin;

    logout({ logoutParams: { returnTo } });
  }, [logout]);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout:
        process.env.NODE_ENV !== 'development' ? timeout : { minutes: 30 },
      onTimeout: () => {
        logoutCallback();
      },
      onExpired: () => {
        // do something if expired on load
        logoutCallback();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, [logoutCallback, timeout]);
}
