import { isReadonly, useAssetCharacterisations, useTask } from '@gleamer/ui';
import {
  CheckBadgeIcon,
  IdentificationIcon,
} from '@heroicons/react/24/outline';
import { ServicesManager } from '@ohif/core';
import { Tooltip } from '@ohif/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentItem } from '../../../hooks/useCurrentItem';
import { isValidPatient } from '../../../utils/validation.utils';
import { CharacterisationButton } from '../../atoms/CharacterisationButton';
import { AssetCharacterisationForm } from '../modals/CharacterisationForm/AssetCharacterisationForm';
import { BUTTON_IDS } from './constants';

type PatientCharacterisationButtonProps = {
  servicesManager: ServicesManager;
};

export function PatientCharacterisationButton({
  servicesManager,
}: PatientCharacterisationButtonProps) {
  const task = useTask();

  const currentItem = useCurrentItem({ servicesManager });
  const { t } = useTranslation();
  const { uiModalService } = servicesManager.services;

  const characterisations = useAssetCharacterisations();
  const readOnly = isReadonly();

  const isValid = isValidPatient(task, characterisations);

  const onClick = () => {
    const { studies } = currentItem;
    if (!studies || studies.length < 1) {
      return;
    }
    const study = studies[0];
    const { PatientID: patientId } = study;
    uiModalService.show({
      content: AssetCharacterisationForm,
      contentProps: {
        jsonSchema: task.characterisations.patient.json,
        uiSchema: task.characterisations.patient.ui,
        asset: { kind: 'patient', patientId },
        readOnly,
        servicesManager,
      },
      shouldCloseOnEsc: true,
      closeButton: true,
      title: t('Patient Characterisation'),
    });
  };

  return (
    <div className="flex items-center">
      <Tooltip content={t('Patient Characterisation')}>
        <CharacterisationButton
          id={BUTTON_IDS.PATIENT}
          onClick={onClick}
          className="inline-flex items-center justify-center rounded-md p-2 text-center text-lg font-bold text-white outline-none transition duration-300 ease-in-out hover:bg-primary-light hover:text-black focus:bg-primary-light focus:text-black focus:outline-none active:opacity-80"
        >
          <IdentificationIcon className="h-6 w-6" />
          {isValid && (
            <CheckBadgeIcon className="absolute bottom-0 right-0 h-4 w-4 group-hover:text-secondary-dark" />
          )}
        </CharacterisationButton>
      </Tooltip>
    </div>
  );
}
