import { useAuth0 } from '@auth0/auth0-react';
import { OHIF } from '@gleamer/types';
import { labelPass, useTask } from '@gleamer/ui';
import {
  CommandsManager,
  ExtensionManager,
  HangingProtocolService,
  HotkeysManager,
  PanelService,
  ServicesManager,
  hotkeys,
} from '@ohif/core';
import {
  AboutModal,
  ErrorBoundary,
  LoadingIndicatorProgress,
  useModal,
} from '@ohif/ui';
import { useAppConfig } from '@state';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAutoLogout } from '../../../hooks/useAutoLogout';
import GleamerKeypointTool from '../../../utils/tools/GleamerKeypointTool';
import GleamerRectangleTool from '../../../utils/tools/GleamerRectangleTool';
import {
  getDefaultMouseBindings,
  getMouseBindingsConfigs,
  getMouseBindingsConfigsKey,
} from '../../../utils/tools/mouse-bindings';
import { Header } from '../../molecules/Header';
import { MouseToolConfig } from '../modals/Preferences/MouseBindings';
import { UserPreferences } from '../modals/Preferences/UserPreferences';
import SidePanelWithServices from '../panels/SidePanelWithServices';
import { InstanceCharacterisationButton } from '../toolbar/InstanceCharacterisationButton';
import { MetadataButton } from '../toolbar/MetadataButton';
import { PatientCharacterisationButton } from '../toolbar/PatientCharacterisationButton';
import Toolbar from '../toolbar/Toolbar';

function useActivateObsPanel({ panelService }: { panelService: PanelService }) {
  const lastUpdatedObsUid = useSelector(
    labelPass.selectors.getLastUpdatedObsUid
  );
  const previousObsUid = useRef(lastUpdatedObsUid);

  useEffect(() => {
    if (lastUpdatedObsUid && lastUpdatedObsUid !== previousObsUid.current) {
      panelService.activatePanel(
        '@gleamer/gdata-extension.panelModule.right-panel-annotation',
        true
      );
      previousObsUid.current = lastUpdatedObsUid;
    }
  }, [lastUpdatedObsUid, panelService]);
}

function CharacterisationToolbar({
  extensionManager,
  servicesManager,
}: {
  extensionManager: ExtensionManager;
  servicesManager: ServicesManager;
}) {
  return (
    <div className="flex items-center">
      <MetadataButton />
      <PatientCharacterisationButton servicesManager={servicesManager} />
      <InstanceCharacterisationButton
        extensionManager={extensionManager}
        servicesManager={servicesManager}
      />
    </div>
  );
}

const annotationTools = [
  GleamerRectangleTool.toolName,
  GleamerKeypointTool.toolName,
];

function setAndSaveToolConfigs({
  configs,
  ToolGroupService,
}: {
  configs: MouseToolConfig[];
  ToolGroupService: OHIF.ToolGroupService;
}) {
  const toolGroupIds = ToolGroupService.getToolGroupIds();

  toolGroupIds.forEach(toolGroupId => {
    const toolGroup = ToolGroupService.getToolGroup(toolGroupId);

    localStorage.setItem(getMouseBindingsConfigsKey(), JSON.stringify(configs));

    configs.forEach(config => {
      let toolName = config.toolName;
      if (config.toolName === 'Annotation') {
        toolName = Object.entries(toolGroup.toolOptions).find(
          ([name, conf]) => {
            return annotationTools.includes(name) && conf.mode === 'Active';
          }
        )?.[0];
      }

      if (!toolName) {
        return;
      }

      if (!ToolGroupService.getToolConfiguration(toolGroupId, toolName)) {
        return;
      }

      ToolGroupService.setToolConfiguration(toolGroupId, toolName, {
        bindings: config.bindings,
      });

      const mode =
        config.bindings.length === 0 ||
        config.bindings.every(binding => binding.mouseButton === undefined)
          ? 'Passive'
          : 'Active';

      if (mode === 'Passive') {
        toolGroup.setToolPassive(toolName);
      } else {
        toolGroup.setToolActive(toolName, {
          bindings: config.bindings,
        });
      }

      toolGroup.toolOptions[toolName] = {
        ...toolGroup.toolOptions[toolName],
        mode,
        bindings: config.bindings,
      };
    });
  });
}

export function GleamerViewerLayout({
  // From Extension Module Params
  extensionManager,
  servicesManager,
  hotkeysManager,
  commandsManager,
  // From Modes
  viewports,
  ViewportGridComp,
  leftPanelDefaultClosed = false,
  rightPanelDefaultClosed = false,
  headerChildren = null,
  headerRightContent = null,
}) {
  const [appConfig] = useAppConfig();
  const { logout } = useAuth0();
  const task = useTask();
  useAutoLogout({ timeout: task?.logout });

  const { t } = useTranslation();
  const { show, hide } = useModal();

  const [showLoadingIndicator, setShowLoadingIndicator] = useState(
    appConfig.showLoadingIndicator
  );

  const { hangingProtocolService, toolGroupService, panelService } = (
    servicesManager as ServicesManager
  ).services;

  const hasPanels = useCallback(
    (side): boolean => !!panelService.getPanels(side).length,
    [panelService]
  );

  const [hasRightPanels, setHasRightPanels] = useState(hasPanels('right'));
  const [hasLeftPanels, setHasLeftPanels] = useState(hasPanels('left'));

  useActivateObsPanel({
    panelService: panelService as unknown as PanelService,
  });

  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const versionNumber = process.env.VERSION_NUMBER;
  const commitHash = process.env.COMMIT_HASH;

  const menuOptions = [
    {
      title: t('Header:About'),
      icon: 'info',
      onClick: () =>
        show({
          content: AboutModal,
          title: 'About OHIF Viewer',
          contentProps: { versionNumber, commitHash },
        }),
    },
    {
      title: t('Header:Preferences'),
      icon: 'settings',
      onClick: () =>
        show({
          title: t('UserPreferencesModal:User Preferences'),
          content: UserPreferences,
          contentProps: {
            hotkeyDefaults:
              hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
            hotkeyDefinitions,
            onCancel: () => {
              hotkeys.stopRecord();
              hotkeys.unpause();
              hide();
            },
            onSubmit: ({ hotkeyDefinitions, configs }) => {
              hotkeysManager.setHotkeys(hotkeyDefinitions);
              setAndSaveToolConfigs({
                configs,
                ToolGroupService: toolGroupService,
              });
              hide();
            },
            onReset: () => {
              hotkeysManager.restoreDefaultBindings();
              setAndSaveToolConfigs({
                configs: getDefaultMouseBindings(),
                ToolGroupService: toolGroupService,
              });
            },
            hotkeysModule: hotkeys,
            configs: getMouseBindingsConfigs(),
          },
          customClassName: 'user-preferences',
        }),
    },
    {
      title: t('Header:Logout'),
      icon: 'power-off',
      onClick: () =>
        logout({ logoutParams: { returnTo: window.location.origin } }),
    },
  ];

  /**
   * Set body classes (tailwindcss) that don't allow vertical
   * or horizontal overflow (no scrolling). Also guarantee window
   * is sized to our viewport.
   */
  useEffect(() => {
    document.body.classList.add('bg-black');
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('bg-black');
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const getComponent = id => {
    const entry = extensionManager.getModuleEntry(id);

    if (!entry) {
      throw new Error(
        `${id} is not a valid entry for an extension module, please check your configuration or make sure the extension is registered.`
      );
    }

    let content;
    if (entry && entry.component) {
      content = entry.component;
    } else {
      throw new Error(
        `No component found from extension ${id}. Check the reference string to the extension in your Mode configuration`
      );
    }

    return { entry, content };
  };

  useEffect(() => {
    const { unsubscribe } = hangingProtocolService.subscribe(
      HangingProtocolService.EVENTS.PROTOCOL_CHANGED,

      // Todo: right now to set the loading indicator to false, we need to wait for the
      // hangingProtocolService to finish applying the viewport matching to each viewport,
      // however, this might not be the only approach to set the loading indicator to false. we need to explore this further.
      () => {
        setShowLoadingIndicator(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [hangingProtocolService]);

  useEffect(() => {
    const { unsubscribe } = panelService.subscribe(
      panelService.EVENTS.PANELS_CHANGED,
      () => {
        setHasLeftPanels(hasPanels('left'));
        setHasRightPanels(hasPanels('right'));
      }
    );

    return () => {
      unsubscribe();
    };
  }, [panelService, hasPanels]);

  const getViewportComponentData = viewportComponent => {
    const { entry } = getComponent(viewportComponent.namespace);

    return {
      component: entry.component,
      displaySetsToDisplay: viewportComponent.displaySetsToDisplay,
    };
  };

  const viewportComponents = viewports.map(getViewportComponentData);

  return (
    <div>
      <Header menuOptions={menuOptions} rightContent={headerRightContent}>
        <ErrorBoundary
          context="Primary Toolbar"
          onError={window.errorHandler?.report}
        >
          <div className="relative flex justify-center">
            <Toolbar servicesManager={servicesManager} />
          </div>
          <div className="relative flex justify-center">
            <CharacterisationToolbar
              extensionManager={extensionManager}
              servicesManager={servicesManager}
            />
          </div>
          {headerChildren}
        </ErrorBoundary>
      </Header>
      <div
        className="relative flex w-full flex-row flex-nowrap items-stretch overflow-hidden bg-black"
        style={{ height: 'calc(100vh - 52px' }}
      >
        <React.Fragment>
          {showLoadingIndicator && (
            <LoadingIndicatorProgress className="h-full w-full bg-black" />
          )}
          {/* LEFT SIDEPANELS */}
          {hasLeftPanels ? (
            <ErrorBoundary
              context="Left Panel"
              onError={window.errorHandler?.report}
            >
              <SidePanelWithServices
                side="left"
                activeTabIndex={leftPanelDefaultClosed ? null : 0}
                servicesManager={servicesManager}
              />
            </ErrorBoundary>
          ) : null}
          {/* TOOLBAR + GRID */}
          <div className="flex h-full flex-1 flex-col">
            <div className="relative flex h-full flex-1 items-center justify-center overflow-hidden bg-black">
              <ErrorBoundary
                context="Grid"
                onError={window.errorHandler?.report}
              >
                <ViewportGridComp
                  servicesManager={servicesManager}
                  viewportComponents={viewportComponents}
                  commandsManager={commandsManager}
                />
              </ErrorBoundary>
            </div>
          </div>
          {hasRightPanels ? (
            <ErrorBoundary
              context="Right Panel"
              onError={window.errorHandler?.report}
            >
              <SidePanelWithServices
                servicesManager={servicesManager}
                side="right"
                activeTabIndex={rightPanelDefaultClosed ? null : 0}
              />
            </ErrorBoundary>
          ) : null}
        </React.Fragment>
      </div>
    </div>
  );
}

GleamerViewerLayout.propTypes = {
  // From extension module params
  extensionManager: PropTypes.shape({
    getModuleEntry: PropTypes.func.isRequired,
  }).isRequired,
  commandsManager: PropTypes.instanceOf(CommandsManager),
  servicesManager: PropTypes.instanceOf(ServicesManager),
  hotkeysManager: PropTypes.instanceOf(HotkeysManager),
  // From modes
  leftPanels: PropTypes.array,
  rightPanels: PropTypes.array,
  leftPanelDefaultClosed: PropTypes.bool,
  rightPanelDefaultClosed: PropTypes.bool,
  viewports: PropTypes.array,
  headerChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  headerRightContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default GleamerViewerLayout;
