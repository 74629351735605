import { Typography } from '@ohif/ui';
import { milliseconds } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useInterval } from '../../../hooks/useInterval';

const POPUP_DELAY_BEFORE_LOGOUT = { seconds: 30 };

type ModalProps = {
  delay: Duration;
};

function Modal({ delay }: ModalProps) {
  const [timeout, setTimeout] = useState(milliseconds(delay) / 1000);

  useInterval(() => {
    setTimeout(timeout => timeout - 1);
  }, 1000);

  return (
    <>
      <header className="flex items-center p-4 border-b-2 border-black bg-secondary-dark rounded-tl rounded-tr">
        <Typography
          variant="h4"
          className="flex grow text-primary-light font-light"
        >
          Logout
        </Typography>
      </header>

      <section className="ohif-scrollbar modal-content overflow-y-auto px-4 py-6 rounded-bl rounded-br bg-primary-dark">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="rounded-lg shadow-lg p-6">
            <h2 className="text-xl text-center font-bold mb-4">
              Session Timeout Warning
            </h2>
            <p className="mb-4">
              You will be automatically logged out in{' '}
              <span className="font-bold">{timeout}</span> seconds.
            </p>
            <p className="mt-4 text-sm text-gray-600">
              Move your mouse to continue your session and close this modal.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

function useShouldDisplay() {
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useInterval(() => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    const expiredTime = parseInt(
      localStorage.getItem('_expiredTime') || '0',
      10
    );

    setShouldDisplay(
      expiredTime - milliseconds(POPUP_DELAY_BEFORE_LOGOUT) < Date.now()
    );
  }, 200);

  return shouldDisplay;
}

export function AutoLogoutModal() {
  const { t } = useTranslation();

  const shouldDisplay = useShouldDisplay();

  return (
    <ReactModal
      id="auto-logout-modal"
      isOpen={shouldDisplay}
      contentLabel={t('Header:Logout')}
      className="relative w-11/12 lg:w-10/12 xl:w-1/2 max-h-full outline-none text-white"
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 z-50 bg-overlay flex items-start justify-center py-16"
      style={{
        overlay: {
          zIndex: 9999,
        },
      }}
    >
      <Modal delay={POPUP_DELAY_BEFORE_LOGOUT} />
    </ReactModal>
  );
}
