import { labelTask, tools, useObservationSubmissions } from '@gleamer/ui';
import classNames from 'classnames';
import React, { Ref, forwardRef } from 'react';
import { useSelector } from 'react-redux';

type NextAnnotationTitleProps = {
  className?: string;
  style?: React.CSSProperties;
};

function ForwardedNextAnnotationTitle(
  { className, style }: NextAnnotationTitleProps,
  ref: Ref<HTMLDivElement>
) {
  const nextObs = useSelector(tools.selectors.getNextObs);
  const observations = useObservationSubmissions();
  const control = useSelector(tools.selectors.getCurrentControl);
  const tool = useSelector(tools.selectors.getTool);
  const observationSpecs = useSelector(
    labelTask.selectors.getObservationsSpecifications
  );

  if (!tool || !nextObs?.code) {
    return null;
  }

  let nextAnnotationIndex = 1;
  let color: string | undefined;
  const matchingObservation = observations.find(
    obs =>
      obs.code === nextObs.code &&
      obs.status === nextObs.status &&
      obs.index === nextObs.index
  );
  if (matchingObservation) {
    nextAnnotationIndex = matchingObservation.regions.length + 1;
    color = matchingObservation.color;
  } else {
    const observationSpec = observationSpecs.find(
      obsSpec =>
        obsSpec.code === nextObs.code && obsSpec.status === nextObs.status
    );
    if (observationSpec) {
      const { colors } = observationSpec;
      color = colors[nextObs.index % colors.length];
    }
  }

  return (
    <div
      ref={ref}
      className={classNames(
        'absolute text-sm text-white bg-overlay px-[1px]',
        className
      )}
      style={{
        color,
        ...style,
      }}
    >
      {nextObs.code} {nextObs.index}:{nextAnnotationIndex}
      {control && ` (${control.title})`}
    </div>
  );
}

export const NextAnnotationTitle = forwardRef(ForwardedNextAnnotationTitle);
