import { ServicesManager } from '@ohif/core';
import { Internal } from '@gleamer/types';
import { utils } from '@gleamer/ui';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type UseCurrentItemParams = {
  servicesManager: ServicesManager;
};

export function useCurrentItem({
  servicesManager,
}: UseCurrentItemParams): Internal.LabelTaskItem | null {
  const { dicomJSONService } = servicesManager.services;
  const [searchParams] = useSearchParams();

  const taskId = searchParams.get('task');
  const itemId = searchParams.get('item');

  const mode = utils.getMode();

  let currentKey;
  if (mode === 'preview') {
    const patient = searchParams.get('patientId');
    const study = searchParams.get('studyInstanceUID');
    const series = searchParams.get('seriesInstanceUID');
    const instance = searchParams.get('sopInstanceUID');

    currentKey = [patient, study, series, instance].filter(Boolean).join('-');
  } else {
    currentKey = `${taskId}-${itemId}`;
  }

  const [currentItem, setCurrentItem] = useState(
    dicomJSONService.getItem(currentKey)
  );

  useEffect(() => {
    if (taskId && itemId !== currentItem?.id) {
      setCurrentItem(null);
    }
  }, [currentKey, dicomJSONService, itemId, currentItem?.id, taskId]);

  useEffect(() => {
    const { unsubscribe } = dicomJSONService.subscribe(
      dicomJSONService.EVENTS.UPDATE_CURRENT_ITEM,
      ({ currentItem: item }) => {
        setCurrentItem(item);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [dicomJSONService]);

  return currentItem;
}
