import { Internal } from '@gleamer/types';
import { labelTask, tools, useObservationSubmissions } from '@gleamer/ui';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMatchingRegion } from '../../../utils/region.utils';

type ObservationsSpecifications = Internal.ObservationsSpecifications;

type UseNextObservationArgs = {
  modality?: string;
  sopClassUID?: string;
};

export function useNextObservation({
  modality,
  sopClassUID,
}: UseNextObservationArgs) {
  const dispatch = useDispatch();

  const observations = useObservationSubmissions();
  const observationsSpecifications = useSelector(
    labelTask.selectors.getObservationsSpecifications
  );

  const matchingObservationsSpecs = useMemo(() => {
    if (!observationsSpecifications?.length) {
      return [];
    }

    return observationsSpecifications.filter(
      obs =>
        obs.regions?.some(region =>
          isMatchingRegion(region, sopClassUID, modality)
        )
    );
  }, [modality, observationsSpecifications, sopClassUID]);

  const selectNextObs = useCallback(
    (observation: ObservationsSpecifications) => {
      const { code, status, regions } = observation;
      const maxIndex = Math.max(
        0,
        ...observations
          .filter(obs => obs.code === code && obs.status === status)
          .map(obs => obs.index)
      );

      const nextObs = {
        code,
        status,
        index: maxIndex + 1,
      };

      const { tool } =
        regions.find(region =>
          isMatchingRegion(region, sopClassUID, modality)
        ) || {};

      dispatch(tools.actions.setNextObs(nextObs));
      if (tool) {
        dispatch(tools.actions.setTool(tool));
      }
    },
    [dispatch, modality, observations, sopClassUID]
  );

  return {
    availableObservations: matchingObservationsSpecs,
    selectNextObs,
  };
}
