import { produce } from 'immer';
import { Api, Assets, Internal, Mapper } from '@gleamer/types';

type LabelPassApi = Api.LabelPassApi;
type ObservationsApi = Api.ObservationsApi;
type RegionApi = Api.RegionApi;
type PredictionApi = Api.PredictionApi;

type InstanceAssetRef = Assets.InstanceAssetRef;
type LabelPassSubmission = Internal.LabelPassSubmission;
type ObservationSubmission = Internal.ObservationInternal;
type Region = Internal.RegionInternal;

const { groupAndFilterCharacterisationsByLevel } = Assets;

function toRegionApi(observation: ObservationSubmission, patientId: string) {
  function fixMetadata(metadata: any) {
    const copy = { ...metadata };
    function fixPoint3d(point3d: number[] | { [key: number]: number }) {
      if (point3d) {
        return [point3d[0], point3d[1], point3d[2]];
      }
      return point3d;
    }

    const fixIfExist = (name: string): void => {
      const value = metadata[name];
      if (value) {
        copy[name] = fixPoint3d(value);
      }
    };
    fixIfExist('cameraPosition');
    fixIfExist('cameraFocalPoint');
    fixIfExist('viewPlaneNormal');
    fixIfExist('viewUp');
    return copy;
  }

  return function (measurement: Region): RegionApi {
    const {
      metadata,
      data,
      SOPInstanceUID,
      uid,
      referenceSeriesUID,
      referenceStudyUID,
      characterisation,
    } = measurement;

    const formattedData = produce(data, draftData => {
      for (let points of draftData.handles.points) {
        points = [...points];
      }
    });

    const instanceAssetRef: InstanceAssetRef = {
      kind: 'instance',
      patientId,
      sopInstanceUID: SOPInstanceUID,
      seriesInstanceUID: referenceSeriesUID,
      studyInstanceUID: referenceStudyUID,
    };

    const characterisationsByLevel = groupAndFilterCharacterisationsByLevel(
      instanceAssetRef,
      observation.characterisations
    );
    return {
      uid,
      annotation: {
        metadata: fixMetadata(metadata),
        data: formattedData,
      },
      patientId,
      studyInstanceUID: referenceStudyUID,
      seriesInstanceUID: referenceSeriesUID,
      sopInstanceUID: SOPInstanceUID,
      characterisations: {
        PATIENT: characterisationsByLevel.patient,
        STUDY: characterisationsByLevel.study,
        SERIES: characterisationsByLevel.series,
        INSTANCE: characterisationsByLevel.instance,
        REGION: characterisation,
      },
    };
  };
}

function toObservationsApi(
  observations: ObservationSubmission[],
  patientId: string
): ObservationsApi[] {
  return observations
    .filter(obs => obs.regions.length > 0)
    .map(obs => {
      return {
        uid: obs.uid,
        code: obs.code,
        status: obs.status,
        regions: obs.regions.map(toRegionApi(obs, patientId)),
      };
    });
}

export function formatLabelPass(
  labelPass: LabelPassSubmission,
  patientId: string
): Omit<LabelPassApi, 'item'> {
  const predictions: PredictionApi[] = labelPass.predictions
    ? labelPass.predictions.map(Mapper.PredictionMapper.toPredictionApi)
    : [];

  const initialPredictions: PredictionApi[] = labelPass.initialPredictions
    ? labelPass.initialPredictions.map(Mapper.PredictionMapper.toPredictionApi)
    : [];

  const formattedLabelPass: Omit<LabelPassApi, 'item'> = {
    initialPredictions,
    predictions,
    labeledBy: labelPass.labeledBy,
    observations: toObservationsApi(labelPass.observations, patientId),
    characterisations: labelPass.characterisations,
    sourceLabelPassesIds: labelPass.sourceLabelPassesIds,
  };

  if (labelPass.feedbacks) {
    formattedLabelPass.feedbacks = labelPass.feedbacks;
  }

  return formattedLabelPass;
}
