import { useAuth0 } from '@auth0/auth0-react';
import { tasksApis } from '@gleamer/apis';
import { useUserAuthentication } from '@ohif/ui';
import { useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import React, { useState } from 'react';
import { useUrlQuery } from '../../hooks/url-query';
import { PageTitle } from '../atoms/PageTitle';
import { PageWrapper } from '../atoms/PageWrapper';
import { ErrorAlert } from '../molecules/ErrorAlert';
import { FinishedTaskAlert } from '../molecules/FinishedTaskAlert';
import { TasksTable } from '../organisms/tasks/TasksTable';

const { fetchMyTasks } = tasksApis;

export function MyTasks() {
  const { getAccessTokenSilently } = useAuth0();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'createdAt',
      desc: true,
    },
  ]);

  // @ts-ignore: bad type definition
  const [_, UserAuthenticationService] = useUserAuthentication();

  function fetchTasks(currentPage = 0, sorting: SortingState) {
    return async () => {
      await getAccessTokenSilently();
      return await fetchMyTasks(
        UserAuthenticationService,
        currentPage,
        sorting
      );
    };
  }

  const labelTasksQuery = useQuery({
    queryKey: ['tasks', pageIndex, sorting],
    queryFn: fetchTasks(pageIndex, sorting),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  const query = useUrlQuery();
  const finishedTaskId = query.has('finished')
    ? parseInt(query.get('finished'), 10)
    : null;
  const finishedTask = finishedTaskId
    ? labelTasksQuery.data?.content?.find(
        task => task.taskId === finishedTaskId
      )
    : null;

  const hasError = query.has('error');

  return (
    <PageWrapper className="w-full">
      <PageTitle title="My tasks" />
      {finishedTask && <FinishedTaskAlert task={finishedTask} />}
      {hasError && <ErrorAlert />}
      <TasksTable
        tasksQuery={labelTasksQuery}
        onPaginationChange={setPagination}
        pagination={{ pageIndex, pageSize }}
        sorting={sorting}
        onSortingChange={setSorting}
      />
    </PageWrapper>
  );
}

export default MyTasks;
