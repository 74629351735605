import classNames from 'classnames';
import React, { Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNextObservation } from './useNextObservation';

type ObservationSelectorProps = {
  className?: string;
  style?: React.CSSProperties;
  sopClassUID: string | null;
  modality: string | null;
};

function ForwardedObservationSelector(
  { className, style, sopClassUID, modality }: ObservationSelectorProps,
  ref: Ref<HTMLDivElement>
) {
  const { t } = useTranslation();
  const { availableObservations, selectNextObs } = useNextObservation({
    sopClassUID,
    modality,
  });

  return (
    <div
      ref={ref}
      style={style}
      className={classNames(
        'absolute origin-center -translate-x-1/2 -translate-y-1/2 text-base text-white',
        className
      )}
    >
      {t('New observation:')}
      <ul>
        {availableObservations?.map(observation => {
          const { colors, label, code, status } = observation;
          const color = colors[0];

          return (
            <li
              key={`${code}-${status}`}
              className="m-2 rounded-sm p-2 text-center"
              style={{
                backgroundColor: color,
              }}
            >
              <button onClick={() => selectNextObs(observation)}>
                {label}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export const ObservationSelector = forwardRef(ForwardedObservationSelector);
