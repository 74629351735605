import { tasksApis } from '@gleamer/apis';
import { Internal } from '@gleamer/types';
import { useTask } from '@gleamer/ui';
import { DicomMetadataStore, ServicesManager } from '@ohif/core';
import { useImageViewer } from '@ohif/ui';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { ReportsMessage } from '../../components/organisms/panels/report/constants';
import { useCurrentDisplaySet } from '../../hooks/useCurrentDisplaySet';
import { useCurrentItem } from '../../hooks/useCurrentItem';
import { useReportsBroadcastChannel } from '../../hooks/useReportsBroadcastChannel';
import { tryParseDate } from '../../utils/utils';

const { fetchReport } = tasksApis;

type DicomJSONReport = Internal.DicomJSONReport;
type DicomJSONStudy = Internal.DicomJSONStudy;
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;

export type ReportsByStudyInstanceUID = Record<
  string,
  EnhancedDicomJSONReport[]
>;

type ReportsContextValue = {
  reports: ReportsByStudyInstanceUID;
  patientReports: EnhancedDicomJSONReport[];
  studyInstanceUIDs: string[];
  patientId?: string;
  fetchReport: (report: DicomJSONReport) => Promise<Blob>;
  activeStudyInstanceUID: string;
};

export const ReportsContext = createContext<ReportsContextValue>({
  reports: {},
  patientReports: [],
  studyInstanceUIDs: [],
  patientId: undefined,
  fetchReport: () => Promise.reject(),
  activeStudyInstanceUID: '',
});

export function useReports() {
  return useContext(ReportsContext);
}

type ReportsContextProviderProps = {
  servicesManager: ServicesManager;
};

function findDateFromStudies(
  report: DicomJSONReport,
  allStudies: DicomJSONStudy[]
): Date | undefined {
  const correspondingStudy = allStudies.find(
    study => study.StudyInstanceUID === report.StudyInstanceUID
  );
  if (correspondingStudy === undefined) {
    return undefined;
  }
  return tryParseDate(correspondingStudy.StudyDate);
}

export function ReportsContextProvider({
  servicesManager,
  children,
}: PropsWithChildren<ReportsContextProviderProps>) {
  const { userAuthenticationService, displaySetService } =
    servicesManager.services;
  const bc = useReportsBroadcastChannel();

  const displaySet = useCurrentDisplaySet(displaySetService);
  const activeStudyInstanceUID: string | undefined =
    displaySet?.studyInstanceUid;

  const task = useTask();

  const sendTask = useCallback(() => {
    const message: ReportsMessage = {
      type: 'reports::task',
      payload: {
        task,
      },
    };
    bc.postMessage(message);
  }, [bc, task]);

  useEffect(() => {
    sendTask();
  }, [sendTask]);

  const sendActiveStudyInstanceUID = useCallback(() => {
    const message: ReportsMessage = {
      type: 'reports::active_study',
      payload: {
        activeStudyInstanceUID,
      },
    };

    bc.postMessage(message);
  }, [bc, activeStudyInstanceUID]);

  useEffect(() => {
    sendActiveStudyInstanceUID();
  }, [sendActiveStudyInstanceUID]);

  // @ts-ignore
  const { StudyInstanceUIDs: studyInstanceUIDs } = useImageViewer();
  const { studies: allStudies = [], reports: allReports = [] } =
    useCurrentItem({ servicesManager }) || {};

  const patientId = allReports.find(report => report.PatientId)?.PatientId;
  const reports = allReports
    .filter(report => !!report.StudyInstanceUID)
    .map(report => {
      const studyMetadata = DicomMetadataStore.getStudy(
        report.StudyInstanceUID
      );
      const modalities = [...(studyMetadata?.ModalitiesInStudy || [])]
        .sort()
        .join(', ');

      return {
        ...report,
        date: findDateFromStudies(report, allStudies),
        modalities,
      };
    })
    .reduce((acc, report) => {
      const { StudyInstanceUID } = report;
      if (acc[StudyInstanceUID]) {
        acc[StudyInstanceUID].push(report);
      } else {
        acc[StudyInstanceUID] = [report];
      }
      return acc;
    }, {});

  const patientReports = allReports
    .filter(report => !report.StudyInstanceUID)
    .map(report => ({
      ...report,
      date: tryParseDate(report.name),
    }));

  const fetch = (report: DicomJSONReport) =>
    fetchReport(userAuthenticationService, report);

  const sendReports = useCallback(() => {
    const message: ReportsMessage = {
      type: 'reports::data',
      payload: {
        reports,
        patientReports,
      },
    };

    bc.postMessage(message);
  }, [reports, patientReports, bc]);

  const sendAuthenticationHeader = useCallback(() => {
    const message: ReportsMessage = {
      type: 'reports::authentication',
      payload: {
        header: userAuthenticationService.getAuthorizationHeader(),
      },
    };

    bc.postMessage(message);
  }, [userAuthenticationService, bc]);

  useEffect(() => {
    sendReports();
  }, [sendReports]);

  useEffect(() => {
    function newWindowHandler({ data }: MessageEvent<ReportsMessage>) {
      if (data.type === 'reports::window_created') {
        sendReports();
        sendActiveStudyInstanceUID();
        sendAuthenticationHeader();
        sendTask();
      }
    }

    bc.addEventListener('message', newWindowHandler);

    return () => {
      bc.removeEventListener('message', newWindowHandler);
    };
  }, [
    bc,
    sendReports,
    sendActiveStudyInstanceUID,
    sendAuthenticationHeader,
    sendTask,
  ]);

  return (
    <ReportsContext.Provider
      value={{
        reports,
        studyInstanceUIDs,
        patientReports,
        patientId,
        activeStudyInstanceUID,
        fetchReport: fetch,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
}
