import { OHIF } from '@gleamer/types';
import React from 'react';
import { Internal } from '@gleamer/types';
import RegionItem, { RegionItemActions } from './RegionItem';
type ServicesManager = OHIF.ServicesManager;
type ObservationSubmission = Internal.ObservationInternal;

// todo change translation file

type RegionListProps = {
  observation: ObservationSubmission;
  onClick?: RegionItemActions['onClick'];
  onDelete: RegionItemActions['onDelete'];
  onFocusRegion: RegionItemActions['onFocusRegion'];
  onRegionHover: RegionItemActions['onHover'];
  onRegionMouseLeave: RegionItemActions['onMouseLeave'];
  open?: boolean;
  servicesManager: ServicesManager;
};

export function RegionList({
  observation,
  onClick = () => {},
  onDelete = () => {},
  onFocusRegion = () => {},
  onRegionHover = () => {},
  onRegionMouseLeave = () => {},
  servicesManager,
  open = false,
}: RegionListProps) {
  const { regions } = observation;
  if (regions.length === 0) {
    return (
      <div className="group ml-2 flex cursor-default border border-transparent bg-black">
        <div className="flex flex-1 items-center justify-between p-2 text-base text-primary-light">
          No region
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="max-h-112 ml-2 overflow-hidden">
        {regions.map((measurement, index) => (
          <RegionItem
            key={measurement.uid}
            region={measurement}
            index={index + 1}
            onClick={onClick}
            onFocusRegion={onFocusRegion}
            onHover={onRegionHover}
            onMouseLeave={onRegionMouseLeave}
            onDelete={onDelete}
            observation={observation}
            open={open}
            servicesManager={servicesManager}
          />
        ))}
      </div>
    </div>
  );
}
