import { Assets, Internal } from '@gleamer/types';
import { DicomMetadataStore, utils } from '@ohif/core';
import { RJSFSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import { getRegionSpecification } from './region.utils';

const {
  isStudyAssetRef,
  isSeriesAssetRef,
  isInstanceAssetRef,
  isPatientAssetRef,
  isStudyReportAssetRef,
} = Assets;

type AssetKind = Assets.AssetKind;
type AssetRef = Assets.AssetRef;

type AssetCharacterisationSubmission = Internal.AssetCharacterisationInternal;
type Region = Internal.RegionInternal;
type LabelTask = Internal.LabelTask;
type ObservationSubmission = Internal.ObservationInternal;
type RegionSpecification = Internal.RegionSpecification;

export const defaultFormValidator = customizeValidator({
  ajvOptionsOverrides: { strict: false },
});

const ajv = new Ajv({ allErrors: true, jsonPointers: true, strict: false });
ajvErrors(ajv);

const { formatDate } = utils;

function getAssetMetadata(asset: AssetRef) {
  if (isStudyAssetRef(asset) || isStudyReportAssetRef(asset)) {
    const metadata = DicomMetadataStore.getStudy(asset.studyInstanceUID);
    const assetName = asset.kind === 'study' ? 'Study' : 'Report';

    if (!metadata) {
      return `A ${assetName}`;
    }

    return `${assetName} ${formatDate(metadata.StudyDate)}`;
  }

  if (isSeriesAssetRef(asset)) {
    const metadata = DicomMetadataStore.getSeries(
      asset.studyInstanceUID,
      asset.seriesInstanceUID
    );

    if (!metadata) {
      return 'A series';
    }

    return `Series number ${metadata.SeriesNumber} of study ${formatDate(
      metadata.StudyDate
    )}`;
  }

  if (isInstanceAssetRef(asset)) {
    const metadata = DicomMetadataStore.getInstance(
      asset.studyInstanceUID,
      asset.seriesInstanceUID,
      asset.sopInstanceUID
    );

    if (!metadata) {
      return 'An instance';
    }

    return `Instance number ${metadata.InstanceNumber} from series number ${
      metadata.SeriesNumber
    } of study ${formatDate(metadata.StudyDate)}`;
  }

  if (isPatientAssetRef(asset)) {
    return 'Patient';
  }

  return {};
}

export function validateRegionCharacterisation(
  regionsSpecs: RegionSpecification[],
  regions: Region[] = []
) {
  const errors = [];
  let requiredElements = 0;
  regions.forEach((region, index) => {
    const regionSpec = getRegionSpecification(region, regionsSpecs);
    if (!regionSpec) {
      return;
    }

    const schema = regionSpec.characterisation?.json;
    if (!schema) {
      return;
    }

    if (schema.required) {
      requiredElements += 1;
    }

    const characterisations = {
      metadata: `Region ${index + 1}`,
      ...region.characterisation,
    };

    const assetSchema = {
      type: 'object',
      ...schema,
      errorMessage: {
        type: `Some region characterisations were not filled`,
        required: '${0/metadata} is missing required properties',
      },
    };

    const validate = ajv.compile(assetSchema);
    validate(characterisations);
    errors.push(validate.errors);
  });

  const characterisations = regions.map((region, index) => ({
    metadata: `Region ${index + 1}`,
    ...region.characterisation,
  }));

  const assetArraySchema = {
    type: 'array',
    items: {
      type: 'object',
    },
    minItems: requiredElements,
    errorMessage: {
      minItems: `Some region characterisations were not filled`,
    },
  };

  const validate = ajv.compile(assetArraySchema);
  validate(characterisations);
  const result = [validate.errors, ...errors].filter(Boolean);
  if (result.length === 0) {
    return null;
  }
  return result;
}

function validateCharacterisations(
  schema: RJSFSchema,
  characterisations: AssetCharacterisationSubmission[] = [],
  requiredLength = 1,
  assetKind: AssetKind = 'patient'
) {
  if (!schema) {
    return null;
  }

  const assetsCharacterisations = characterisations
    .filter(charac => charac.asset.kind === assetKind)
    .map(charac => {
      const metadata = getAssetMetadata(charac.asset);
      return { metadata, ...charac.characterisation };
    });

  const assetArraySchema = {
    type: 'array',
    items: {
      type: 'object',
      ...schema,
      errorMessage: {
        required: '${0/metadata} is missing required properties',
      },
    },
    minItems: schema.required ? requiredLength : 0,
    errorMessage: {
      minItems: `Some ${assetKind} characterisations were not filled, \${/length} / ${requiredLength} were filled`,
    },
  };

  const validate = ajv.compile(assetArraySchema);
  validate(assetsCharacterisations);
  return validate.errors;
}

export function validateAssetCharacterisations(
  task: Partial<LabelTask>,
  characterisations: AssetCharacterisationSubmission[],
  requiredLength = 1,
  assetKind: AssetKind = 'patient'
) {
  return validateCharacterisations(
    task?.characterisations?.[assetKind]?.json,
    characterisations,
    requiredLength,
    assetKind
  );
}

export function validateObservation(
  task: Partial<LabelTask>,
  observation: Partial<ObservationSubmission>
) {
  if (observation.regions.length === 0) {
    return null;
  }

  if (!task) {
    return null;
  }

  const studyInstanceUIDs = [
    ...new Set(observation.regions.map(reg => reg.referenceStudyUID)),
  ];
  const seriesInstanceUIDs = [
    ...new Set(observation.regions.map(reg => reg.referenceSeriesUID)),
  ];
  const sopInstanceUIDs = [
    ...new Set(observation.regions.map(reg => reg.SOPInstanceUID)),
  ];

  const spec = task.observations.find(
    obsSpec =>
      obsSpec.code === observation.code && obsSpec.status === observation.status
  );

  if (!spec) {
    return null;
  }

  const hasNoRegionsCharac = spec.regions.every(
    regionSpec => !regionSpec.characterisation
  );
  const hasNoOtherCharac =
    !spec.characterisations ||
    Object.values(spec.characterisations).every(characSpec => !characSpec);

  if (hasNoRegionsCharac && hasNoOtherCharac) {
    return null;
  }

  const characterisationSpecs = spec?.characterisations;

  const patientErrors =
    validateCharacterisations(
      characterisationSpecs?.patient?.json,
      observation.characterisations,
      1,
      'patient'
    ) || [];

  const studyErrors =
    validateCharacterisations(
      characterisationSpecs?.study?.json,
      observation.characterisations,
      studyInstanceUIDs.length,
      'study'
    ) || [];

  const seriesErrors =
    validateCharacterisations(
      characterisationSpecs?.series?.json,
      observation.characterisations,
      seriesInstanceUIDs.length,
      'series'
    ) || [];

  const instanceErrors =
    validateCharacterisations(
      characterisationSpecs?.instance?.json,
      observation.characterisations,
      sopInstanceUIDs.length,
      'instance'
    ) || [];

  const regionErrors =
    validateRegionCharacterisation(spec.regions, observation.regions) || [];

  const result = {
    patient: patientErrors,
    study: studyErrors,
    series: seriesErrors,
    instance: instanceErrors,
    region: regionErrors,
  };

  return result;
}

export function isValidObservation(
  task: Partial<LabelTask>,
  observation: Partial<ObservationSubmission>
): boolean {
  const errors = validateObservation(task, observation);
  return !errors || Object.values(errors).every(err => err.length === 0);
}

export function isValidStudy(
  task: Partial<LabelTask>,
  characterisations: AssetCharacterisationSubmission[],
  studyInstanceUID: string
): boolean {
  const studyCharacterisations = characterisations.filter(
    charac =>
      isStudyAssetRef(charac.asset) &&
      charac.asset.studyInstanceUID === studyInstanceUID
  );

  const errors = validateAssetCharacterisations(
    task,
    studyCharacterisations,
    1,
    'study'
  );

  return !errors || errors.length === 0;
}

export function isValidSeries(
  task: Partial<LabelTask>,
  characterisations: AssetCharacterisationSubmission[],
  studyInstanceUID: string,
  seriesInstanceUID: string
): boolean {
  const seriesCharacterisations = characterisations.filter(
    charac =>
      isSeriesAssetRef(charac.asset) &&
      charac.asset.studyInstanceUID === studyInstanceUID &&
      charac.asset.seriesInstanceUID === seriesInstanceUID
  );

  const errors = validateAssetCharacterisations(
    task,
    seriesCharacterisations,
    1,
    'series'
  );

  return !errors || errors.length === 0;
}

export function isValidPatient(
  task: Partial<LabelTask>,
  characterisations: AssetCharacterisationSubmission[]
): boolean {
  const patientCharacterisations = characterisations.filter(charac =>
    isPatientAssetRef(charac.asset)
  );

  const errors = validateAssetCharacterisations(task, patientCharacterisations);
  return !errors || errors.length === 0;
}

export function isValidInstance(
  task: Partial<LabelTask>,
  characterisations: AssetCharacterisationSubmission[],
  studyInstanceUID: string,
  seriesInstanceUID: string,
  sopInstanceUID: string
): boolean {
  const instanceCharacterisations = characterisations.filter(
    charac =>
      isInstanceAssetRef(charac.asset) &&
      charac.asset.studyInstanceUID === studyInstanceUID &&
      charac.asset.seriesInstanceUID === seriesInstanceUID &&
      charac.asset.sopInstanceUID === sopInstanceUID
  );

  const errors = validateAssetCharacterisations(
    task,
    instanceCharacterisations,
    1,
    'instance'
  );
  return !errors || errors.length === 0;
}
