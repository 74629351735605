// External
import { GdataAuthProvider } from '@gleamer/auth0-extension';
import { AutoLogoutModal, GdataRoutes } from '@gleamer/gdata-extension';
import { QueryContextProvider } from '@gleamer/query-context-extension';
import {
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  ServicesManager,
} from '@ohif/core';
import i18n from '@ohif/i18n';
import {
  CineProvider,
  DialogProvider,
  Modal,
  ModalProvider,
  SnackbarProvider,
  ThemeWrapper,
  UserAuthenticationProvider,
  ViewportDialogProvider,
  ViewportGridProvider,
} from '@ohif/ui';
import { AppConfigProvider } from '@state';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import appInit from './appInit.js';
import createRoutes from './routes';
import Compose from './routes/Mode/Compose';

let commandsManager: CommandsManager,
  extensionManager: ExtensionManager,
  servicesManager: ServicesManager,
  hotkeysManager: HotkeysManager;

function Gdata({ config, defaultExtensions, defaultModes }) {
  const [init, setInit] = useState(null);
  useEffect(() => {
    const run = async () => {
      appInit(config, defaultExtensions, defaultModes)
        .then(setInit)
        .catch(console.error);
    };

    run();
  }, []);

  if (!init) {
    return null;
  }

  // Set above for named export
  commandsManager = init.commandsManager;
  extensionManager = init.extensionManager;
  servicesManager = init.servicesManager;
  hotkeysManager = init.hotkeysManager;

  // Set appConfig
  const appConfigState = init.appConfig;
  const { routerBasename, modes, dataSources, oidc, showStudyList } =
    appConfigState;

  const {
    uiDialogService,
    uiModalService,
    uiNotificationService,
    uiViewportDialogService,
    viewportGridService,
    cineService,
    userAuthenticationService,
    customizationService,
  } = servicesManager.services;

  const providers = [
    [AppConfigProvider, { value: appConfigState }],
    [UserAuthenticationProvider, { service: userAuthenticationService }],
    [
      GdataAuthProvider,
      { UserAuthenticationService: userAuthenticationService },
    ],
    [QueryContextProvider],
    [I18nextProvider, { i18n }],
    [ThemeWrapper],
    [ViewportGridProvider, { service: viewportGridService }],
    [ViewportDialogProvider, { service: uiViewportDialogService }],
    [CineProvider, { service: cineService }],
    [SnackbarProvider, { service: uiNotificationService }],
    [DialogProvider, { service: uiDialogService }],
    [ModalProvider, { service: uiModalService, modal: Modal }],
  ];
  const CombinedProviders = ({ children }) =>
    Compose({ components: providers, children });

  const authRoutes = null;

  // Should there be a generic call to init on the extension manager?
  customizationService.init(extensionManager);

  // Use config to create routes
  const appRoutes = createRoutes({
    modes,
    dataSources,
    extensionManager,
    servicesManager,
    commandsManager,
    hotkeysManager,
    routerBasename,
    showStudyList,
  });

  return (
    <BrowserRouter basename={routerBasename}>
      <CombinedProviders>
        <AutoLogoutModal />
        <Routes>
          <Route
            path="/*"
            element={<GdataRoutes servicesManager={servicesManager} />}
          />
          <Route path="ohif/*" element={appRoutes} />
        </Routes>
      </CombinedProviders>
    </BrowserRouter>
  );
}

Gdata.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
      oidc: PropTypes.array,
      whiteLabeling: PropTypes.object,
      extensions: PropTypes.array,
    }),
  ]).isRequired,
  /* Extensions that are "bundled" or "baked-in" to the application.
   * These would be provided at build time as part of they entry point. */
  defaultExtensions: PropTypes.array,
};

Gdata.defaultProps = {
  config: {
    /**
     * Relative route from domain root that OHIF instance is installed at.
     * For example:
     *
     * Hosted at: https://ohif.org/where-i-host-the/viewer/
     * Value: `/where-i-host-the/viewer/`
     * */
    routerBaseName: '/',
    /**
     *
     */
    showLoadingIndicator: true,
    showStudyList: true,
    oidc: [],
    extensions: [],
  },
  defaultExtensions: [],
};

export default Gdata;

export { commandsManager, extensionManager, servicesManager };
