import { useSelector } from 'react-redux';
import { getTask, isTaskDoneLoading } from './labelTask.selectors';

export function useTask() {
  return useSelector(getTask);
}

export function useTaskDoneLoading() {
  return useSelector(isTaskDoneLoading);
}
