import { OHIF } from '@gleamer/types';
import { useViewportGrid } from '@ohif/ui';
type DisplaySet = OHIF.DisplaySet;

type DisplaySetService = OHIF.DisplaySetService;

export function useCurrentDisplaySet(
  DisplaySetService: DisplaySetService,
  viewportId?: string
): DisplaySet {
  // @ts-ignore: wrong typing in OHIF
  const [{ activeViewportId, viewports }] = useViewportGrid();

  const computedViewportId = viewportId ?? activeViewportId;
  let displaySet: DisplaySet;

  const activeViewport = viewports.get(computedViewportId);
  if (activeViewport?.displaySetInstanceUIDs?.length > 0) {
    displaySet = DisplaySetService.getDisplaySetByUID(
      activeViewport.displaySetInstanceUIDs[0]
    );
  }

  return displaySet;
}
