import { Internal } from '@gleamer/types';
import { milliseconds } from 'date-fns';

class IdleTimer {
  private static readonly EVENTS: Internal.DocumentEventType[] = [
    'focus',
    'keydown',
    'keyup',
    'mousemove',
    'mousedown',
    'mouseup',
    'wheel',
    'scroll',
  ];
  timeout: Duration;
  onTimeout: () => void;
  eventHandler: () => void;
  interval: ReturnType<typeof setInterval>;
  timeoutTracker: ReturnType<typeof setTimeout>;

  constructor({
    timeout,
    onTimeout,
    onExpired,
  }: {
    timeout: Duration;
    onTimeout: () => void;
    onExpired: () => void;
  }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    const expiredTime = parseInt(
      localStorage.getItem('_expiredTime') || '0',
      10
    );
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem('_expiredTime') || '0',
        10
      );
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(
        '_expiredTime',
        `${Date.now() + milliseconds(this.timeout)}`
      );
    }, 300);
  }

  tracker() {
    IdleTimer.EVENTS.forEach(event => {
      window.addEventListener(event, this.eventHandler);
    });
  }

  cleanUp() {
    clearInterval(this.interval);
    IdleTimer.EVENTS.forEach(event => {
      window.removeEventListener(event, this.eventHandler);
    });
  }
}
export default IdleTimer;
