import { Enums } from '@cornerstonejs/core';
import { ServicesManager } from '@ohif/core';
import { useViewportGrid } from '@ohif/ui';
import { useEffect, useState } from 'react';
import { useCurrentDisplaySet } from './useCurrentDisplaySet';

export function useCurrentImageIndex(servicesManager: ServicesManager) {
  const { cornerstoneViewportService, displaySetService } =
    servicesManager.services;

  // @ts-ignore: wrong typing in OHIF
  const [{ activeViewportId }] = useViewportGrid();

  const displaySet = useCurrentDisplaySet(displaySetService, activeViewportId);

  const viewport =
    cornerstoneViewportService.getCornerstoneViewport(activeViewportId);

  const [currentIndex, setCurrentIndex] = useState(
    viewport?.getCurrentImageIdIndex() ?? 0
  );

  useEffect(() => {
    const { unsubscribe } = cornerstoneViewportService.subscribe(
      cornerstoneViewportService.EVENTS.VIEWPORT_DATA_CHANGED,
      ({ viewportData, viewportId }) => {
        if (
          viewportId === activeViewportId &&
          currentIndex >= viewportData?.data.imageIds?.length &&
          currentIndex !== 0
        ) {
          setCurrentIndex(0);
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [cornerstoneViewportService, activeViewportId, currentIndex]);

  useEffect(() => {
    const viewport =
      cornerstoneViewportService.getCornerstoneViewport(activeViewportId);

    if (!viewport) {
      return;
    }

    if (currentIndex >= displaySet?.images.length && currentIndex !== 0) {
      setCurrentIndex(0);
      return;
    }

    const currentImageIdIndex = viewport.getCurrentImageIdIndex();
    if (currentImageIdIndex !== currentIndex) {
      setCurrentIndex(currentImageIdIndex);
    }

    const updateStackIndex = event => {
      const { newImageIdIndex } = event.detail;
      setCurrentIndex(newImageIdIndex);
    };

    viewport.element.addEventListener(
      Enums.Events.STACK_VIEWPORT_SCROLL,
      updateStackIndex
    );

    return () => {
      viewport.element.removeEventListener(
        Enums.Events.STACK_VIEWPORT_SCROLL,
        updateStackIndex
      );
    };
  }, [cornerstoneViewportService, activeViewportId, currentIndex, displaySet]);

  return currentIndex;
}
