export interface StartStop {
  start(): void;

  stop(): void;
}
export function combine(...startStop: StartStop[]): StartStop {
  return {
    start() {
      startStop.forEach(l => l.start());
    },
    stop() {
      startStop.forEach(l => l.stop());
    },
  };
}
