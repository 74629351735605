import { Assets } from '@gleamer/types';
import {
  isReadonly,
  useAssetCharacterisations,
  useTask,
  useUpdateAssetCharacterisations,
} from '@gleamer/ui';
import { ServicesManager } from '@ohif/core';
import Form, { FormProps } from '@rjsf/core';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useCurrentItem } from '../../../../hooks/useCurrentItem';
import { defaultFormValidator } from '../../../../utils/validation.utils';

type SeriesAssetRef = Assets.SeriesAssetRef;

type SeriesCharacterisationProps = {
  StudyInstanceUID: string;
  SeriesInstanceUID: string;
  servicesManager: ServicesManager;
};

export function SeriesCharacterisation({
  StudyInstanceUID,
  SeriesInstanceUID,
  servicesManager,
}: SeriesCharacterisationProps) {
  const readOnly = isReadonly();
  const task = useTask();
  const current = useCurrentItem({ servicesManager });
  const characterisations = useAssetCharacterisations();
  const updateCharacterisations = useUpdateAssetCharacterisations();

  if (!task?.characterisations?.series?.json) {
    return null;
  }

  const asset: SeriesAssetRef = {
    kind: 'series',
    patientId: current.studies[0].PatientID,
    studyInstanceUID: StudyInstanceUID,
    seriesInstanceUID: SeriesInstanceUID,
  };

  const existingCharacterisation = characterisations.find(charac =>
    isEqual(charac.asset, asset)
  );

  if (!existingCharacterisation) {
    return null;
  }

  const handleChange: FormProps<any>['onChange'] = event => {
    if (event.errors.length > 0) {
      return;
    }

    updateCharacterisations(asset, event.formData);
  };

  return (
    <div className="characterisation-panel">
      <div className="my-2 ml-2 text-white">
        <Form
          readonly={readOnly}
          schema={task.characterisations.series.json}
          onChange={handleChange}
          formData={existingCharacterisation.characterisation}
          uiSchema={{
            ...task.characterisations.series.ui,
            'ui:submitButtonOptions': {
              norender: true,
            },
          }}
          validator={defaultFormValidator}
        />
      </div>
      <hr className="my-2 border-gray-700" />
    </div>
  );
}
