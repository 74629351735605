import React, { PropsWithChildren } from 'react';

type WithStatusProps = {
  loading: boolean;
  error: boolean;
  className?: string;
};

export function WithStatus({
  loading,
  error,
  children,
  className,
}: PropsWithChildren<WithStatusProps>) {
  return (
    <div className={className}>
      {error && <div>An error occurred while retrieving report</div>}
      {loading && <div>Loading report</div>}
      {children}
    </div>
  );
}
