import { StartStop } from './StartStop';
import { Internal } from '@gleamer/types';

type DocumentEventType = Internal.DocumentEventType;

type Listener<K extends DocumentEventType> = (
  this: Document,
  ev: DocumentEventMap[K]
) => void;
export class DocumentEventsListener implements StartStop {
  private readonly events: DocumentEventType[];
  private readonly listener: Listener<DocumentEventType> =
    this.onDocumentEvent.bind(null, this);
  private readonly onEvent: (ev: Event) => void;

  constructor(onEvent: (ev: Event) => void, ...events: DocumentEventType[]) {
    this.onEvent = onEvent;
    this.events = events;
  }

  public start(): void {
    this.events.forEach(event => {
      document.addEventListener(event, this.listener);
    });
  }

  public stop(): void {
    this.events.forEach(event => {
      document.removeEventListener(event, this.listener);
    });
  }

  private onDocumentEvent(service: DocumentEventsListener, ev: Event): void {
    service.onEvent(ev);
  }
}
