import { Internal } from '@gleamer/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReportsByStudyInstanceUID } from '../../../../../contexts/ReportsContext/ReportsContext';
import { ReportDisplay } from '../ReportDisplay';
import { byReportKey, getReportKey } from '../utils';
import { ReportEntry } from './ReportEntry';

type DicomJSONReport = Internal.DicomJSONReport;
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;

type SecondaryWindowStudyReportWrapperProps = {
  reports: ReportsByStudyInstanceUID;
  patientReports: EnhancedDicomJSONReport[];
  activeStudyInstanceUID: string;
  authenticationHeader: HeadersInit;
  task?: Internal.LabelTask;
};

export function SecondaryWindowReportWrapper({
  reports,
  patientReports,
  activeStudyInstanceUID,
  authenticationHeader,
  task,
}: SecondaryWindowStudyReportWrapperProps) {
  const [currentReport, setCurrentReport] = useState(
    reports[activeStudyInstanceUID]?.[0] || null
  );

  const prevActive = useRef(activeStudyInstanceUID);
  useEffect(() => {
    if (prevActive.current !== activeStudyInstanceUID) {
      setCurrentReport(reports[activeStudyInstanceUID]?.[0]);
      prevActive.current = activeStudyInstanceUID;
    }
  }, [activeStudyInstanceUID, reports]);

  useEffect(() => {
    if (!currentReport && reports[activeStudyInstanceUID]) {
      setCurrentReport(reports[activeStudyInstanceUID]?.[0]);
    }
  }, [activeStudyInstanceUID, currentReport, reports]);

  const reportsEntries = [
    ...Object.values(reports).flatMap(r => r),
    ...patientReports,
  ]
    .sort(byReportKey)
    .map(report => {
      const key = getReportKey(report);
      return (
        <ReportEntry
          key={key}
          report={report}
          activeStudyInstanceUID={activeStudyInstanceUID}
          onOpen={setCurrentReport}
          isDisplayed={currentReport && getReportKey(currentReport) === key}
        />
      );
    });

  const fetchReport = useCallback(
    async ({ url }: Pick<DicomJSONReport, 'url'>): Promise<Blob> => {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers(authenticationHeader),
      });
      if (response.status >= 400) {
        throw new Error(`${response.status} : ${response.statusText}`);
      }
      return response.blob();
    },
    [authenticationHeader]
  );

  return (
    <div className="py-4 grid grid-cols-4 gap-4 lg:grid-cols-6 h-screen w-screen bg-primary-dark text-white">
      <div className="flex flex-col flex-auto overflow-hidden">
        {reportsEntries.length > 0 ? (
          <ul className="ml-2 pb-2 overflow-y-auto ohif-scrollbar">
            {reportsEntries}
          </ul>
        ) : (
          <>Loading reports</>
        )}
      </div>
      <div className="col-span-3 lg:col-span-5 overflow-y-auto ohif-scrollbar my-2">
        {currentReport && (
          <ReportDisplay
            report={currentReport}
            fetchReport={fetchReport}
            className="h-full"
            embedded
            task={task}
          />
        )}
      </div>
    </div>
  );
}
