// This is a fork from `defaultRouteInit` that sort studies by date when there are downloaded.

import { DicomMetadataStore } from '@ohif/core';
import { parse } from 'date-fns';
import { sortBy } from 'lodash';

// This allows us to play the hanging protocol matcher on the study that will be first displayed by default
export function gdataRouteInit(
  { servicesManager, studyInstanceUIDs, dataSource, filters },
  hangingProtocols
) {
  const { displaySetService, hangingProtocolService } =
    servicesManager.services;

  const unsubscriptions = [];
  const { unsubscribe: instanceAddedUnsubscribe } =
    DicomMetadataStore.subscribe(
      DicomMetadataStore.EVENTS.INSTANCES_ADDED,
      function ({ StudyInstanceUID, SeriesInstanceUID, madeInClient = false }) {
        const seriesMetadata = DicomMetadataStore.getSeries(
          StudyInstanceUID,
          SeriesInstanceUID
        );

        displaySetService.makeDisplaySets(
          seriesMetadata.instances,
          madeInClient
        );
      }
    );

  unsubscriptions.push(instanceAddedUnsubscribe);

  const allRetrieves = studyInstanceUIDs.map(StudyInstanceUID =>
    dataSource.retrieve.series.metadata({
      StudyInstanceUID,
      filters,
    })
  );

  // The hanging protocol matching service is fairly expensive to run multiple
  // times, and doesn't allow partial matches to be made (it will simply fail
  // to display anything if a required match fails), so we wait here until all metadata
  // is retrieved (which will synchronously trigger the display set creation)
  // until we run the hanging protocol matching service.

  Promise.allSettled(allRetrieves).then(() => {
    const displaySets = displaySetService.getActiveDisplaySets();

    if (!displaySets || !displaySets.length) {
      return;
    }

    const studyMap = {};

    // Prior studies don't quite work properly yet, but the studies list
    // is at least being generated and passed in.
    const studies = displaySets.reduce((prev, curr) => {
      const { StudyInstanceUID } = curr;
      if (!studyMap[StudyInstanceUID]) {
        const study = DicomMetadataStore.getStudy(StudyInstanceUID);
        studyMap[StudyInstanceUID] = study;
        prev.push(study);
      }
      return prev;
    }, []);

    const sortedStudies = sortBy(studies, study =>
      parse(study.StudyDate, 'yyyyMMdd', new Date())
    );

    // The assumption is that the display set at position 0 is the first
    // study being displayed, and is thus the "active" study.
    const activeStudy = sortedStudies[0];

    // run the hanging protocol matching on the displaySets with the predefined
    // hanging protocol in the mode configuration
    hangingProtocolService.run(
      { studies: sortedStudies, activeStudy, displaySets },
      hangingProtocols
    );
  });

  return unsubscriptions;
}
