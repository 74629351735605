import { Internal, OHIF } from '@gleamer/types';
import { useTask } from '@gleamer/ui';
import React from 'react';
import { ObservationCharacterisationForm } from '../../../modals/CharacterisationForm/ObservationCharacterisationForm';

type ServicesManager = OHIF.ServicesManager;
type ObservationSubmission = Internal.ObservationInternal;

type ObservationCharacterisationProps = {
  observation: ObservationSubmission;
  servicesManager: ServicesManager;
};

export function ObservationCharacterisation({
  observation,
  servicesManager,
}: ObservationCharacterisationProps) {
  const task = useTask();

  const observationSpec = task.observations.find(
    obs => obs.code === observation.code && obs.status === observation.status
  );

  if (!observationSpec) {
    return null;
  }

  const { characterisations } = observationSpec;
  if (!characterisations) {
    return null;
  }

  return (
    <ObservationCharacterisationForm
      characterisations={characterisations}
      observation={observation}
      servicesManager={servicesManager}
    />
  );
}
