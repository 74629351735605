import classNames from 'classnames';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { Button } from './Button';

export type PrimaryButtonProps = PropsWithChildren<{
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
}>;

export function PrimaryButton({ className, onClick, children, disabled }: PrimaryButtonProps) {
  return (
    <Button
      className={classNames('text-base', className)}
      variant="outlined"
      size="small"
      color="black"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
