import { generateDicomJsonDatasource } from '@gleamer/utils';
import { ServicesManager } from '@ohif/core';
import { Internal } from '@gleamer/types';

type PreviewParams = Internal.PreviewParams;

function getPreviewKey(params: PreviewParams) {
  return Object.values(params).filter(Boolean).join('-');
}

export function getDataSourcesModule({
  servicesManager,
}: {
  servicesManager: ServicesManager;
}) {
  const { DicomJSONService } = servicesManager.services;
  return [
    generateDicomJsonDatasource({
      servicesManager,
      name: 'preview',
      initialize: async ({ query }) => {
        const patientId = query.get('patientId');
        const studyInstanceUID = query.get('studyInstanceUID');
        const seriesInstanceUID = query.get('seriesInstanceUID');
        const sopInstanceUID = query.get('sopInstanceUID');

        const params: PreviewParams = {
          patient: patientId,
          study: studyInstanceUID,
          series: seriesInstanceUID,
          instance: sopInstanceUID,
        };

        if (patientId) {
          try {
            await DicomJSONService.getPreviewItem(params);
            return DicomJSONService.getStudyInstanceUIDs(getPreviewKey(params));
          } catch (err) {
            console.group('Error in preview datasource initialize');
            console.error(err);
            console.groupEnd();
            return [];
          }
        }
      },
      getStorageKey: ({ query }: { query?: URLSearchParams } = {}) => {
        const searchParams =
          query || new URLSearchParams(window.location.search);
        const patientId = searchParams.get('patientId');
        const studyInstanceUID = searchParams.get('studyInstanceUID');
        const seriesInstanceUID = searchParams.get('seriesInstanceUID');
        const sopInstanceUID = searchParams.get('sopInstanceUID');

        const params: PreviewParams = {
          patient: patientId,
          study: studyInstanceUID,
          series: seriesInstanceUID,
          instance: sopInstanceUID,
        };

        return getPreviewKey(params);
      },
    }),
  ];
}
