import { Auth0Context } from '@auth0/auth0-react';
import React from 'react';
import { Provider } from './context/Provider';
import { id } from './id';

export default {
  /**
   * Only required property. Should be a unique value across all extensions.
   * You ID can be anything you want, but it should be unique.
   */
  id,

  /**
   * Perform any pre-registration tasks here. This is called before the extension
   * is registered. Usually we run tasks such as: configuring the libraries
   * (e.g. cornerstone, cornerstoneTools, ...) or registering any services that
   * this extension is providing.
   */
  preRegistration: ({
    servicesManager,
    commandsManager,
    configuration = {},
  }) => {},

  /**
   * ContextModule should provide a list of context that will be available in OHIF
   * and will be provided to the Modes. A context is a state that is shared OHIF.
   * Context is defined by an object of { name, context, provider }. Examples include
   * the measurementTracking context provided by the measurementTracking extension.
   */
  getContextModule: ({
    servicesManager,
    commandsManager,
    extensionManager,
  }) => {
    return [
      {
        name: 'Auth0Context',
        context: Auth0Context,
        provider: ({ children }) => {
          const { UserAuthenticationService } = servicesManager.services;

          return (
            <Provider UserAuthenticationService={UserAuthenticationService}>
              {children}
            </Provider>
          );
        },
      },
    ];
  },
};

export const GdataAuthProvider = Provider;
