import { ServicesManager } from '@ohif/core';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAutoLogout } from '../hooks/useAutoLogout';
import GdataLayout from './pages/GdataLayout';
import { Home } from './pages/Home';
import MyTasks from './pages/MyTasks';
import { NotificationsPage } from './pages/Notifications';
import { ReportsWindow } from './pages/ReportsWindow';
import { ReviewSessionConfiguration } from './pages/ReviewSessionConfiguration';

export { AlertDialog } from './atoms/AlertDialog';
export { PreviousItemButtons } from './molecules/PreviousItemButtons';
export { ValidateButton } from './molecules/ValidateButton';
export { GleamerViewerLayout } from './organisms/ViewerLayout/GleamerViewerLayout';
export { AutoLogoutModal } from './organisms/modals/AutoLogoutModal';

type GdataRoutesProps = {
  servicesManager: ServicesManager;
};

export function GdataRoutes({ servicesManager }: GdataRoutesProps) {
  useAutoLogout();

  return (
    <Routes>
      <Route path="/" element={<GdataLayout />}>
        <Route path="" element={<Home />} />
        <Route path="my-label-tasks" element={<MyTasks />} />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route
          path="review-session/:taskId"
          element={
            <ReviewSessionConfiguration servicesManager={servicesManager} />
          }
        />
      </Route>
      <Route path="/reports" element={<ReportsWindow />} />
    </Routes>
  );
}
