import {
  Auth0Context,
  Auth0ContextInterface,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react';
import React from 'react';

function withCypress() {
  const { Cypress } = window as unknown as Window &
    typeof globalThis & {
      Cypress: any;
    };
  return !!Cypress;
}

const stub = (): never => {
  throw new Error(
    'You forgot to wrap your component in <GleamerAuth0Provider>.'
  );
};

export const GleamerAuth0Provider = ({
  children,
  onRedirectCallback,
  ...opts
}: Auth0ProviderOptions): JSX.Element => {
  if (!withCypress()) {
    return (
      <Auth0Provider onRedirectCallback={onRedirectCallback} {...opts}>
        {children}
      </Auth0Provider>
    );
  }

  const auth0 = JSON.parse(localStorage.getItem('auth0Cypress')!);
  const contextValue: Auth0ContextInterface = {
    isLoading: false,
    isAuthenticated: true,
    user: auth0.body.decodedToken.user,
    getAccessTokenSilently: () => auth0.body.access_token,
    loginWithRedirect: async (options) => onRedirectCallback(options?.appState),
    handleRedirectCallback: stub,
    loginWithPopup: stub,
    getAccessTokenWithPopup: stub,
    getIdTokenClaims: stub,
    logout: stub,
  };
  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};
