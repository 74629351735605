import { metaData } from '@cornerstonejs/core';
import { Internal } from '@gleamer/types';

type Region = Internal.RegionInternal;
type RegionSpecification = Internal.RegionSpecification;

function isMatching(matchingValues: string[], value: string) {
  if (!matchingValues || matchingValues.length === 0) {
    return true;
  }

  return matchingValues.includes(value);
}

export function isMatchingRegion(
  regionSpec: Pick<RegionSpecification, 'selector'>,
  sopClassUID?: string,
  modality?: string
) {
  if (!regionSpec.selector) {
    return true;
  }

  const { modalities, sopClassUIDs } = regionSpec.selector;

  return (
    isMatching(modalities, modality) && isMatching(sopClassUIDs, sopClassUID)
  );
}

export function getRegionSpecification(
  region: Region,
  specifications: RegionSpecification[]
) {
  const imageId = region.metadata.referencedImageId;

  // Image may not have been not loaded yet
  try {
    const { modality } = metaData.get('generalSeriesModule', imageId) || {};
    const { sopClassUID } = metaData.get('sopCommonModule', imageId) || {};

    return specifications.find(spec =>
      isMatchingRegion(spec, sopClassUID, modality)
    );
  } catch (e) {
    return null;
  }
}
