import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { AlertDialog } from '../atoms/AlertDialog';

export function ErrorAlert() {
  const [searchParams, setSearchParams] = useSearchParams();
  const onClose = () => {
    const hasError = searchParams.has('error');
    if (hasError) {
      searchParams.delete('error');
      setSearchParams(searchParams);
    }
  };

  return (
    <AlertDialog level="error" onClose={onClose}>
      <p className="text-base text-red-700">An unexpected error occured</p>
    </AlertDialog>
  );
}
