import { annotation } from '@cornerstonejs/tools';
import { OHIF } from '@gleamer/types';
import { createStore } from '@gleamer/ui';
import { drawRegion } from '../../utils/drawRegion';

export class ReduxStoreService {
  store: ReturnType<typeof createStore>;

  public static REGISTRATION = {
    name: 'reduxStoreService',
    altName: 'ReduxStoreService',
    create: ({ configuration = {}, servicesManager }) => {
      return new ReduxStoreService({ servicesManager });
    },
  };

  constructor({ servicesManager }: { servicesManager: OHIF.ServicesManager }) {
    this.store = createStore({ servicesManager, annotation, drawRegion });
  }

  dispatch(action: any) {
    return this.store.dispatch(action);
  }

  getStore() {
    return this.store;
  }

  getState() {
    return this.store.getState();
  }

  subscribe(listener: any) {
    return this.store.subscribe(listener);
  }
}
