import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const OVERLAY_EVENTS = {
  OVERLAY_STATE_CHANGED: 'event::hotKey:overlayStateChanged',
};

type OverlayVisibility = 'displayed' | 'hidden';

export const OverlayStateContext =
  createContext<OverlayVisibility>('displayed');

export function useOverlayState() {
  return useContext(OverlayStateContext);
}

export function OverlayStateContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const [visibility, setVisibility] = useState<OverlayVisibility>('displayed');

  const toggleVisibility = useCallback(() => {
    setVisibility(prevVisibility =>
      prevVisibility === 'displayed' ? 'hidden' : 'displayed'
    );
  }, []);

  useEffect(() => {
    window.addEventListener(
      OVERLAY_EVENTS.OVERLAY_STATE_CHANGED,
      toggleVisibility
    );

    return () => {
      window.removeEventListener(
        OVERLAY_EVENTS.OVERLAY_STATE_CHANGED,
        toggleVisibility
      );
    };
  }, [toggleVisibility]);

  return (
    <OverlayStateContext.Provider value={visibility}>
      {children}
    </OverlayStateContext.Provider>
  );
}
