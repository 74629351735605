import { Assets, Internal } from '@gleamer/types';
import { isReadonly, useTask } from '@gleamer/ui';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import { ServicesManager } from '@ohif/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CharacterisationButton } from '../../../atoms/CharacterisationButton';
import { AssetCharacterisationForm } from '../../modals/CharacterisationForm/AssetCharacterisationForm';
import { BUTTON_IDS } from '../../toolbar/constants';

type PatientReportAssetRef = Assets.PatientReportAssetRef;
type StudyReportAssetRef = Assets.StudyReportAssetRef;
type DicomJSONReport = Internal.DicomJSONReport;

export type ReportCharacterisationButtonProps = {
  className?: string;
  report: DicomJSONReport;
  servicesManager: ServicesManager;
};

export function ReportCharacterisationButton({
  servicesManager,
  className,
  report,
}: ReportCharacterisationButtonProps) {
  const task = useTask();
  const { t } = useTranslation();
  const { uiModalService } = servicesManager.services;

  const asset: StudyReportAssetRef | PatientReportAssetRef = {
    kind: report.StudyInstanceUID ? 'studyReport' : 'patientReport',
    patientId: report.PatientId,
    studyInstanceUID: report.StudyInstanceUID,
    id: report.id,
  };
  const readOnly = isReadonly();

  const onClick = () => {
    uiModalService.show({
      content: AssetCharacterisationForm,
      contentProps: {
        jsonSchema: task.characterisations[asset.kind].json,
        uiSchema: task.characterisations[asset.kind].ui,
        asset: asset,
        readOnly,
        servicesManager,
      },
      shouldCloseOnEsc: true,
      closeButton: true,
      title: t('Report Characterisation'),
    });
  };

  const buttonId =
    asset.kind === 'studyReport'
      ? `${BUTTON_IDS.REPORT}-${asset.studyInstanceUID}`
      : undefined;

  if (!task?.characterisations?.[asset.kind]) {
    return null;
  }

  return (
    <div className={className}>
      <CharacterisationButton
        onClick={onClick}
        id={buttonId}
        className="group relative inline-flex items-center justify-center rounded-md p-2 text-center text-lg font-bold text-white outline-none transition duration-300 ease-in-out hover:bg-primary-light hover:text-black focus:bg-primary-light focus:text-black focus:outline-none active:opacity-80"
        title={t('Characterise report')}
      >
        <ClipboardDocumentListIcon className="h-6 w-6" />
      </CharacterisationButton>
    </div>
  );
}
