import { Internal } from '@gleamer/types';
type DicomJSONMetadata = Internal.DicomJSONMetadata;

export interface CharacterisationContext {
  [name: string]: any;
}

export type ReportCharacterisationContext = { type: string } & (
  | PatientCharacterisationContext
  | StudyCharacterisationContext
);

export interface PatientCharacterisationContext {
  PatientSex: string;
}

export interface StudyCharacterisationContext {
  PatientSex: string;
  PatientAge: string;
  ModalitiesInStudy: string[];
}

export interface SeriesCharacterisationContext {
  Modality: string;
  SOPClassUID: string;
  PatientSex: string;
  PatientAge: string;
  BodyPartExamined: string;
  ImageTypeInSeries: string[];
  ViewCodeValueInSeries: string[];
  ProcedureCodeValueInSeries: string[];
}

export interface InstanceCharacterisationContext {
  Modality: string;
  SOPClassUID: string;
  PatientSex: string;
  PatientAge: string;
  BodyPartExamined: string;
  ImageType: string[];
  ViewCodeValue: string[];
  ProcedureCodeValue: string[];
}

export function formDataWithContext<T>(formData: T, context: CharacterisationContext) {
  return Object.entries(context).reduce(
    (form, [key, value]) => ({ ...form, [`context.${key}`]: value }),
    formData
  );
}

export function patientContext(metadata: DicomJSONMetadata): PatientCharacterisationContext {
  return {
    PatientSex: metadata.PatientSex,
  };
}

export function studyContext(metadata: DicomJSONMetadata): StudyCharacterisationContext {
  return {
    PatientSex: metadata.PatientSex,
    PatientAge: metadata.PatientAge,
    ModalitiesInStudy: metadata.ModalitiesInStudy,
  };
}

export function seriesContext(metadata: DicomJSONMetadata): SeriesCharacterisationContext {
  return {
    PatientSex: metadata.PatientSex,
    BodyPartExamined: metadata.BodyPartExamined,
    SOPClassUID: metadata.SOPClassUID,
    PatientAge: metadata.PatientAge,
    Modality: metadata.Modality,
    ImageTypeInSeries: metadata.ImageTypeInSeries,
    ViewCodeValueInSeries: metadata.ViewCodeValueInSeries,
    ProcedureCodeValueInSeries: metadata.ProcedureCodeValueInSeries,
  };
}

export function instanceContext(metadata: DicomJSONMetadata): InstanceCharacterisationContext {
  return {
    PatientSex: metadata.PatientSex,
    PatientAge: metadata.PatientAge,
    Modality: metadata.Modality,
    BodyPartExamined: metadata.BodyPartExamined,
    SOPClassUID: metadata.SOPClassUID,
    ViewCodeValue: metadata.ViewCodeValue,
    ImageType: metadata.ImageType,
    ProcedureCodeValue: metadata.ProcedureCodeValue,
  };
}

export const contextMappers = {
  Patient: patientContext,
  Study: studyContext,
  Series: seriesContext,
  Instance: instanceContext,
};
