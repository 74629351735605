import { useAuth0 } from '@auth0/auth0-react';
import { PrimaryButton } from '@gleamer/ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function LoginButton() {
  const { t } = useTranslation();
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  const doLogin = useCallback(() => {
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      },
    });
  }, [loginWithRedirect]);

  const doLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const className = 'text-white';

  if (!isLoading && !isAuthenticated) {
    return (
      <PrimaryButton className={className} onClick={doLogin}>
        {t('Header:Login')}
      </PrimaryButton>
    );
  }
  return (
    <PrimaryButton className={className} onClick={doLogout}>
      {t('Header:Logout')}
    </PrimaryButton>
  );
}
