import { Dropdown, Icon, IconButton, NavBar } from '@ohif/ui';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type MenuOption = {
  title: string;
  icon: string;
  onClick: () => void;
};

type HeaderProps = {
  menuOptions?: MenuOption[];
  children?: ReactNode;
  isSticky?: boolean;
  rightContent?: ReactNode;
};

export function Header({
  children,
  menuOptions,
  isSticky,
  rightContent,
}: HeaderProps) {
  const { t } = useTranslation('Header');

  return (
    <NavBar
      className="justify-between border-b-4 border-black"
      isSticky={isSticky}
    >
      <div className="flex flex-1 items-center justify-between ">
        <div className="flex items-center">
          <Link
            title={t('Go to tasks')}
            className="mr-3 inline-flex cursor-pointer items-center"
            to="/my-label-tasks"
          >
            <Icon name="chevron-left" className="w-8 text-primary-active" />
            <div className="ml-4">
              <h1 className="text-white">GData</h1>
            </div>
          </Link>
        </div>
        {children}
        <div className="flex items-center">
          {rightContent}
          {menuOptions?.length > 0 && (
            <Dropdown
              id="options"
              showDropdownIcon={false}
              list={menuOptions}
              alignment="right"
            >
              <IconButton
                id="options-settings-icon"
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
              >
                <Icon name="settings" />
              </IconButton>
              <IconButton
                id="options-chevron-down-icon"
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
              >
                <Icon name="chevron-down" />
              </IconButton>
            </Dropdown>
          )}
        </div>
      </div>
    </NavBar>
  );
}
