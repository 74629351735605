import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, {
  createContext,
  HTMLAttributes,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

type AccordionContextValue = {
  open: boolean;
  toggle: () => void;
};

const AccordionContext = createContext<AccordionContextValue>({
  open: false,
  toggle: () => {},
});

const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('Your component must be used within an Accordion component');
  }
  return context;
};

type AccordionProps = PropsWithChildren<{
  initialOpen?: boolean;
  open?: boolean;
  onClick?: () => any;
}>;

function AccordionRoot({ children, initialOpen = false, open, onClick }: AccordionProps) {
  const [openState, setIsOpen] = useState(initialOpen);

  const toggle = useCallback(() => setIsOpen(wasOpen => !wasOpen), []);

  if (open !== undefined && open !== null && !onClick) {
    console.error(
      'You have to provide a `onClick` to Accordion when state is controlled (aka `open` prop is provided)'
    );
  }

  return (
    <AccordionContext.Provider
      value={{ open: open ?? openState, toggle: onClick ? onClick : toggle }}
    >
      {children}
    </AccordionContext.Provider>
  );
}

type AccordionTitleProps = PropsWithChildren<{
  inactive?: boolean;
  className?: string;
  chevronClassName?: string;
  inactiveClassName?: string;
}>;

function Title({
  children,
  className,
  inactive,
  chevronClassName,
  inactiveClassName,
  ...props
}: AccordionTitleProps & HTMLAttributes<HTMLElement>) {
  const { open, toggle } = useAccordionContext();

  const ChevronComponent = open ? ChevronDownIcon : ChevronRightIcon;

  if (inactive) {
    return (
      <span
        className={classNames(
          'ml-2 flex h-5 items-center font-bold uppercase tracking-widest text-white',
          className,
          inactiveClassName
        )}
        {...props}
      >
        {children}
      </span>
    );
  }

  return (
    <button
      className={classNames(
        'flex items-center font-bold uppercase tracking-widest text-white',
        className
      )}
      onClick={toggle}
      {...props}
    >
      <span>
        <ChevronComponent className={classNames('mr-2 w-4 text-white', chevronClassName)} />
      </span>
      {children}
    </button>
  );
}

function Content({ children }: PropsWithChildren<{}>): JSX.Element {
  const { open } = useAccordionContext();

  if (!open) {
    return null;
  }

  return <>{children}</>;
}

export const Accordion = Object.assign(AccordionRoot, { Title, Content });
