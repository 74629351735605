import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import React from 'react';

export function OpenReportsWindowButton() {
  const createReportsWindow = () => {
    window.open(
      '/reports',
      'reports',
      `top=0,left=${Math.round(window.innerWidth / 2)},width=${Math.round(
        window.innerWidth / 2
      )},height=${window.innerHeight}`
    );
  };

  return (
    <button
      onClick={createReportsWindow}
      title="Display reports in another window"
      className="w-12 px-4 text-white"
    >
      <ArrowTopRightOnSquareIcon />
    </button>
  );
}
