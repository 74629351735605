import { JsonTypes } from '../common/common.type';
import {
  AssetCharacterisationSpecificationsApi,
  CharacterisationValueApi,
  CharacterisationValuesApi,
} from './characterisation.api.type';
import {
  CharacterisationValueInternal,
  CharacterisationValuesInternal,
  AssetCharacterisationSpecificationsInternal,
} from './characterisation.internal.type';

export const toCharacterisationValuesInternal = (
  characterisation: CharacterisationValuesApi
): CharacterisationValuesInternal => {
  return Object.entries(characterisation).reduce((acc, [key, value]) => {
    acc[key] = value as JsonTypes;
    return acc;
  }, {} as CharacterisationValuesInternal);
};

export const toCharacterisationValueInternal = (
  characterisation: CharacterisationValueApi
): CharacterisationValueInternal => {
  return {
    asset: { ...characterisation.asset },
    characterisation: toCharacterisationValuesInternal(characterisation.characterisation),
  };
};

export const toCharacterisationValuesApi = (
  characterisation: CharacterisationValuesInternal
): CharacterisationValuesApi => {
  return Object.entries(characterisation).reduce((acc, [key, value]) => {
    acc[key] = value as JsonTypes;
    return acc;
  }, {} as CharacterisationValuesApi);
};

export const toCharacterisationValueApi = (
  characterisation: CharacterisationValueInternal
): CharacterisationValueApi => {
  return {
    asset: { ...characterisation.asset },
    characterisation: toCharacterisationValuesApi(characterisation.characterisation),
  };
};

export const toAssetCharacterisationSpecificationsApi = (
  characterisationsSpecification: AssetCharacterisationSpecificationsInternal
): AssetCharacterisationSpecificationsApi => {
  return { ...characterisationsSpecification };
};

export const toAssetCharacterisationSpecificationsInternal = (
  characterisationsSpecification: AssetCharacterisationSpecificationsApi
): AssetCharacterisationSpecificationsInternal => {
  return { ...characterisationsSpecification };
};
