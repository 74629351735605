import { OHIF } from '@gleamer/types';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { isEqual } from 'lodash';
import React from 'react';
import { getDefaultMouseBindings } from '../../../../utils/tools/mouse-bindings';
import { MouseInput } from './MouseInput';

type ToolConfigBinding = OHIF.ToolConfigBinding;

type ToolMouseBindingsProps = {
  bindings: ToolConfigBinding[];
  toolName: string;
  onChange: (bindings: ToolConfigBinding[]) => void;
  className?: string;
  configs: ReturnType<typeof getDefaultMouseBindings>;
};

export function ToolMouseBindings({
  bindings,
  onChange,
  className,
  configs,
  toolName,
}: ToolMouseBindingsProps) {
  const onAdd = () => {
    onChange([...bindings, { mouseButton: null }]);
  };

  const onRemove = (config: ToolConfigBinding) => {
    onChange(bindings.filter(b => !isEqual(b, config)));
  };

  const onBindingChange = (
    config: ToolConfigBinding,
    newConfig: ToolConfigBinding
  ) => {
    onChange(
      bindings.map(bd => {
        if (isEqual(bd, config)) {
          return newConfig;
        }
        return bd;
      })
    );
  };

  return (
    <div className={className}>
      {bindings.length === 0 && (
        <button
          className="text-green-600"
          title="Add a new binding"
          onClick={onAdd}
        >
          <PlusCircleIcon className="h-5 w-5" />
        </button>
      )}
      {bindings.map((binding, index, all) => (
        <div
          className="flex flex-row items-center px-1 gap-1"
          key={`${binding.mouseButton}-${binding.modifierKey}`}
        >
          <MouseInput
            toolName={toolName}
            configs={configs}
            binding={binding}
            onChange={newBinding => onBindingChange(binding, newBinding)}
          />
          <button
            className="text-red-600"
            title="Remove binding"
            onClick={() => onRemove(binding)}
          >
            <MinusCircleIcon className="h-5 w-5" />
          </button>
          {index + 1 === all.length && (
            <button
              className="text-green-600"
              title="Add a new binding"
              onClick={onAdd}
            >
              <PlusCircleIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
}
