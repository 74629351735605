import { useSelector } from 'react-redux';
import { getNextItemId, getPreviousItemId, isLastSeenItem } from './tasksSeenItems.selectors';

export function useIsLastSeenItem() {
  return useSelector(isLastSeenItem);
}

export function usePreviousItemId(): string | null {
  return useSelector(getPreviousItemId);
}

export function useNextItemId(): string | null {
  return useSelector(getNextItemId);
}
