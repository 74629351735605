import React from 'react';
import { Internal } from '@gleamer/types';
import { displayDate } from '../../../../utils/utils';
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;

type ReportTitleProps = {
  report: EnhancedDicomJSONReport;
};

export function ReportTitle({ report }: ReportTitleProps) {
  if (report.StudyInstanceUID) {
    return (
      <>
        {displayDate(report.date)} {report.modalities}
      </>
    );
  }

  return (
    <>
      {displayDate(report.date)} {report.type}
    </>
  );
}
