import { Api } from '@gleamer/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Notification } from './Notification';
import classNames from 'classnames';

export type NotificationListProps = {
  notifications: Api.Notification[];
  onNotificationRead: (id: number) => any;
  variant?: 'default' | 'long';
};

export function NotificationList({
  variant = 'default',
  notifications,
  onNotificationRead,
}: NotificationListProps) {
  return (
    <div
      className={classNames(
        'flex flex-col gap-y-2 rounded-md border bg-white p-2',
        {
          'w-72': variant === 'default',
          'w-full': variant === 'long',
        }
      )}
    >
      {notifications.length === 0 && (
        <div className="text-center text-sm">No new notifications</div>
      )}
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          notification={notification}
          onRead={onNotificationRead}
          variant={variant}
        />
      ))}
      <hr className="my-1" />
      <div className="mb-1 text-center text-sm">
        <Link to="/notifications">View all</Link>
      </div>
    </div>
  );
}
