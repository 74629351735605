import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import parse from 'date-fns/parse';

import { StudyItem } from './StudyItem';
import { ThumbnailList } from './ThumbnailList';
import { StudyCharacterisation } from './StudyCharacterisation';

export function StudyBrowser({
  studies,
  expandedStudyInstanceUIDs,
  onClickStudy,
  onFillViewports,
  onClickThumbnail,
  onDoubleClickThumbnail,
  onDoubleClickInstanceThumbnail,
  activeDisplaySetInstanceUIDs,
  servicesManager,
  getImageSrc,
}) {
  return (
    <div
      className="ohif-scrollbar flex flex-1 flex-col overflow-auto"
      data-testid="studies-panel"
    >
      {sortBy(studies, study =>
        parse(study.date, 'dd-MMM-yyyy', new Date())
      ).map(({ studyInstanceUid, date, displaySets }) => {
        const isExpanded = expandedStudyInstanceUIDs.includes(studyInstanceUid);
        return (
          <React.Fragment key={studyInstanceUid}>
            <StudyItem
              date={date}
              numInstances={getSeriesCount(displaySets)}
              modalities={getModalities(displaySets)}
              displaySets={displaySets}
              activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
              isActive={isExpanded}
              onClick={() => {
                onClickStudy(studyInstanceUid);
              }}
              onFillViewports={e => onFillViewports(e, studyInstanceUid)}
              data-cy="thumbnail-list"
              studyInstanceUID={studyInstanceUid}
            />
            {isExpanded && displaySets && (
              <>
                <StudyCharacterisation
                  StudyInstanceUID={studyInstanceUid}
                  servicesManager={servicesManager}
                />
                <ThumbnailList
                  thumbnails={displaySets}
                  activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
                  onThumbnailClick={onClickThumbnail}
                  onThumbnailDoubleClick={onDoubleClickThumbnail}
                  onDoubleClickInstanceThumbnail={
                    onDoubleClickInstanceThumbnail
                  }
                  getImageSrc={getImageSrc}
                  servicesManager={servicesManager}
                />
              </>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

function getModalities(displaySets: { modality: string }[]): string[] {
  return Array.from(
    new Set(displaySets.map(displaySet => displaySet.modality))
  );
}

function getSeriesCount(displaySets: any[]): number {
  return displaySets.length;
}

StudyBrowser.propTypes = {
  onClickStudy: PropTypes.func,
  onFillViewports: PropTypes.func,
  onClickThumbnail: PropTypes.func,
  onDoubleClickThumbnail: PropTypes.func,
  onDoubleClickInstanceThumbnail: PropTypes.func,
  expandedStudyInstanceUIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  getImageSrc: PropTypes.func.isRequired,
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      studyInstanceUid: PropTypes.string.isRequired,
      date: PropTypes.string,
      numInstances: PropTypes.number,
      modalities: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      description: PropTypes.string,
      displaySets: PropTypes.arrayOf(
        PropTypes.shape({
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageSrc: PropTypes.string,
          imageAltText: PropTypes.string,
          seriesDate: PropTypes.string,
          seriesNumber: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          numInstances: PropTypes.number,
          inverted: PropTypes.bool,
          description: PropTypes.string,
          componentType: PropTypes.oneOf(['thumbnail', 'thumbnailNoImage'])
            .isRequired,
          isTracked: PropTypes.bool,
          viewportIdentificator: PropTypes.arrayOf(PropTypes.string),
          /**
           * Data the thumbnail should expose to a receiving drop target. Use a matching
           * `dragData.type` to identify which targets can receive this draggable item.
           * If this is not set, drag-n-drop will be disabled for this thumbnail.
           *
           * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
           */
          dragData: PropTypes.shape({
            /** Must match the "type" a dropTarget expects */
            type: PropTypes.string.isRequired,
          }),
        })
      ),
    })
  ).isRequired,
};

const noop = () => {};

StudyBrowser.defaultProps = {
  onClickStudy: noop,
  onClickThumbnail: noop,
  onDoubleClickThumbnail: noop,
};
