import { Internal } from '@gleamer/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LabelTask = Internal.LabelTask;
type Observation = Internal.ObservationInternal;
type ObservationsSpecifications = Internal.ObservationsSpecifications;

export type LabelTaskState = {
  task: LabelTask;
  status: 'idle' | 'loading' | 'failed';
};

const initialState: LabelTaskState = {
  task: null,
  status: 'loading',
};

export const labelTaskSlice = createSlice({
  name: 'labelTask',
  initialState,
  reducers: {
    setTask(state, action: PayloadAction<LabelTask>) {
      state.task = action.payload;
    },

    initObservations(
      state,
      action: PayloadAction<{
        observations: Observation[];
        observationsSpecifications: ObservationsSpecifications[];
      }>
    ) {
      if (!state.task) {
        console.error('Tried to initialize observations without a task');
        return;
      }

      state.task.initialObservations = action.payload.observations;
      state.task.observations = action.payload.observationsSpecifications;
      if (state.task.initialCharacterisations) {
        state.status = 'idle';
      }
    },

    initCharacterisations(state, action: PayloadAction<Internal.AssetCharacterisationInternal[]>) {
      if (!state.task) {
        console.error('Tried to initialize characterizations without a task');
        return;
      }

      state.task.initialCharacterisations = action.payload;
      if (state.task.initialObservations) {
        state.status = 'idle';
      }
    },

    startLoading(state) {
      state.task = null;
      state.status = 'loading';
    },
  },
});

export const actions = labelTaskSlice.actions;
export const reducer = labelTaskSlice.reducer;
