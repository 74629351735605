export class ChannelPublisher<T> {
  private readonly channel: string;
  private bc: BroadcastChannel | null = null;
  public constructor(channel: string) {
    this.channel = channel;
  }

  public publish<T>(data: T): void {
    if (!this.bc) {
      throw new Error('Channel is not open');
    }
    this.bc.postMessage(data);
  }

  public start(): void {
    if (!this.bc) {
      this.bc = new BroadcastChannel(this.channel);
    }
  }

  public stop(): void {
    this.bc?.close();
    this.bc = null;
  }
}
