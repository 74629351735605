import { annotation as csToolsAnnotation } from '@cornerstonejs/tools';
import { Internal } from '@gleamer/types';
import { ServicesManager } from '@ohif/core';
import tinycolor from 'tinycolor2';
import { RootState } from '../store';
import { getItemLabelPassState } from './labelPass.selectors';

type ObservationSubmission = Internal.ObservationInternal;
type LabelTask = Internal.LabelTask;
type Region = Internal.RegionInternal;

export type DrawerMiddlewareConfig = {
  servicesManager: ServicesManager;
  annotation: typeof csToolsAnnotation;
};

function getControlsContainingValue(
  labelTask: LabelTask,
  observation: Pick<ObservationSubmission, 'code' | 'status'>,
  region: Region
): {
  availableControls: ControledEnum[];
  value: string;
} {
  if (!labelTask) {
    return { availableControls: [], value: '' };
  }

  const matchingObs = labelTask.observations.find(
    obs => obs.code === observation.code && obs.status === observation.status
  );
  if (!matchingObs) {
    return { availableControls: [], value: '' };
  }

  const matchingRegionSpec = matchingObs.regions
    .filter(regionSpec => regionSpec.tool.control)
    .find(regionSpec => {
      if (!regionSpec.characterisation.json?.properties) {
        return false;
      }

      const { control } = regionSpec.tool;
      const { properties } = regionSpec.characterisation.json;
      if (!properties?.[control]?.oneOf) {
        return false;
      }

      const value = region.characterisation?.[control];

      return properties[control].oneOf.some(item => item.const === value);
    });

  if (!matchingRegionSpec) {
    return { availableControls: [], value: '' };
  }

  const { control } = matchingRegionSpec.tool;
  const availableControls = matchingRegionSpec.characterisation.json.properties[control].oneOf;

  return {
    availableControls,
    value: (region.characterisation?.[control] || '') as string,
  };
}

type ControledEnum = {
  title: string;
  const: string;
};

function getAnnotationColor(color: string, availableControls: ControledEnum[], value: string) {
  let usedColor = color;
  if (availableControls?.length && value) {
    const initialColor = tinycolor(color);
    const analogousColors = new Array(availableControls.length)
      .fill(1)
      .map((_, index) => initialColor.spin((index * 15) % 360).toHexString());

    const controlIndex = availableControls.findIndex(control => control.const === value);

    usedColor = analogousColors[controlIndex];
  }
  return usedColor;
}

export function drawerMiddleware({ servicesManager, annotation }: DrawerMiddlewareConfig) {
  const { cornerstoneViewportService } = servicesManager.services;

  return store => next => action => {
    const result = next(action);

    const { labelTask } = store.getState() as RootState;
    const { task } = labelTask;

    const { observations } = getItemLabelPassState(store.getState());

    const renderingEngine = cornerstoneViewportService.getRenderingEngine();
    observations.forEach(observation => {
      const { color } = observation;
      observation.regions.forEach((region, index) => {
        const { uid } = region;
        annotation.selection.deselectAnnotation(uid);

        const { availableControls, value } = getControlsContainingValue(task, observation, region);

        if (!color) {
          return;
        }

        const currentAnnotation = annotation.state.getAnnotationManager().getAnnotation(uid);

        if (!currentAnnotation) {
          return;
        }

        annotation.visibility.setAnnotationVisibility(uid, !observation.hidden);

        currentAnnotation.data.label = `${observation.label}:${index + 1}`;
        if (value) {
          const control = availableControls.find(c => c.const === value);
          currentAnnotation.data.label += ` (${control.title})`;
        }

        const annotationStyle = annotation.config.style;
        const { lineWidth } = annotationStyle.getDefaultToolStyles().global;

        const usedColor = getAnnotationColor(color, availableControls, value);

        const lineWidthHovered = String(parseFloat(lineWidth) * 2);
        annotationStyle.setAnnotationStyles(uid, {
          color: usedColor,
          colorActive: usedColor,
          colorEnabled: usedColor,
          colorHighlighted: usedColor,
          colorSelected: usedColor,
          lineWidthSelected: lineWidthHovered,
          lineWidthHighlighted: lineWidthHovered,
        });
      });
    });

    renderingEngine.render();

    return result;
  };
}
