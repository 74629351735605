import { Api, Internal, OHIF } from '@gleamer/types';
import { formatLabelPass } from './utils/format-label-pass';

type UserAuthenticationService = OHIF.UserAuthenticationService;
type LabelPassSubmission = Internal.LabelPassSubmission;
type LabelTaskItem = Internal.LabelTaskItem;
type LabelPassApi = Api.LabelPassApi;

function isBlank(field: string) {
  return field.trim() === '';
}

type Violation = { field: string; message: string };

export const createLabelPass = saveLabelPass('POST');
export const updateLabelPass = saveLabelPass('PUT');
export const fixLabelPass = saveLabelPass('PATCH');

function saveLabelPass(method: 'PUT' | 'POST' | 'PATCH') {
  return async function (
    UserAuthenticationService: UserAuthenticationService,
    task: number,
    item: LabelTaskItem,
    labelPass: LabelPassSubmission
  ) {
    const { PatientID } = item.studies[0];
    const defaultLabeler = UserAuthenticationService.getUser().email;

    const labelPassApi: Api.LabelPassApi = {
      item: {
        taskId: task,
        itemId: item.id,
      },
      ...formatLabelPass(
        {
          labeledBy: defaultLabeler,
          ...labelPass,
        },
        PatientID
      ),
    };

    const response = await fetch('/api/label-passes', {
      method,
      headers: new Headers({
        ...UserAuthenticationService.getAuthorizationHeader(),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      body: JSON.stringify(labelPassApi),
    });

    if (response.status >= 400) {
      let error;
      try {
        const body: { violations: Violation[] } = await response.json();
        const violationMessages = body.violations
          .map(({ field, message }) => (isBlank(field) ? message : `${field}: ${message}`))
          .join(', ');
        error = new Error(`${violationMessages}`);
      } catch (err) {
        error = new Error(`${response.status}: ${response.statusText}`);
      }
      throw error;
    }

    // There is no body in the response. We only return it.
    return response;
  };
}

export async function fetchLabelPass(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string,
  labeledBy: string
): Promise<LabelPassApi> {
  const response = await fetch(
    `/api/label-passes?taskId=${taskId}&itemId=${itemId}&labeledBy=${labeledBy}`,
    {
      method: 'GET',
      headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
    }
  );
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }

  const data = (await response.json()) as LabelPassApi;

  // FIXME: this should be temporary as we have some regions not matching current item.
  // This is due to the mammo then mammo+tomo workflow that saves "old" regions on 2nd item.
  const referenceRegex = new RegExp(`/items/${itemId}`);

  data.observations.forEach(observation => {
    observation.regions = observation.regions.filter(region =>
      referenceRegex.test(region.annotation.metadata.referencedImageId)
    );
  });

  return data;
}
