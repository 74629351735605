import { Api, Internal } from '@gleamer/types';
import { UseQueryResult } from '@tanstack/react-query';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { PageButton } from '../../atoms/PageButton';
import { TableBodyTd, TableHeadTh } from '../../atoms/Table';
import { TaskLink } from '../../molecules/TaskLink';

type LabelTask = Internal.LabelTask;
type Page<T> = Api.Page<T>;

type DisplayedTask = Pick<
  LabelTask,
  'taskId' | 'name' | 'description' | 'reviewer' | 'createdAt'
>;

const columnHelper = createColumnHelper<DisplayedTask>();
const columns = [
  columnHelper.accessor('taskId', {
    header: 'Task ID',
    enableSorting: false,
    cell: ({ row }) => <TaskLink task={row.original} />,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    enableSorting: false,
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor('reviewer', {
    header: 'Role',
    enableSorting: false,
    cell: ({ getValue }) => (getValue() ? 'Reviewer' : 'Labeler'),
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    enableSorting: false,
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor('createdAt', {
    header: 'Last modified at',
    enableSorting: true,
    cell: ({ getValue }) => formatDate(getValue()),
  }),
];

function formatDate(dateToFormat: string) {
  const date = new Date(dateToFormat);
  return date.toLocaleDateString();
}

type TasksTableProps = {
  tasksQuery: Partial<UseQueryResult<Page<DisplayedTask>>>;
  pagination: PaginationState;
  sorting: SortingState;
  onPaginationChange: (pagination: PaginationState) => void;
  onSortingChange: (sorting: SortingState) => void;
};

export function TasksTable({
  tasksQuery,
  onPaginationChange,
  sorting,
  onSortingChange,
  pagination: { pageIndex, pageSize },
}: TasksTableProps) {
  const { data: labelTasksPage, status } = tasksQuery;

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: labelTasksPage?.content || [],
    columns,
    state: {
      pagination,
      sorting,
    },
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    pageCount: labelTasksPage?.totalPages ?? -1,
    onPaginationChange,
    manualPagination: true,
    enableSortingRemoval: false,
  });

  if (status === 'loading') {
    return <div>Loading ...</div>;
  }

  if (status === 'error') {
    return <div>An error occured</div>;
  }

  if (labelTasksPage?.totalElements === 0) {
    return <div>No task assigned</div>;
  }

  return (
    <>
      <div className="not-prose relative rounded-lg">
        <div className="shadow-sm">
          <table className="w-full table-auto">
            <thead className="bg-gray-200">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <TableHeadTh key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </TableHeadTh>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="group">
                  {row.getVisibleCells().map(cell => (
                    <TableBodyTd key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableBodyTd>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {labelTasksPage?.totalPages > 1 && (
        <div className="py-2">
          <span>Current Page: {table.getState().pagination.pageIndex + 1}</span>
          <PageButton
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous Page
          </PageButton>{' '}
          <PageButton
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next Page
          </PageButton>
        </div>
      )}
    </>
  );
}
