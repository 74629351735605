import { Internal } from '@gleamer/types';
import { Accordion, labelPass, useTask } from '@gleamer/ui';
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegionSpecification } from '../../../../../utils/region.utils';
import { RegionCharacterisation } from './RegionCharacterisation';

const { clearLastUpdatedRegion } = labelPass.actions;

type LabelTask = Internal.LabelTask;
type ObservationSubmission = Internal.ObservationInternal;
type Region = Internal.RegionInternal;

export type RegionItemActions = {
  onClick: (args: { region: Region; event: Event }) => void;
  onDelete: (args: { region: Region; event: Event }) => void;
  onFocusRegion: (args: { region: Region }) => void;
  onHover: (args: { region: Region }) => void;
  onMouseLeave: (args: { region: Region }) => void;
};

type RegionItemProps = {
  region: Region;
  index: number;
  observation: ObservationSubmission;
  open: boolean;
} & RegionItemActions;

export default function RegionItem({
  region,
  index,
  onClick,
  onDelete = () => {},
  onFocusRegion = () => {},
  onHover = () => {},
  onMouseLeave: onMouseExit = () => {},
  observation,
  open,
}: RegionItemProps) {
  const task = useTask();

  const dispatch = useDispatch();
  const lastUpdatedRegionUid = useSelector(
    labelPass.selectors.getLastUpdatedRegionUid
  );

  const { isActive } = region;

  const [isHovering, setIsHovering] = useState(false);
  const [openRegion, setOpenRegion] = useState<boolean>(open);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    setOpenRegion(open);
  }, [open]);

  useEffect(() => {
    setIsFocused(region.uid === lastUpdatedRegionUid);
  }, [lastUpdatedRegionUid, region.uid]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFocused) {
      ref.current.scrollIntoView();
    } else {
      ref.current.blur();
    }
  }, [isFocused]);

  const onDeleteHandler = event => {
    event.stopPropagation();
    onDelete({ region, event });
  };

  const onFocusHandler = event => {
    event.stopPropagation();
    onFocusRegion({ region });
  };

  const onClickHandler = event => onClick({ region, event });

  const onMouseEnter = () => {
    setIsHovering(true);
    dispatch(clearLastUpdatedRegion());
    onHover({ region });
  };

  const onMouseLeave = () => {
    setIsHovering(false);
    onMouseExit({ region });
  };

  const displayRegionCharacterisation = shouldDisplayRegionCharacterisation(
    task,
    observation,
    region
  );

  return (
    <Accordion open={openRegion} onClick={() => setOpenRegion(!openRegion)}>
      <div
        className={classnames(
          'group flex cursor-pointer border-transparent outline-none transition duration-300',
          {
            'overflow-hidden rounded border-primary-light': isActive,
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClickHandler}
        role="button"
        tabIndex={0}
        ref={ref}
        data-cy={'measurement-item'}
      >
        <div
          className={classnames('relative flex flex-1 flex-col py-1', {
            'active bg-primary-light text-black': isActive,
            'bg-primary-dark text-primary-light group-hover:bg-secondary-main':
              !isActive,
            'bg-secondary-main': isFocused,
          })}
          data-testid={`region-${index}-parent`}
        >
          <div className="flex items-center">
            <Accordion.Title inactive={!displayRegionCharacterisation}>
              <h6 className="text-xs font-medium">Region {index}</h6>
            </Accordion.Title>
            <button
              title={`Focus region ${index}`}
              className={classnames(
                'ml-2 w-4 cursor-pointer text-white transition duration-300',
                { 'invisible mr-2 opacity-0': !isActive && !isHovering },
                { 'opacity-1 visible mr-1': !isActive && isHovering }
              )}
              onClick={onFocusHandler}
            >
              <MagnifyingGlassIcon />
            </button>
            <button
              title={`Delete region ${index}`}
              className={classnames(
                'absolute w-4 cursor-pointer text-white transition duration-300',
                { 'invisible mr-2 opacity-0': !isActive && !isHovering },
                { 'opacity-1 visible mr-1': !isActive && isHovering }
              )}
              style={{
                right: 6,
                transform: isActive || isHovering ? '' : 'translateX(100%)',
              }}
              onClick={onDeleteHandler}
            >
              <TrashIcon />
            </button>
          </div>
        </div>
      </div>
      <Accordion.Content>
        <div className="overflow-hidden pl-4">
          <RegionCharacterisation region={region} observation={observation} />
        </div>
      </Accordion.Content>
    </Accordion>
  );
}

function shouldDisplayRegionCharacterisation(
  task: LabelTask,
  observation: ObservationSubmission,
  region: Region
): boolean {
  const observationSpec = task.observations.find(
    obs => obs.code === observation.code
  );

  if (!observationSpec) {
    return false;
  }

  const regionSpec = getRegionSpecification(region, observationSpec.regions);
  if (!regionSpec) {
    return false;
  }

  const { characterisation } = regionSpec;
  if (!characterisation) {
    return false;
  }

  return !!characterisation?.json;
}
