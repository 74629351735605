import PropTypes from 'prop-types';
import React from 'react';

import { ThumbnailNoImage } from '@ohif/ui';
import Thumbnail from './Thumbnail';

export function ThumbnailList({
  thumbnails,
  onThumbnailClick,
  onThumbnailDoubleClick,
  onDoubleClickInstanceThumbnail,
  activeDisplaySetInstanceUIDs = [],
  getImageSrc,
  servicesManager,
}) {
  return (
    <div className="py-3">
      {thumbnails.map(
        ({
          displaySetInstanceUID,
          description,
          dragData,
          seriesNumber,
          numInstances,
          modality,
          componentType,
          seriesDate,
          canReject,
          onReject,
          imageSrc,
          imageAltText,
          StudyInstanceUID,
          SeriesInstanceUID,
          SopInstanceUIDs,
          imageIds,
          inverted,
        }) => {
          const isActive = activeDisplaySetInstanceUIDs.includes(
            displaySetInstanceUID
          );

          switch (componentType) {
            case 'thumbnail':
              return (
                <Thumbnail
                  key={displaySetInstanceUID}
                  displaySetInstanceUID={displaySetInstanceUID}
                  dragData={dragData}
                  description={description}
                  seriesNumber={seriesNumber}
                  numInstances={numInstances}
                  imageSrc={imageSrc}
                  imageAltText={imageAltText}
                  isActive={isActive}
                  onClick={() => onThumbnailClick(displaySetInstanceUID)}
                  onDoubleClick={() =>
                    onThumbnailDoubleClick(displaySetInstanceUID, inverted)
                  }
                  onDoubleClickInstanceThumbnail={
                    onDoubleClickInstanceThumbnail
                  }
                  SopInstanceUIDs={SopInstanceUIDs}
                  imageIds={imageIds}
                  inverted={inverted}
                  getImageSrc={getImageSrc}
                  StudyInstanceUID={StudyInstanceUID}
                  SeriesInstanceUID={SeriesInstanceUID}
                  servicesManager={servicesManager}
                />
              );
            case 'thumbnailNoImage':
              return (
                <ThumbnailNoImage
                  key={displaySetInstanceUID}
                  isActive={isActive}
                  displaySetInstanceUID={displaySetInstanceUID}
                  dragData={dragData}
                  modality={modality}
                  modalityTooltip={_getModalityTooltip(modality)}
                  seriesDate={seriesDate}
                  description={description}
                  canReject={canReject}
                  onReject={onReject}
                  onClick={() => onThumbnailClick(displaySetInstanceUID)}
                  onDoubleClick={() =>
                    onThumbnailDoubleClick(displaySetInstanceUID, inverted)
                  }
                />
              );
            default:
              return <></>;
          }
        }
      )}
    </div>
  );
}

ThumbnailList.propTypes = {
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      displaySetInstanceUID: PropTypes.string.isRequired,
      imageSrc: PropTypes.string,
      imageAltText: PropTypes.string,
      seriesDate: PropTypes.string,
      seriesNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      numInstances: PropTypes.number,
      description: PropTypes.string,
      componentType: PropTypes.oneOf(['thumbnail', 'thumbnailNoImage'])
        .isRequired,
      isTracked: PropTypes.bool,
      /**
       * Data the thumbnail should expose to a receiving drop target. Use a matching
       * `dragData.type` to identify which targets can receive this draggable item.
       * If this is not set, drag-n-drop will be disabled for this thumbnail.
       *
       * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
       */
      dragData: PropTypes.shape({
        /** Must match the "type" a dropTarget expects */
        type: PropTypes.string.isRequired,
      }),
    })
  ),
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  onThumbnailClick: PropTypes.func.isRequired,
  onThumbnailDoubleClick: PropTypes.func.isRequired,
  onDoubleClickInstanceThumbnail: PropTypes.func.isRequired,
  getImageSrc: PropTypes.func.isRequired,
  servicesManager: PropTypes.object.isRequired,
};

// TODO: Support "Viewport Identificator"?
function _getModalityTooltip(modality) {
  if (_modalityTooltips.hasOwnProperty(modality)) {
    return _modalityTooltips[modality];
  }

  return 'Unknown';
}

const _modalityTooltips = {
  SR: 'Structured Report',
};
