import { RegionApi } from './observation.api.type';
import {
  MEASUREMENT_SCHEMA_KEYS,
  REGION_INTERNAL_KEYS,
  RegionInternal,
} from './observation.internal.type';

export const toRegionInternal = (region: RegionApi): RegionInternal => {
  const initRegion: RegionInternal = {
    uid: '',
    SOPInstanceUID: '',
    FrameOfReferenceUID: null,
    points: [],
    metadata: {},
    referenceSeriesUID: '',
    referenceStudyUID: '',
    toolName: '',
    displaySetInstanceUID: '',
    label: '',
    displayText: '',
    data: {},
    type: '',
  };
  Object.keys(region).forEach(key => {
    if (REGION_INTERNAL_KEYS.includes(key)) {
      initRegion[key] = region[key];
    }
  });
  let points = region.annotation.data.handles.points;
  const metadata = { ...region.annotation.metadata };
  if (!metadata.toolName) {
    if (points?.length === 2 || points?.length === 4) {
      metadata.toolName = 'GleamerRectangleTool';
    } else {
      metadata.toolName = 'GleamerKeypointTool';
    }
  }
  if (metadata.toolName === 'GleamerRectangleTool' && points.length === 2) {
    const [start, end] = region.annotation.data.handles.points;
    points = [
      [start[0], start[1], start[2]],
      [end[0], start[1], start[2]],
      [start[0], end[1], end[2]],
      [end[0], end[1], end[2]],
    ];
  }
  return {
    ...initRegion,
    data: { ...region.annotation.data, handles: { ...region.annotation.data.handles, points } },
    metadata,
    points: points,
    toolName: metadata.toolName,
    FrameOfReferenceUID: region.annotation?.metadata?.FrameOfReferenceUID,
    SOPInstanceUID: region.sopInstanceUID,
    referenceSeriesUID: region.seriesInstanceUID,
    referenceStudyUID: region.studyInstanceUID,
  };
};

export const toMeasurement = (region: RegionInternal): Partial<RegionInternal> => {
  const measurement = {};
  Object.keys(region).forEach(key => {
    if (MEASUREMENT_SCHEMA_KEYS.includes(key)) {
      measurement[key] = region[key];
    }
  });
  return measurement;
};
