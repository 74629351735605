import PropTypes from 'prop-types';
import React from 'react';
//
import getImageSrcFromImageId from './getImageSrcFromImageId';
import PanelStudyBrowser from './PanelStudyBrowser';
import getStudiesForPatientByStudyInstanceUID from './getStudiesForPatientByStudyInstanceUID';
import requestDisplaySetCreationForStudy from './requestDisplaySetCreationForStudy';

/**
 * Wraps the PanelStudyBrowser and provides features afforded by managers/services
 *
 * @param {object} params
 * @param {object} commandsManager
 * @param {object} extensionManager
 */
export function WrappedPanelStudyBrowser({
  commandsManager,
  extensionManager,
  servicesManager,
}) {
  // TODO: This should be made available a different way; route should have
  // already determined our datasource
  const dataSource = extensionManager.getDataSources()[0];
  const _getStudiesForPatientByStudyInstanceUID =
    getStudiesForPatientByStudyInstanceUID.bind(null, dataSource);

  const _getImageSrcFromImageId =
    _createGetImageSrcFromImageIdFn(extensionManager);
  const _requestDisplaySetCreationForStudy =
    requestDisplaySetCreationForStudy.bind(null, dataSource);

  return (
    <PanelStudyBrowser
      servicesManager={servicesManager}
      getStudiesForPatientByStudyInstanceUID={
        _getStudiesForPatientByStudyInstanceUID
      }
      dataSource={dataSource}
      getImageSrc={_getImageSrcFromImageId}
      requestDisplaySetCreationForStudy={_requestDisplaySetCreationForStudy}
    />
  );
}

/**
 * Grabs cornerstone library reference using a dependent command from
 * the @ohif/extension-cornerstone extension. Then creates a helper function
 * that can take an imageId and return an image src.
 *
 * @param {func} getCommand - CommandManager's getCommand method
 * @returns {func} getImageSrcFromImageId - A utility function powered by
 * cornerstone
 */
function _createGetImageSrcFromImageIdFn(extensionManager) {
  const utilities = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.common'
  );

  try {
    const { cornerstone } = utilities.exports.getCornerstoneLibraries();
    return getImageSrcFromImageId.bind(null, cornerstone);
  } catch (ex) {
    throw new Error('Required command not found');
  }
}

WrappedPanelStudyBrowser.propTypes = {
  commandsManager: PropTypes.object.isRequired,
  extensionManager: PropTypes.object.isRequired,
  servicesManager: PropTypes.object.isRequired,
};
