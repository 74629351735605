import React, { HTMLAttributes } from 'react';

export function CharacterisationButton({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLButtonElement> & { iconClassName?: string }) {
  return (
    <button className={className || 'self-start text-blue-300'} {...props}>
      {children}
    </button>
  );
}
