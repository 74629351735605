import {
  Enums,
  PanTool,
  StackScrollTool,
  WindowLevelTool,
  ZoomTool,
} from '@cornerstonejs/tools';
import { OHIF } from '@gleamer/types';

type SavedToolConfigurations = {
  bindings: OHIF.ToolConfigBinding[];
  toolName: string;
}[];

export function getMouseBindingsConfigsKey() {
  return `mouse-bindings-config`;
}

export function getDefaultMouseBindings(): SavedToolConfigurations {
  return [
    {
      toolName: ZoomTool.toolName,
      bindings: [{ mouseButton: Enums.MouseBindings.Auxiliary }],
    },
    {
      toolName: WindowLevelTool.toolName,
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Fourth_Button,
        },
        {
          mouseButton: Enums.MouseBindings.Primary,
          modifierKey: Enums.KeyboardBindings.Shift,
        },
      ],
    },
    {
      toolName: PanTool.toolName,
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Primary,
        },
      ],
    },
    {
      toolName: StackScrollTool.toolName,
      bindings: [],
    },
    {
      toolName: 'Annotation',
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary,
        },
      ],
    },
  ];
}

export function getMouseBindingsConfigs(): SavedToolConfigurations {
  const configKey = getMouseBindingsConfigsKey();
  const config = localStorage.getItem(configKey);
  if (config) {
    return JSON.parse(config);
  }
  return getDefaultMouseBindings();
}

export function getBindingsForTool(toolName: string) {
  const savedConfigs = getMouseBindingsConfigs();
  const toolConfig = savedConfigs.find(
    ({ toolName: name }) => name === toolName
  );
  return toolConfig ? toolConfig.bindings : [];
}
