import { notificationsApis } from '@gleamer/apis';
import { Api } from '@gleamer/types';
import { useUserAuthentication } from '@ohif/ui';
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';

const { fetchNotifications, markNotificationAsRead } = notificationsApis;

type Page<T> = Api.Page<T>;
type Notification = Api.Notification;
type InfinitePagedNotifications = InfiniteData<Page<Notification>>;

export function useLastNotifications() {
  // @ts-ignore wrong ohif typing
  const [_, UserAuthenticationService] = useUserAuthentication();

  return useQuery({
    queryKey: ['last-notifications'],
    queryFn: () =>
      fetchNotifications(UserAuthenticationService, {
        page: 0,
        size: 5,
        read: false,
      }),
  });
}

export function useInfiniteNotifications({ read } = { read: false }) {
  // @ts-ignore wrong ohif typing
  const [_, UserAuthenticationService] = useUserAuthentication();

  return useInfiniteQuery({
    queryKey: ['notifications', { read }],
    queryFn: ({ pageParam = 0 }) => {
      return fetchNotifications(UserAuthenticationService, {
        page: pageParam,
        size: 20,
        read,
      });
    },
    getNextPageParam: lastPage => {
      if (lastPage.last) {
        return null;
      }
      return lastPage.number + 1;
    },
  });
}

function updatePagedNotifications(
  pagedNotifications: InfinitePagedNotifications,
  notificationId: number
) {
  return produce(pagedNotifications, draft => {
    draft.pages.forEach(page => {
      page.content.forEach(notification => {
        if (notification.id === notificationId) {
          notification.read = true;
        }
      });
    });
  });
}

function onMutateRead({
  notificationId,
  read,
  queryClient,
}: {
  notificationId: number;
  read: boolean | undefined;
  queryClient: QueryClient;
}) {
  const previousNotifications =
    queryClient.getQueryData<InfinitePagedNotifications>([
      'notifications',
      { read },
    ]);

  if (previousNotifications) {
    const updated = updatePagedNotifications(
      previousNotifications,
      notificationId
    );
    queryClient.setQueryData(['notifications', { read }], updated);
  }

  return previousNotifications;
}

export function useMarkNotificationAsRead() {
  const queryClient = useQueryClient();
  // @ts-ignore wrong ohif typing
  const [_, UserAuthenticationService] = useUserAuthentication();

  return useMutation({
    mutationFn: (notificationId: number) =>
      markNotificationAsRead(UserAuthenticationService, notificationId),
    onMutate: async (notificationId: number) => {
      await queryClient.cancelQueries({
        queryKey: ['notifications'],
      });

      const previousNotifications = onMutateRead({
        notificationId,
        read: undefined,
        queryClient,
      });

      const previousUnreadNotifications = onMutateRead({
        notificationId,
        read: false,
        queryClient,
      });

      return {
        previousNotifications,
        previousUnreadNotifications,
      };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData(
        ['notifications', { read: undefined }],
        context.previousNotifications
      );

      queryClient.setQueryData(
        ['notifications', { read: true }],
        context.previousUnreadNotifications
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notifications'] });
      await queryClient.invalidateQueries({ queryKey: ['last-notifications'] });
    },
  });
}
