import { StartStop } from './StartStop';

export class ChannelListener<T> implements StartStop {
  private bc: BroadcastChannel | null = null;
  private readonly onEvent: (ev: T) => void;
  private readonly channel: string;
  public constructor(channel: string, onEvent: (ev: T) => void) {
    this.channel = channel;
    this.onEvent = onEvent;
  }
  public start(): void {
    if (!this.bc) {
      this.bc = new BroadcastChannel(this.channel);
      this.bc.onmessage = (ev: MessageEvent<T>) => {
        this.onEvent(ev.data);
      };
    }
  }
  public stop(): void {
    this.bc?.close();
    this.bc = null;
  }
}
