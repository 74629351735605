import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Internal } from '@gleamer/types';
import { AlertDialog } from '../atoms/AlertDialog';
type LabelTask = Internal.LabelTask;

type FinishedTaskAlertProps = {
  task: LabelTask;
};

export function FinishedTaskAlert({ task }: FinishedTaskAlertProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const onClose = () => {
    const finished = searchParams.has('finished');
    if (finished) {
      searchParams.delete('finished');
      setSearchParams(searchParams);
    }
  };

  return (
    <AlertDialog onClose={onClose}>
      <p className="text-base text-gray-900">
        You reviewed all items from the task:
        <br />
        {task.name} ({task.taskId})
      </p>
    </AlertDialog>
  );
}
