import { Api, Internal, OHIF } from '@gleamer/types';
import { fetchDicomJsonItemsBySha, fetchDicomJsonItemsLockedOrLabeledBy } from './dicom-json';
import { NoMoreItemsError } from './errors';

type UserAuthenticationService = OHIF.UserAuthenticationService;
type LabelTaskApiItem = Internal.LabelTaskApiItem;
type LabelTaskApiItemDetails = Api.LabelTaskApiItemDetails;

export async function fetchNextItem(
  UserAuthenticationService: UserAuthenticationService,
  task: number
): Promise<LabelTaskApiItem> {
  const response = await fetch(`/api/label-tasks/${task}/next-item`, {
    method: 'POST',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status === 204) {
    throw new NoMoreItemsError();
  }
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

export async function fetchItemDetails(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string
): Promise<LabelTaskApiItemDetails> {
  const response = await fetch(`/api/label-tasks/${taskId}/items/${itemId}/details`, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

export async function fetchItemReviewDetails(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string
): Promise<LabelTaskApiItemDetails> {
  const response = await fetch(`/api/label-tasks/${taskId}/items/${itemId}/review-details`, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

export async function fetchItem(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string
): Promise<LabelTaskApiItem> {
  const response = await fetch(`/api/label-tasks/${taskId}/items/${itemId}`, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

export async function fetchItemOrNextForCurrentUser(
  userAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId?: string
): Promise<LabelTaskApiItem> {
  const currentItem = itemId
    ? await fetchItem(userAuthenticationService, taskId, itemId)
    : await fetchNextItem(userAuthenticationService, taskId);

  const { reports, instances } = await fetchDicomJsonItemsLockedOrLabeledBy(
    userAuthenticationService,
    taskId,
    currentItem.id
  );

  currentItem.reports = reports;
  currentItem.instances = instances;

  return currentItem;
}

export async function fetchItemForLabeledBy(
  userAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string,
  labeledBy: string
): Promise<LabelTaskApiItem> {
  const itemDetails = await fetchItemDetails(userAuthenticationService, taskId, itemId);

  const labeledVersion = itemDetails.versionsLabeled.find(
    versionLabeled => versionLabeled.email === labeledBy
  );

  if (!labeledVersion) {
    throw new Error('Could not find a labeled version for this user');
  }

  const { version, taskRevision, lockedAt } = labeledVersion;
  const itemVersion = itemDetails.versions.find(vers => vers.number === version);

  if (!itemVersion) {
    throw new Error('Could not find related item version');
  }

  const { sha256 } = itemVersion;

  const { reports, instances } = await fetchDicomJsonItemsBySha(
    userAuthenticationService,
    taskId,
    itemId,
    sha256
  );

  return {
    id: itemId,
    taskId,
    version,
    taskRevision,
    reports,
    instances,
    lockedAt,
  };
}

export async function fetchItemVersion(
  userAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string,
  itemVersionNumber?: number
): Promise<LabelTaskApiItem> {
  const itemDetails = await fetchItemDetails(userAuthenticationService, taskId, itemId);

  let itemVersion;
  if (itemVersionNumber) {
    itemVersion = itemDetails.versions.find(
      versionLabeled => versionLabeled.number === itemVersionNumber
    );
  } else {
    // get version with max revision
    itemVersion = itemDetails.versions.reduce((prev, current) => {
      return prev.number > current.number ? prev : current;
    });
  }

  if (!itemVersion) {
    throw new Error('Could not find item with provided version');
  }

  const { sha256 } = itemVersion;

  const { reports, instances } = await fetchDicomJsonItemsBySha(
    userAuthenticationService,
    taskId,
    itemId,
    sha256
  );

  return {
    id: itemId,
    taskId,
    version: itemVersion.number,
    taskRevision: undefined,
    reports,
    instances,
    lockedAt: undefined,
  };
}

export async function fetchCurrentItemTaskRevision(
  userAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId?: string
): Promise<number> {
  const currentItem = itemId
    ? await fetchItem(userAuthenticationService, taskId, itemId)
    : await fetchNextItem(userAuthenticationService, taskId);

  return currentItem.taskRevision;
}

export async function fetchLabeledItemTaskRevision(
  userAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string,
  labeledBy: string
): Promise<number> {
  const itemDetails = await fetchItemDetails(userAuthenticationService, taskId, itemId);

  const labeledVersion = itemDetails.versionsLabeled.find(
    versionLabeled => versionLabeled.email === labeledBy
  );

  if (!labeledVersion) {
    throw new Error('Could not find a labeled version for this user');
  }

  return labeledVersion.taskRevision;
}
