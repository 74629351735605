import { Internal, OHIF } from '@gleamer/types';
import sortBy from 'lodash/sortBy';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { labelPass, useObservationSubmissions } from '@gleamer/ui';
import ObservationItem from './ObservationItem';
import classNames from 'classnames';
const { setLastUpdated } = labelPass.actions;

type ServicesManager = OHIF.ServicesManager;
type ObservationSubmission = Internal.ObservationInternal;

type ObservationsListProps = {
  servicesManager: ServicesManager;
};

function ObservationsList({ servicesManager }: ObservationsListProps) {
  const observations = useObservationSubmissions();
  const lastUpdatedObsId = useSelector(
    labelPass.selectors.getLastUpdatedObsUid
  );
  const dispatch = useDispatch();

  const onObservationClick = useCallback(
    (observation: ObservationSubmission) => {
      return () => {
        dispatch(setLastUpdated({ observation }));
      };
    },
    [dispatch]
  );

  if (observations.length === 0) {
    return (
      <div className="group flex grow cursor-default border border-transparent bg-black">
        <div className="flex items-center justify-between p-2 text-base text-primary-light">
          No observation
        </div>
      </div>
    );
  }

  return (
    <ul className="ohif-scrollbar max-h-full min-h-0 grow overflow-auto">
      {sortBy(observations, 'code').map(observation => (
        <li key={observation.uid}>
          <ObservationItem
            servicesManager={servicesManager}
            index={observation.index}
            observation={observation}
            open={observation.uid === lastUpdatedObsId}
            onObservationClick={onObservationClick(observation)}
          />
        </li>
      ))}
    </ul>
  );
}

type ObservationsProps = {
  servicesManager: ServicesManager;
  className?: string;
};

export function Observations({
  servicesManager,
  className,
}: ObservationsProps) {
  return (
    <div
      className={classNames(
        'observation-panel characterisation-panel flex flex-auto flex-col overflow-hidden',
        className
      )}
      data-testid="annotation-panel"
    >
      <div className="flex grow flex-col overflow-auto">
        <ObservationsList servicesManager={servicesManager} />
      </div>
    </div>
  );
}
