import { Internal } from '@gleamer/types';
import { isReadonly, labelPass } from '@gleamer/ui';
import Form, { FormProps } from '@rjsf/core';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import { defaultFormValidator } from '../../../../utils/validation.utils';

const { updateRegionCharacterisations } = labelPass.actions;

type Region = Internal.RegionInternal;

export type RegionCharacterisationFormProps = {
  jsonSchema: RJSFSchema;
  uiSchema: UiSchema;
  region: Region;
  observationUid: string;
};

export function RegionCharacterisationForm({
  jsonSchema,
  uiSchema,
  region,
  observationUid,
}: RegionCharacterisationFormProps) {
  const readOnly = isReadonly();
  const dispatch = useDispatch();

  const handleChange: FormProps<any>['onChange'] = event => {
    if (event.errors.length > 0) {
      return;
    }

    dispatch(
      updateRegionCharacterisations({
        observationUid,
        regionUid: region.uid,
        formData: event.formData,
      })
    );
  };

  return (
    <div className="text-white" data-testid="classification-form">
      <Form
        readonly={readOnly}
        schema={jsonSchema}
        uiSchema={{
          ...uiSchema,
          'ui:submitButtonOptions': {
            norender: true,
          },
        }}
        onChange={handleChange}
        formData={region.characterisation}
        validator={defaultFormValidator}
      />
    </div>
  );
}
