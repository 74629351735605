import { decompress } from 'lz-string';

export function loadFromCompressedLocalStorage(key: string) {
  let data = localStorage.getItem(key);
  let hasData = key && data !== null;

  // G3D-399: backward compatibility for labelPass storage
  // After this ticket, there should be store in labelPass-taskId-itemId-mode format
  // Whereas this was only labelPass-taskId-itemId before
  if (!hasData) {
    const previousKey = key.slice(0, key.lastIndexOf('-'));
    data = localStorage.getItem(previousKey);
    hasData = previousKey && data !== null;
  }

  if (hasData) {
    if (/^\{/.test(data)) {
      return JSON.parse(data);
    }
    // Load compressed data
    return JSON.parse(decompress(data));
  }

  return null;
}
