import classnames from 'classnames';
import React from 'react';
import { Internal } from '@gleamer/types';
import { ReportTitle } from '../ReportTitle';
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;

type ReportEntryProps = {
  report: EnhancedDicomJSONReport;
  onOpen: (report: EnhancedDicomJSONReport) => void;
  isDisplayed: boolean;
  activeStudyInstanceUID?: string;
};

export function ReportEntry({
  report,
  activeStudyInstanceUID,
  onOpen,
  isDisplayed,
}: ReportEntryProps) {
  const active =
    activeStudyInstanceUID &&
    activeStudyInstanceUID === report.StudyInstanceUID;

  return (
    <li className="mb-2 text-base">
      <div
        className={classnames(
          'flex items-center justify-start rounded-md my-2',
          {
            'bg-secondary-main': active,
            'border-[2px] border-primary-light px-[8px] py-[6px]': isDisplayed,
            'px-[10px] py-2': !isDisplayed,
          }
        )}
      >
        <button className="text-base mr-2" onClick={() => onOpen(report)}>
          <ReportTitle report={report} />
        </button>
      </div>
    </li>
  );
}
