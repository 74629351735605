import { useEffect, useRef } from 'react';

export function useReportsBroadcastChannel() {
  const bc = useRef(new BroadcastChannel('reports'));

  useEffect(() => {
    return () => {
      bc.current.close();
    };
  }, []);

  return bc.current;
}
