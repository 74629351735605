import { OHIF } from '@gleamer/types';
import { Typography } from '@ohif/ui';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ToolMouseBindings } from './ToolMouseBindings';

type ToolConfigBinding = OHIF.ToolConfigBinding;

export type MouseToolConfig = {
  toolName: string;
  bindings: ToolConfigBinding[];
};

export function MouseBindings({
  configs,
  onChange,
}: {
  configs: MouseToolConfig[];
  onChange: (toolName: string, bindings: ToolConfigBinding[]) => void;
}) {
  const { t } = useTranslation('UserPreferencesModal');
  return (
    <div className="grid grid-cols-2 w-full gap-x-6 gap-y-4">
      <Typography
        variant="subtitle"
        className="w-full text-right text-primary-light"
      >
        {t('Function')}
      </Typography>
      <Typography variant="subtitle" className="text-left text-primary-light">
        {t('Mouse binding')}
      </Typography>
      {configs.map(config => {
        return (
          <Fragment key={config.toolName}>
            <Typography
              variant="subtitle"
              className="whitespace-nowrap text-right h-8 flex items-center justify-end"
            >
              {config.toolName.replace(/([A-Z])/g, ' $1')}
            </Typography>
            <ToolMouseBindings
              toolName={config.toolName}
              className="flex flex-col gap-1"
              configs={configs}
              bindings={config.bindings}
              onChange={toolBindings => onChange(config.toolName, toolBindings)}
            />
          </Fragment>
        );
      })}
    </div>
  );
}
