import { CommandsManager, ExtensionManager, ServicesManager } from '@ohif/core';
import { MammoHangingProtocol } from './hanging-protocols/mammo';
import { generateMPRProtocols } from './hanging-protocols/mpr';
import { mipProtocolModule } from './hanging-protocols/mip';

export function getHangingProtocolModule({
  servicesManager,
  commandsManager,
  extensionManager,
}: {
  servicesManager: ServicesManager;
  commandsManager: CommandsManager;
  extensionManager: ExtensionManager;
}) {
  return [
    {
      id: '@gleamer/gdata-extension.hangingProtocolModule.mammo',
      name: '@gleamer/gdata-extension.hangingProtocolModule.mammo',
      protocol: MammoHangingProtocol,
    },
    mipProtocolModule,
    ...generateMPRProtocols().map(protocol => ({
      id: protocol.id,
      name: protocol.id,
      protocol,
    })),
  ];
}
