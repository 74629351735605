import { FolderIcon } from '@heroicons/react/24/outline';
import {
  ClipboardDocumentListIcon,
  FolderIcon as FolderIconSolid,
  PencilSquareIcon,
} from '@heroicons/react/24/solid';
import React from 'react';
import { Observations } from './components/organisms/panels/annotation/tabs/Observations';
import { ReportPanel } from './components/organisms/panels/report/ReportPanel';
import { WrappedPanelStudyBrowser } from './components/organisms/panels/StudyBrowser/WrappedPanelStudyBrowser';

export const REPORT_PANEL_NAME = 'right-panel-reports';
export const ANNOTATION_PANEL_NAME = 'right-panel-annotation';

export function getPanelModule({
  servicesManager,
  commandsManager,
  extensionManager,
}) {
  return [
    {
      id: ANNOTATION_PANEL_NAME,
      name: ANNOTATION_PANEL_NAME,
      icon: <PencilSquareIcon />,
      iconLabel: 'Annotation',
      label: 'Annotation',
      component: props => (
        <Observations servicesManager={servicesManager} {...props} />
      ),
    },
    {
      id: REPORT_PANEL_NAME,
      name: REPORT_PANEL_NAME,
      icon: <ClipboardDocumentListIcon />,
      iconLabel: 'Reports',
      label: 'Reports',
      component: props => (
        <ReportPanel servicesManager={servicesManager} {...props} />
      ),
    },
    {
      id: 'study-browser',
      name: 'study-browser',
      icon: <FolderIconSolid />,
      iconLabel: 'Studies',
      label: (
        <div className="flex items-center">
          <FolderIcon className="h-6 w-6" />
          <span className="ml-2"> Studies</span>
        </div>
      ),
      component: props => (
        <WrappedPanelStudyBrowser
          commandsManager={commandsManager}
          servicesManager={servicesManager}
          extensionManager={extensionManager}
          {...props}
        />
      ),
    },
  ];
}
