import { Api, Internal, OHIF } from '@gleamer/types';
import { Duration } from 'date-fns';
import { parse as parseDuration } from 'tinyduration';
import { SortingState } from '@tanstack/react-table';

type UserAuthenticationService = OHIF.UserAuthenticationService;

export const DEFAULT_LOGOUT_DELAY: Duration = { minutes: 2 };

type DicomJSONReport = Internal.DicomJSONReport;
type LabelTask = Internal.LabelTask;
type LabelTaskDetails = Internal.LabelTaskDetails;
type VolumeOrientation = Internal.VolumeOrientation;
type LabelTaskApi = Api.LabelTaskApi;
type Page<T> = Api.Page<T>;
type VolumeOrientationApi = Uppercase<Internal.VolumeOrientation>;

function toInternalOrientation(orientation: VolumeOrientationApi): VolumeOrientation {
  switch (orientation) {
    case 'AXIAL':
      return 'axial';
    case 'CORONAL':
      return 'coronal';
    case 'SAGITTAL':
      return 'sagittal';
    default:
      throw new Error(`Unknown orientation ${orientation}`);
  }
}

function taskApiToInternal<T = LabelTask>(taskApi: LabelTaskApi): T {
  const mprApi = taskApi?.layout?.mpr ?? {
    main: 'SAGITTAL',
    top: 'AXIAL',
    bottom: 'CORONAL',
  };

  const mpr: LabelTask['layout']['mpr'] = {
    main: toInternalOrientation(mprApi.main),
    top: toInternalOrientation(mprApi.top),
    bottom: toInternalOrientation(mprApi.bottom),
  };
  const logout = taskApi.logout ? parseDuration(taskApi.logout) : DEFAULT_LOGOUT_DELAY;
  return { ...taskApi, layout: { mpr }, logout } as T;
}

export async function fetchTask(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  taskRevision?: number
): Promise<LabelTask> {
  const taskUrl = taskRevision
    ? `/api/label-tasks/${taskId}/revisions/${taskRevision}`
    : `/api/label-tasks/${taskId}/revisions/last`;

  const response = await fetch(taskUrl, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status === 204) {
    throw new Error('No task found');
  }
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  const taskApi: LabelTaskApi = await response.json();
  return taskApiToInternal(taskApi);
}

export async function fetchObservations(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId?: string
): Promise<Api.ObservationsApi[]> {
  if (!itemId) {
    return Promise.resolve([]);
  }
  const taskUrl = `/api/label-tasks/${taskId}/items/${itemId}/observations`;

  const response = await fetch(taskUrl, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status >= 400) {
    console.error(`Error while fetching observations ${response.status} : ${response.statusText}`);
    return [];
  }
  const observationsApi: Api.ObservationsApi[] = await response.json();
  return observationsApi;
}

export async function fetchCharacterisations(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId?: string
): Promise<Api.CharacterisationApi[]> {
  if (!itemId) {
    return Promise.resolve([]);
  }
  const characterisationsUrl = `/api/label-tasks/${taskId}/items/${itemId}/characterisations`;

  const response = await fetch(characterisationsUrl, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status >= 400) {
    console.error(
      `Error while fetching characterizations ${response.status} : ${response.statusText}`
    );
    return [];
  }
  const characterisationsApi: Api.CharacterisationApi[] = await response.json();
  return characterisationsApi;
}

export async function fetchTaskDetails(
  UserAuthenticationService: UserAuthenticationService,
  taskId: number
): Promise<LabelTaskDetails> {
  const response = await fetch(`/api/label-tasks/${taskId}/details`, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status === 204) {
    throw new Error('No task found');
  }
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  const taskApi: LabelTaskApi = await response.json();
  return taskApiToInternal<LabelTaskDetails>(taskApi);
}

export async function fetchMyTasks(
  UserAuthenticationService,
  page = 0,
  sorting: SortingState
): Promise<Page<LabelTask>> {
  let url = `/api/label-tasks/me?page=${page}`;

  url = sorting.reduce((acc, sort) => {
    const order = sort.desc ? 'desc' : 'asc';
    return `${acc}&sort=${sort.id},${order}`;
  }, url);

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

export async function fetchReport(
  UserAuthenticationService: UserAuthenticationService,
  { url }: Pick<DicomJSONReport, 'url'>
): Promise<Blob> {
  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.blob();
}
