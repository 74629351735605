import { Types } from '@ohif/core';

const mipAxial = {
  viewportOptions: {
    viewportId: 'mipAxial',
    viewportType: 'volume',
    orientation: 'axial',
    toolGroupId: 'mipToolGroup',
  },
  displaySets: [
    {
      options: {
        blendMode: 'MIP',
        slabThickness: 10,
      },
      id: 'activeDisplaySet',
    },
  ],
};

const mipProtocol: Types.HangingProtocol.Protocol = {
  id: '@gleamer/gdata-extension.hangingProtocolModule.mip',
  protocolMatchingRules: [],
  callbacks: {
    onProtocolExit: [
      {
        commandName: 'onExitMIP',
        commandOptions: {},
      },
    ],
  },
  stages: [
    {
      name: 'mipStage',
      viewportStructure: {
        layoutType: 'grid',
        properties: {
          rows: 1,
          columns: 1,
        },
      },
      viewports: [mipAxial],
    },
  ],
  displaySetSelectors: {
    activeDisplaySet: {
      seriesMatchingRules: [
        {
          weight: 10,
          attribute: 'isReconstructable',
          constraint: {
            equals: {
              value: true,
            },
          },
          required: true,
        },
      ],
    },
  },
};

export const mipProtocolModule = {
  name: '@gleamer/gdata-extension.hangingProtocolModule.mip',
  protocol: mipProtocol,
};
