import { format as formatDate, isValid, parseISO } from 'date-fns';

export const tryParseDate = (date: string): Date | undefined => {
  try {
    return parseISO(date);
  } catch (e) {
    return undefined;
  }
};

export const displayDate = (date: Date): string => {
  if (isValid(date)) {
    return formatDate(date, 'dd-MMM-yyyy');
  }

  return undefined;
};
