import { Api } from '@gleamer/types';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import formatDistance from 'date-fns/formatDistance';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type NotificationProps = {
  notification: Api.Notification;
  onRead?: (id: number) => any;
  className?: string;
  variant?: 'default' | 'long';
};

function PassReviewedNotification({
  notification,
  onRead,
  className,
  variant = 'default',
}: NotificationProps) {
  const { taskId, itemId, comments = '' } = notification.payload;
  const navigate = useNavigate();

  const onLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onRead(notification.id);
    navigate(`/ohif/feedback?task=${taskId}&item=${itemId}`);
  };

  return (
    <div
      className={classNames(
        'flex items-center justify-between text-gray-800 hover:text-black',
        className
      )}
    >
      <button onClick={onLinkClick} className="max-w-[80%] text-left">
        {notification.text}
        <div className="text-sm text-gray-700">
          <div>
            Task {taskId} / Item {itemId}
          </div>
          {comments && (
            <div
              className={classNames('mb-2 mt-1', {
                truncate: variant === 'default',
              })}
              title={comments}
            >
              {comments}
            </div>
          )}
        </div>
      </button>
      <div className="grid grid-flow-col items-center space-x-4">
        {variant === 'long' && (
          <div className="text-sm text-gray-700">
            {formatDistance(new Date(notification.createdAt), new Date(), {
              addSuffix: true,
            })}
          </div>
        )}
        {notification.read ? (
          <div className="w-5" />
        ) : (
          <button
            title="Mark as read"
            onClick={() => onRead(notification.id)}
            className="w-5"
          >
            <CheckIcon className="h-5 text-gray-500 hover:text-green-800" />
          </button>
        )}
      </div>
    </div>
  );
}

export function Notification({
  notification,
  onRead,
  className,
  variant = 'default',
}: NotificationProps) {
  switch (notification.type) {
    case 'PASS_REVIEWED':
      return (
        <PassReviewedNotification
          notification={notification}
          onRead={onRead}
          className={className}
          variant={variant}
        />
      );
    default:
      return (
        <div className={classNames('text-gray-800', className)}>
          {notification.text}
        </div>
      );
  }
}
