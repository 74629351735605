import { Internal } from '@gleamer/types';
import { useTask } from '@gleamer/ui';
import React from 'react';
import { getRegionSpecification } from '../../../../../utils/region.utils';
import { RegionCharacterisationForm } from '../../../modals/CharacterisationForm/RegionCharacterisationForm';

type ObservationSubmission = Internal.ObservationInternal;
type Region = Internal.RegionInternal;

type RegionCharacterisationProps = {
  region: Region;
  observation: ObservationSubmission;
};

export function RegionCharacterisation({
  region,
  observation,
}: RegionCharacterisationProps) {
  const task = useTask();

  const observationSpec = task.observations.find(
    obs => obs.code === observation.code && obs.status === observation.status
  );

  if (!observationSpec) {
    return null;
  }

  const regionSpec = getRegionSpecification(region, observationSpec.regions);
  if (!regionSpec) {
    return null;
  }

  const { characterisation } = regionSpec;
  if (!characterisation?.json) {
    return null;
  }

  return (
    <RegionCharacterisationForm
      jsonSchema={characterisation.json}
      uiSchema={characterisation.ui}
      region={region}
      observationUid={observation.uid}
    />
  );
}
