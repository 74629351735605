import { Assets } from '@gleamer/types';
import { labelPass } from '@gleamer/ui';
import { ServicesManager } from '@ohif/core';
import Form, { FormProps } from '@rjsf/core';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import isEqual from 'lodash/isEqual';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { defaultFormValidator } from '../../../../utils/validation.utils';

type AssetRef = Assets.AssetRef;

type AssetCharacterisationFormProps = PropsWithChildren<{
  jsonSchema: RJSFSchema;
  uiSchema: UiSchema;
  asset: AssetRef;
  readOnly?: boolean;
  servicesManager: ServicesManager;
}>;

export function AssetCharacterisationForm({
  jsonSchema,
  uiSchema,
  asset,
  readOnly,
  children,
  servicesManager,
}: AssetCharacterisationFormProps) {
  // We directly use the Redux Store because the Form may be not under ReduxProvider
  const { ReduxStoreService } = servicesManager.services;

  const [characterisations, setCharacterisations] = useState(() => {
    return labelPass.selectors.getAssetCharacterisations(
      ReduxStoreService.getState()
    );
  });

  const updateCharacterisations = useCallback(
    (asset, formData) => {
      ReduxStoreService.dispatch(
        labelPass.actions.updateAssetCharacterisations({
          asset,
          formData,
        })
      );
    },
    [ReduxStoreService]
  );

  useEffect(() => {
    const unsubscribe = ReduxStoreService.subscribe(() => {
      setCharacterisations(
        labelPass.selectors.getAssetCharacterisations(
          ReduxStoreService.getState()
        )
      );
    });

    return unsubscribe;
  });

  const existingCharacterisation = characterisations.find(charac =>
    isEqual(charac.asset, asset)
  );

  const handleChange: FormProps<any>['onChange'] = event => {
    if (event.errors.length > 0) {
      return;
    }

    updateCharacterisations(asset, event.formData);
  };

  useEffect(() => {
    if (jsonSchema && !existingCharacterisation) {
      updateCharacterisations(asset, {});
    }
  }, [existingCharacterisation, updateCharacterisations, asset, jsonSchema]);

  if (!jsonSchema || !existingCharacterisation) {
    return null;
  }

  return (
    <div
      className="grid grid-cols-2 items-center text-white"
      data-testid="classification-form"
      id={`${asset.kind}-characterisation-form`}
    >
      <div>
        <Form
          readonly={readOnly}
          schema={jsonSchema}
          uiSchema={{
            ...uiSchema,
            'ui:submitButtonOptions': {
              norender: true,
            },
          }}
          onChange={handleChange}
          formData={existingCharacterisation.characterisation}
          validator={defaultFormValidator}
        />
      </div>
      <div>{children}</div>
    </div>
  );
}
