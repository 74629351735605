import { Types as ToolsTypes } from '@cornerstonejs/tools';
import GleamerKeypointTool from '../tools/GleamerKeypointTool';
import { OhifMeasurement } from './Rectangle';
import getSOPInstanceAttributes from './utils/getSOPInstanceAttributes';

export const Keypoint = {
  toAnnotation: measurement => {},
  toMeasurement: (
    csToolsEventDetail: ToolsTypes.EventTypes.AnnotationAddedEventDetail,
    DisplaySetService: any,
    CornerstoneViewportService: any,
    getValueTypeFromToolType: (toolType: string) => string
  ): OhifMeasurement => {
    const { annotation, viewportId } = csToolsEventDetail;
    const { metadata, data, annotationUID } = annotation;

    if (!metadata || !data) {
      console.warn('Keypoint tool: Missing metadata or data');
      return null;
    }

    const { toolName, referencedImageId, FrameOfReferenceUID } = metadata;
    const validToolType = toolName === GleamerKeypointTool.toolName;

    if (!validToolType) {
      throw new Error('Tool not supported');
    }

    const { SOPInstanceUID, SeriesInstanceUID, StudyInstanceUID } =
      getSOPInstanceAttributes(
        referencedImageId,
        CornerstoneViewportService,
        viewportId
      );

    let displaySet;

    if (SOPInstanceUID) {
      displaySet = DisplaySetService.getDisplaySetForSOPInstanceUID(
        SOPInstanceUID,
        SeriesInstanceUID
      );
    } else {
      displaySet = DisplaySetService.getDisplaySetsForSeries(SeriesInstanceUID);
    }

    const { points } = data.handles;

    return {
      uid: annotationUID,
      SOPInstanceUID,
      FrameOfReferenceUID,
      points,
      metadata,
      referenceSeriesUID: SeriesInstanceUID,
      referenceStudyUID: StudyInstanceUID,
      toolName: metadata.toolName,
      displaySetInstanceUID: displaySet.displaySetInstanceUID,
      label: data.label,
      data,
      type: getValueTypeFromToolType(toolName),
    };
  },
};
