import { ActionTypes } from 'redux-undo';
import * as labelPass from '../labelPass';
import { getItemLabelPassState } from '../labelPass/labelPass.selectors';
import * as tools from './toolsState.slice';

const matchingActions = [
  labelPass.actions.setLastUpdated,
  labelPass.actions.addObservation,
  labelPass.actions.setLastUpdatedByRegion,
  labelPass.actions.deleteObservation,
  labelPass.actions.deleteRegion,
  labelPass.actions.clear,
  labelPass.actions.setInitialLabelPass,
];

export const toolsMiddleware = store => next => action => {
  const result = next(action);

  if (
    matchingActions.some(matchingAction => matchingAction.match(action)) ||
    action.type === ActionTypes.UNDO ||
    action.type === ActionTypes.REDO
  ) {
    const { lastUpdatedObs, observations } = getItemLabelPassState(store.getState());

    if (lastUpdatedObs) {
      store.dispatch(
        tools.actions.setNextObs({
          code: lastUpdatedObs.code,
          status: lastUpdatedObs.status,
          index: lastUpdatedObs.index,
        })
      );
    } else if (observations.length === 0) {
      store.dispatch(tools.actions.clear());
    }
  }

  return result;
};
