import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export function PageWrapper({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return <div className={classNames('p-4', className)}>{children}</div>;
}
