import { Internal, OHIF } from '@gleamer/types';
import { Accordion } from '@gleamer/ui';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useCurrentDisplaySet } from '../../../../hooks/useCurrentDisplaySet';
import { ReportCharacterisationButton } from './ReportCharacterisationButton';
import { ReportDisplay } from './ReportDisplay';
import { ReportTitle } from './ReportTitle';

type ServicesManager = OHIF.ServicesManager;
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;
type LabelTask = Internal.LabelTask;

type ReportStudyEntryProps = {
  servicesManager: ServicesManager;
  report: EnhancedDicomJSONReport;
  fetchReport: (report: EnhancedDicomJSONReport) => Promise<Blob>;
  task?: LabelTask;
};

export function ReportStudyEntry({
  servicesManager,
  report,
  fetchReport,
  task,
}: ReportStudyEntryProps) {
  const { DisplaySetService } = servicesManager.services;

  const displaySet = useCurrentDisplaySet(DisplaySetService);
  const activeStudyInstanceUID: string | undefined =
    displaySet?.studyInstanceUid;

  const [opened, setOpened] = useState(
    report.StudyInstanceUID === activeStudyInstanceUID
  );

  const active = report.StudyInstanceUID === activeStudyInstanceUID;

  useEffect(() => {
    setOpened(report.StudyInstanceUID === activeStudyInstanceUID);
  }, [report.StudyInstanceUID, activeStudyInstanceUID]);

  const handleAccordionClick = () => {
    setOpened(state => !state);
  };

  return (
    <li className="mb-2 text-base">
      <Accordion open={opened} onClick={handleAccordionClick}>
        <div
          className={classnames(
            {
              'bg-secondary-main': active,
            },
            'flex items-center justify-start'
          )}
        >
          <Accordion.Title className="my-2 mr-2 text-base">
            <ReportTitle report={report} />
          </Accordion.Title>
          <ReportCharacterisationButton
            servicesManager={servicesManager}
            report={report}
          />
        </div>
        <Accordion.Content>
          <ReportDisplay
            report={report}
            fetchReport={fetchReport}
            task={task}
          />
        </Accordion.Content>
      </Accordion>
    </li>
  );
}
