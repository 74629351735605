import { annotation } from '@cornerstonejs/tools';
import { Internal, Mapper } from '@gleamer/types';
import { ServicesManager } from '@ohif/core';
import { cloneDeep } from 'lodash';

type ObservationSubmission = Internal.ObservationInternal;
type Region = Internal.RegionInternal;

export function drawRegion({
  servicesManager,
  observations,
  region: initialRegion,
}: {
  servicesManager: ServicesManager;
  observations: ObservationSubmission[];
  region: Region;
}): {
  initialRegion: Region;
  drawnRegion: Region | null;
} {
  const { measurementService, displaySetService, cornerstoneViewportService } =
    servicesManager.services;

  const observation = observations.find(obs =>
    obs.regions.some(region => region.uid)
  );
  if (!observation) {
    return { initialRegion, drawnRegion: null };
  }

  const updatedRegion = cloneDeep(initialRegion);

  const { color = 'red', label } = observation;
  const regionIndex = observation.regions.indexOf(initialRegion);

  const displaySet = displaySetService.getDisplaySetForSOPInstanceUID(
    initialRegion.SOPInstanceUID,
    initialRegion.referenceSeriesUID
  );
  if (!displaySet) {
    console.warn('DisplaySet is not ready');
    return { initialRegion, drawnRegion: null };
  }

  updatedRegion.displaySetInstanceUID = displaySet.displaySetInstanceUID;

  const annotationData = {
    handles: updatedRegion.data.handles,
    text: '',
    label: `${label}:${regionIndex + 1}`,
  };

  updatedRegion.uid = measurementService.addRawMeasurement(
    measurementService.getSource('Cornerstone3DTools', '0.1'),
    initialRegion.toolName,
    {
      ...updatedRegion.data,
      annotation: { data: annotationData },
      id: initialRegion.uid,
    },
    () => Mapper.ObservationMapper.toMeasurement(updatedRegion)
  );

  const annotationStyle = annotation.config.style;
  const { lineWidth } = annotationStyle.getDefaultToolStyles().global;

  const lineWidthHovered = String(parseFloat(lineWidth) * 2);
  annotationStyle.setAnnotationStyles(updatedRegion.uid, {
    color: color,
    colorActive: color,
    colorEnabled: color,
    colorHighlighted: color,
    colorSelected: color,
    lineWidthSelected: lineWidthHovered,
    lineWidthHighlighted: lineWidthHovered,
  });
  cornerstoneViewportService.getRenderingEngine().render();

  return { initialRegion, drawnRegion: updatedRegion };
}
