import {
  eventTarget,
  getEnabledElement,
  triggerEvent,
  Types,
  utilities as csUtils,
  VolumeViewport,
} from '@cornerstonejs/core';
import {
  annotation,
  cursors,
  drawing,
  Enums,
  RectangleROITool,
  state,
  Types as ToolsTypes,
  utilities as toolsUtils,
} from '@cornerstonejs/tools';
import { vec2 } from 'gl-matrix';
import merge from 'lodash/merge';
import getAnnotations from './utils/getAnnotations';

type RectangleROIAnnotation =
  ToolsTypes.ToolSpecificAnnotationTypes.RectangleROIAnnotation;
type AnnotationCompletedEventDetail =
  ToolsTypes.EventTypes.AnnotationCompletedEventDetail;
type StyleSpecifier = ToolsTypes.AnnotationStyle.StyleSpecifier;
type SVGDrawingHelper = ToolsTypes.SVGDrawingHelper;

const { addAnnotation, removeAnnotation } = annotation.state;
const { isAnnotationVisible } = annotation.visibility;
const { isAnnotationLocked } = annotation.locking;
const {
  selection: { deselectAnnotation },
} = annotation;
const {
  drawHandles: drawHandlesSvg,
  drawRect: drawRectSvg,
  drawTextBox,
} = drawing;
const { hideElementCursor, resetElementCursor } = cursors.elementCursor;
const {
  triggerAnnotationRenderForViewportIds,
  viewportFilters: { getViewportIdsWithToolToRender },
} = toolsUtils;
const { transformWorldToIndex } = csUtils;

function computeTextBoxCoordinates(coordinates: Types.Point2[]): Types.Point2 {
  return [
    Math.min(...coordinates.map(coord => coord[0])),
    Math.min(...coordinates.map(coord => coord[1])) - 22,
  ];
}

export const ANNOTATION_DRAWING_MISSCLICK =
  'CORNERSTONE_TOOLS_ANNOTATION_DRAWING_MISSCLICK';

export const ANNOTATION_DRAWING_FORBIDDEN =
  'CORNERSTONE_TOOLS_ANNOTATION_DRAWING_FORBIDDEN';

export const ANNOTATION_UPDATED = 'CORNERSTONE_TOOLS_ANNOTATION_UPDATED';

const REGION_MIN_SIZE = 5;

class GleamerRectangleTool extends RectangleROITool {
  static toolName;

  editData: {
    annotation: any;
    viewportIdsToRender: string[];
    handleIndex?: number;
    movingTextBox?: boolean;
    newAnnotation?: boolean;
    hasMoved?: boolean;
  } | null;
  isDrawing: boolean;

  constructor(
    toolProps: ToolsTypes.PublicToolProps = {},
    defaultToolProps: ToolsTypes.ToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        preventHandleOutsideImage: true,
      },
    }
  ) {
    super(merge({}, defaultToolProps, toolProps));
  }

  /**
   * Based on the current position of the mouse and the current imageId to create
   * a RectangleROI Annotation and stores it in the annotationManager
   *
   * @param evt -  EventTypes.NormalizedMouseEventType
   * @returns The annotation object.
   *
   */
  addNewAnnotation = (
    evt: ToolsTypes.EventTypes.InteractionEventType
  ): RectangleROIAnnotation => {
    const eventDetail = evt.detail;
    const { currentPoints, element } = eventDetail;
    const worldPos = currentPoints.world;

    const enabledElement = getEnabledElement(element);
    const { viewport, renderingEngine } = enabledElement;

    this.isDrawing = true;

    const camera = viewport.getCamera();
    const { viewPlaneNormal, viewUp } = camera;

    const referencedImageId = this.getReferencedImageId(
      viewport,
      worldPos,
      viewPlaneNormal,
      viewUp
    );

    const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

    const annotation: RectangleROIAnnotation = {
      invalidated: true,
      highlighted: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: [...viewPlaneNormal] as Types.Point3,
        viewUp: [...viewUp] as Types.Point3,
        FrameOfReferenceUID,
        referencedImageId,
      },
      data: {
        label: '',
        handles: {
          points: [
            [...worldPos] as Types.Point3,
            [...worldPos] as Types.Point3,
            [...worldPos] as Types.Point3,
            [...worldPos] as Types.Point3,
          ],
          activeHandleIndex: null,
          // missing textBox
        },
        cachedStats: {},
      },
    } as RectangleROIAnnotation;

    addAnnotation(annotation, element);

    const viewportIdsToRender = getViewportIdsWithToolToRender(
      element,
      this.getToolName()
    );

    this.editData = {
      annotation,
      viewportIdsToRender,
      handleIndex: 3,
      newAnnotation: true,
      hasMoved: false,
    };
    this._activateDraw(element);

    hideElementCursor(element);

    evt.preventDefault();

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    return annotation;
  };

  handleSelectedCallback = (
    evt: ToolsTypes.EventTypes.MouseDownEventType,
    annotation: RectangleROIAnnotation,
    handle: ToolsTypes.ToolHandle,
    interactionType = 'mouse'
  ): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;
    const { data } = annotation;

    annotation.highlighted = true;

    const handleIndex = data.handles.points.findIndex(p => p === handle);

    // Find viewports to render on drag.
    const viewportIdsToRender = getViewportIdsWithToolToRender(
      element,
      this.getToolName()
    );

    this.editData = {
      annotation,
      viewportIdsToRender,
      handleIndex,
    };
    this._activateModify(element);

    hideElementCursor(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    evt.preventDefault();
  };

  _mouseUpCallback = (
    evt:
      | ToolsTypes.EventTypes.MouseUpEventType
      | ToolsTypes.EventTypes.MouseClickEventType
  ) => {
    const { element } = evt.detail;

    const { annotation, viewportIdsToRender, newAnnotation } = this.editData;
    const { data } = annotation;

    deselectAnnotation(annotation.annotationUID);
    annotation.highlighted = false;
    data.handles.activeHandleIndex = null;

    this._deactivateModify(element);
    this._deactivateDraw(element);

    resetElementCursor(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    this.editData = null;
    this.isDrawing = false;

    if (
      this._isHandleOutsideImage(data, enabledElement, renderingEngine) &&
      this.configuration.preventHandleOutsideImage
    ) {
      const uid = annotation.annotationUID;
      triggerEvent(eventTarget, ANNOTATION_DRAWING_FORBIDDEN, {
        regionUID: uid,
      });
      removeAnnotation(uid);
    } else if (newAnnotation) {
      const hasMovedEnough = this.cursorHasMovedEnough(evt.detail);
      if (!hasMovedEnough) {
        const uid = annotation.annotationUID;
        triggerEvent(eventTarget, ANNOTATION_DRAWING_MISSCLICK, {
          regionUID: uid,
        });
        removeAnnotation(uid);
      } else {
        const eventType = Enums.Events.ANNOTATION_COMPLETED;

        const eventDetail: AnnotationCompletedEventDetail = {
          annotation,
        };

        triggerEvent(eventTarget, eventType, eventDetail);
      }
    } else {
      const eventType = ANNOTATION_UPDATED;
      const eventDetail = { annotation };
      triggerEvent(eventTarget, eventType, eventDetail);
    }

    renderingEngine.renderViewports(viewportIdsToRender);
  };

  private cursorHasMovedEnough(eventDetail) {
    return (
      Math.abs(
        eventDetail.startPoints.canvas[0] - eventDetail.lastPoints.canvas[0]
      ) > REGION_MIN_SIZE &&
      Math.abs(
        eventDetail.startPoints.canvas[1] - eventDetail.lastPoints.canvas[1]
      ) > REGION_MIN_SIZE
    );
  }

  _mouseDragCallback = (
    evt:
      | ToolsTypes.EventTypes.MouseMoveEventType
      | ToolsTypes.EventTypes.MouseDragEventType
  ) => {
    this.isDrawing = true;

    const eventDetail = evt.detail;
    const { element } = eventDetail;

    const { annotation, viewportIdsToRender, handleIndex } = this.editData;
    const { data } = annotation;

    if (handleIndex === undefined) {
      // Drag mode - Moving tool, so move all points by the world points delta
      const { deltaPoints } =
        eventDetail as ToolsTypes.EventTypes.MouseDragEventDetail;
      const worldPosDelta = deltaPoints.world;

      const { points } = data.handles;

      points.forEach(point => {
        point[0] += worldPosDelta[0];
        point[1] += worldPosDelta[1];
        point[2] += worldPosDelta[2];
      });
      annotation.invalidated = true;
    } else {
      // Moving handle.
      const { currentPoints } = eventDetail;
      const enabledElement = getEnabledElement(element);
      const { worldToCanvas, canvasToWorld } = enabledElement.viewport;
      const worldPos = currentPoints.world;

      const { points } = data.handles;

      // Move this handle.
      points[handleIndex] = [...worldPos];

      let bottomLeftCanvas;
      let bottomRightCanvas;
      let topLeftCanvas;
      let topRightCanvas;

      let bottomLeftWorld;
      let bottomRightWorld;
      let topLeftWorld;
      let topRightWorld;

      switch (handleIndex) {
        case 0:
        case 3:
          // Moving bottomLeft or topRight

          bottomLeftCanvas = worldToCanvas(points[0]);
          topRightCanvas = worldToCanvas(points[3]);

          bottomRightCanvas = [topRightCanvas[0], bottomLeftCanvas[1]];
          topLeftCanvas = [bottomLeftCanvas[0], topRightCanvas[1]];

          bottomRightWorld = canvasToWorld(bottomRightCanvas);
          topLeftWorld = canvasToWorld(topLeftCanvas);

          points[1] = bottomRightWorld;
          points[2] = topLeftWorld;

          break;
        case 1:
        case 2:
          // Moving bottomRight or topLeft
          bottomRightCanvas = worldToCanvas(points[1]);
          topLeftCanvas = worldToCanvas(points[2]);

          bottomLeftCanvas = [
            topLeftCanvas[0],
            bottomRightCanvas[1],
          ] as Types.Point2;
          topRightCanvas = [
            bottomRightCanvas[0],
            topLeftCanvas[1],
          ] as Types.Point2;

          bottomLeftWorld = canvasToWorld(bottomLeftCanvas);
          topRightWorld = canvasToWorld(topRightCanvas);

          points[0] = bottomLeftWorld;
          points[3] = topRightWorld;

          break;
      }
      annotation.invalidated = true;
    }

    this.editData.hasMoved = true;

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
  };

  cancel = (element: HTMLDivElement) => {
    // If it is mid-draw or mid-modify
    if (this.isDrawing) {
      this.isDrawing = false;
      this._deactivateDraw(element);
      this._deactivateModify(element);
      resetElementCursor(element);

      const { annotation, viewportIdsToRender, newAnnotation } = this.editData;

      const { data } = annotation;

      annotation.highlighted = false;
      data.handles.activeHandleIndex = null;

      const enabledElement = getEnabledElement(element);
      const { renderingEngine } = enabledElement;

      triggerAnnotationRenderForViewportIds(
        renderingEngine,
        viewportIdsToRender
      );

      if (newAnnotation) {
        const eventType = Enums.Events.ANNOTATION_COMPLETED;

        const eventDetail: AnnotationCompletedEventDetail = {
          annotation,
        };

        triggerEvent(eventTarget, eventType, eventDetail);
      } else {
        const eventType = ANNOTATION_UPDATED;
        const eventDetail = { annotation };
        triggerEvent(eventTarget, eventType, eventDetail);
      }

      this.editData = null;
      return annotation.annotationUID;
    }
  };
  /**
   * Add event handlers for the modify event loop, and prevent default event prapogation.
   */
  _activateDraw = element => {
    state.isInteractingWithTool = true;

    element.addEventListener(Enums.Events.MOUSE_UP, this._mouseUpCallback);
    element.addEventListener(Enums.Events.MOUSE_DRAG, this._mouseDragCallback);
    element.addEventListener(Enums.Events.MOUSE_MOVE, this._mouseDragCallback);
    element.addEventListener(Enums.Events.MOUSE_CLICK, this._mouseUpCallback);
  };

  /**
   * Add event handlers for the modify event loop, and prevent default event prapogation.
   */
  _deactivateDraw = element => {
    state.isInteractingWithTool = false;

    element.removeEventListener(Enums.Events.MOUSE_UP, this._mouseUpCallback);
    element.removeEventListener(
      Enums.Events.MOUSE_DRAG,
      this._mouseDragCallback
    );
    element.removeEventListener(
      Enums.Events.MOUSE_MOVE,
      this._mouseDragCallback
    );
    element.removeEventListener(
      Enums.Events.MOUSE_CLICK,
      this._mouseUpCallback
    );
  };

  /**
   * Add event handlers for the modify event loop, and prevent default event prapogation.
   */
  _activateModify = element => {
    state.isInteractingWithTool = true;

    element.addEventListener(Enums.Events.MOUSE_UP, this._mouseUpCallback);
    element.addEventListener(Enums.Events.MOUSE_DRAG, this._mouseDragCallback);
    element.addEventListener(Enums.Events.MOUSE_CLICK, this._mouseUpCallback);
  };

  /**
   * Remove event handlers for the modify event loop, and enable default event propagation.
   */
  _deactivateModify = element => {
    state.isInteractingWithTool = false;

    element.removeEventListener(Enums.Events.MOUSE_UP, this._mouseUpCallback);
    element.removeEventListener(
      Enums.Events.MOUSE_DRAG,
      this._mouseDragCallback
    );
    element.removeEventListener(
      Enums.Events.MOUSE_CLICK,
      this._mouseUpCallback
    );
  };

  /**
   * it is used to draw the rectangleROI annotation in each
   * request animation frame.
   *
   * @param enabledElement - The Cornerstone's enabledElement.
   * @param svgDrawingHelper - The svgDrawingHelper providing the context for drawing.
   */
  renderAnnotation = (
    enabledElement: Types.IEnabledElement,
    svgDrawingHelper: SVGDrawingHelper
  ): boolean => {
    let renderStatus = false;
    const { viewport } = enabledElement;
    const { element } = viewport;

    let annotations = getAnnotations(this.getToolName(), element);

    if (!annotations?.length) {
      return renderStatus;
    }

    annotations = this.filterInteractableAnnotationsForElement(
      element,
      annotations
    );

    if (!annotations?.length) {
      return renderStatus;
    }

    const targetId = this.getTargetId(viewport);
    const renderingEngine = viewport.getRenderingEngine();

    const styleSpecifier: StyleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i] as RectangleROIAnnotation;
      const { annotationUID, data } = annotation;
      const { points, activeHandleIndex } = data.handles;
      const canvasCoordinates = points.map(p => viewport.worldToCanvas(p));

      styleSpecifier.annotationUID = annotationUID;

      const lineWidth = this.getStyle('lineWidth', styleSpecifier, annotation);
      const lineDash = this.getStyle('lineDash', styleSpecifier, annotation);
      const color = this.getStyle('color', styleSpecifier, annotation);

      const { viewPlaneNormal, viewUp } = viewport.getCamera();

      // If cachedStats does not exist, or the unit is missing (as part of import/hydration etc.),
      // force to recalculate the stats from the points
      if (
        !data.cachedStats[targetId] ||
        data.cachedStats[targetId].areaUnit == null
      ) {
        data.cachedStats[targetId] = {
          Modality: null,
          area: null,
          max: null,
          mean: null,
          stdDev: null,
          areaUnit: null,
        };

        this._calculateCachedStats(
          annotation,
          viewPlaneNormal,
          viewUp,
          renderingEngine,
          enabledElement
        );
      } else if (annotation.invalidated) {
        this._throttledCalculateCachedStats(
          annotation,
          viewPlaneNormal,
          viewUp,
          renderingEngine,
          enabledElement
        );

        // If the invalidated data is as a result of volumeViewport manipulation
        // of the tools, we need to invalidate the related stackViewports data if
        // they are not at the referencedImageId, so that
        // when scrolling to the related slice in which the tool were manipulated
        // we re-render the correct tool position. This is due to stackViewport
        // which doesn't have the full volume at each time, and we are only working
        // on one slice at a time.
        if (viewport instanceof VolumeViewport) {
          const { referencedImageId } = annotation.metadata;

          // invalidate all the relevant stackViewports if they are not
          // at the referencedImageId
          for (const targetId in data.cachedStats) {
            if (targetId.startsWith('imageId')) {
              const viewports = renderingEngine.getStackViewports();

              const invalidatedStack = viewports.find(vp => {
                // The stack viewport that contains the imageId but is not
                // showing it currently
                const referencedImageURI =
                  csUtils.imageIdToURI(referencedImageId);
                const hasImageURI = vp.hasImageURI(referencedImageURI);
                const currentImageURI = csUtils.imageIdToURI(
                  vp.getCurrentImageId()
                );
                return hasImageURI && currentImageURI !== referencedImageURI;
              });

              if (invalidatedStack) {
                delete data.cachedStats[targetId];
              }
            }
          }
        }
      }

      // If rendering engine has been destroyed while rendering
      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');
        return renderStatus;
      }

      let activeHandleCanvasCoords;

      if (!isAnnotationVisible(annotationUID)) {
        continue;
      }

      if (
        !isAnnotationLocked(annotation) &&
        !this.editData &&
        activeHandleIndex !== null
      ) {
        // Not locked or creating and hovering over handle, so render handle.
        activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
      }

      if (activeHandleCanvasCoords) {
        const handleGroupUID = '0';

        drawHandlesSvg(
          svgDrawingHelper,
          annotationUID,
          handleGroupUID,
          activeHandleCanvasCoords,
          {
            color,
          }
        );
      }

      const rectangleUID = '0';
      drawRectSvg(
        svgDrawingHelper,
        annotationUID,
        rectangleUID,
        canvasCoordinates[0],
        canvasCoordinates[3],
        {
          color,
          lineDash,
          lineWidth,
        }
      );

      const textBoxFontSize = this.getStyle(
        'textBoxFontSize',
        styleSpecifier,
        annotation
      );

      if (textBoxFontSize === '1') {
        drawTextBox(
          svgDrawingHelper,
          annotationUID,
          '0',
          [annotation.data.label],
          computeTextBoxCoordinates(canvasCoordinates),
          {
            padding: 0,
            background: 'rgba(0,0,0,0.5)',
            color: 'rgb(255,255,255)',
          }
        );
      }

      renderStatus = true;
    }

    return renderStatus;
  };

  getHandleNearImagePoint(
    element: HTMLDivElement,
    annotation: ToolsTypes.Annotation,
    canvasCoords: Types.Point2,
    proximity: number
  ): ToolsTypes.ToolHandle | undefined {
    const enabledElement = getEnabledElement(element);
    const { viewport } = enabledElement;

    const { data } = annotation;
    const { points } = data.handles;

    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const annotationCanvasCoordinate = viewport.worldToCanvas(point);

      const near =
        vec2.distance(canvasCoords, annotationCanvasCoordinate) < proximity;

      if (near === true) {
        data.handles.activeHandleIndex = i;
        return point;
      }
    }

    data.handles.activeHandleIndex = null;
  }

  _isHandleOutsideImage(data, enabledElement, renderingEngine) {
    const worldPos1 = data.handles.points[0];
    const worldPos2 = data.handles.points[3];

    const targetId = this.getTargetId(enabledElement.viewport);
    const image = this.getTargetIdImage(targetId, renderingEngine);
    const { dimensions, imageData } = image;

    const worldPos1Index = transformWorldToIndex(imageData, worldPos1);
    const worldPos2Index = transformWorldToIndex(imageData, worldPos2);

    return !this._isInsideVolume(worldPos1Index, worldPos2Index, dimensions);
  }
}

GleamerRectangleTool.toolName = 'GleamerRectangleTool';
export default GleamerRectangleTool;
