import React from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../atoms/PageTitle';
import { PageWrapper } from '../atoms/PageWrapper';

export function Home() {
  return (
    <PageWrapper className="w-full">
      <PageTitle title="Home" />
      <ul>
        <li>
          <Link to="/my-label-tasks">Go to your tasks</Link>
        </li>
        <li>
          <Link to="/notifications">Go to your notifications</Link>
        </li>
        <li>
          <Link to="/ohif/local">View local files</Link>
        </li>
      </ul>
    </PageWrapper>
  );
}
