import { AssetCharacterisationInternal } from './internal';

export type AssetKind =
  | 'patient'
  | 'study'
  | 'series'
  | 'instance'
  | 'region'
  | 'patientReport'
  | 'studyReport';

interface BaseAsset {
  kind: AssetKind;
}

export interface PatientAssetRef extends BaseAsset {
  patientId: string;
}

export interface StudyAssetRef extends PatientAssetRef {
  studyInstanceUID: string;
}

export interface SeriesAssetRef extends StudyAssetRef {
  seriesInstanceUID: string;
}

export interface InstanceAssetRef extends SeriesAssetRef {
  sopInstanceUID: string;
}

export interface PatientReportAssetRef extends BaseAsset {
  patientId: string;
  id: string;
}

export interface StudyReportAssetRef extends PatientReportAssetRef {
  studyInstanceUID: string;
}

export type AssetRef =
  | PatientAssetRef
  | StudyAssetRef
  | SeriesAssetRef
  | InstanceAssetRef
  | PatientReportAssetRef
  | StudyReportAssetRef;

export function groupAndFilterCharacterisationsByLevel(
  asset: InstanceAssetRef,
  characterisations: AssetCharacterisationInternal[] = []
) {
  const relatedCharacterisations = characterisations.filter(characterisations =>
    isRelatedToInstance(characterisations.asset, asset)
  );

  return {
    patient:
      relatedCharacterisations.find(characterisations => isPatientAssetRef(characterisations.asset))
        ?.characterisation || {},
    study:
      relatedCharacterisations.find(characterisations => isStudyAssetRef(characterisations.asset))
        ?.characterisation || {},
    series:
      relatedCharacterisations.find(characterisations => isSeriesAssetRef(characterisations.asset))
        ?.characterisation || {},
    instance:
      relatedCharacterisations.find(characterisations =>
        isInstanceAssetRef(characterisations.asset)
      )?.characterisation || {},
  };
}

export function isPatientAssetRef(asset: AssetRef): asset is PatientAssetRef {
  return asset.kind === 'patient';
}

export function isStudyAssetRef(asset: AssetRef): asset is StudyAssetRef {
  return asset.kind === 'study';
}

export function isSeriesAssetRef(asset: AssetRef): asset is SeriesAssetRef {
  return asset.kind === 'series';
}

export function isInstanceAssetRef(asset: AssetRef): asset is InstanceAssetRef {
  return asset.kind === 'instance';
}

export function isPatientReportAssetRef(asset: AssetRef): asset is PatientReportAssetRef {
  return asset.kind === 'patientReport';
}

export function isStudyReportAssetRef(asset: AssetRef): asset is StudyReportAssetRef {
  return asset.kind === 'studyReport';
}

export function getAssetKind(asset: AssetRef): AssetKind {
  return asset.kind;
}

export function isRelatedToInstance(asset: AssetRef, instance: InstanceAssetRef) {
  if (isInstanceAssetRef(asset)) {
    return (
      asset.patientId === instance.patientId &&
      asset.studyInstanceUID === instance.studyInstanceUID &&
      asset.seriesInstanceUID === instance.seriesInstanceUID &&
      asset.sopInstanceUID === instance.sopInstanceUID
    );
  }
  if (isSeriesAssetRef(asset)) {
    return (
      asset.patientId === instance.patientId &&
      asset.studyInstanceUID === instance.studyInstanceUID &&
      asset.seriesInstanceUID === instance.seriesInstanceUID
    );
  }
  if (isStudyAssetRef(asset)) {
    return (
      asset.patientId === instance.patientId && asset.studyInstanceUID === instance.studyInstanceUID
    );
  }
  if (isPatientAssetRef(asset)) {
    return true;
  }
}

export function getRelatedAsset(
  { patientId, studyInstanceUID, seriesInstanceUID, sopInstanceUID }: InstanceAssetRef,
  kind: AssetKind
): AssetRef {
  if (kind === 'patient') {
    return { kind: 'patient', patientId };
  }
  if (kind === 'study') {
    return {
      kind,
      patientId,
      studyInstanceUID,
    };
  }
  if (kind === 'series') {
    return {
      kind,
      patientId,
      studyInstanceUID,
      seriesInstanceUID,
    };
  }
  if (kind === 'instance') {
    return {
      kind: 'instance',
      patientId,
      studyInstanceUID,
      seriesInstanceUID,
      sopInstanceUID,
    };
  }
}
