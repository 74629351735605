import classnames from 'classnames';
import React, { TdHTMLAttributes, ThHTMLAttributes } from 'react';

export function TableHeadTh({
  className,
  children,
  ...props
}: ThHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th
      className={classnames(
        'border-b border-gray-500 font-medium p-4 text-black text-left',
        className
      )}
      {...props}
    >
      {children}
    </th>
  );
}

export function TableBodyTd({
  className,
  children,
  ...props
}: TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td
      className={classnames(
        'border-b border-gray-100 p-4 pl-8 text-gray-800 group-hover:bg-gray-200',
        className
      )}
      {...props}
    >
      {children}
    </td>
  );
}
