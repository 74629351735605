import { Api, OHIF } from '@gleamer/types';

type UserAuthenticationService = OHIF.UserAuthenticationService;

type Page<T> = Api.Page<T>;
type Notification = Api.Notification;

export async function fetchNotifications(
  UserAuthenticationService: UserAuthenticationService,
  { page = 0, size = 20, read }: { page?: number; size?: number; read?: boolean | undefined }
): Promise<Page<Notification>> {
  const searchParams = new URLSearchParams();
  searchParams.set('page', page.toString());
  searchParams.set('size', size.toString());
  searchParams.set('sort', 'createdAt,desc');
  if (read !== undefined) {
    searchParams.set('read', read.toString());
  }

  const response = await fetch(`/api/notifications?${searchParams.toString()}`, {
    method: 'GET',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response.json();
}

export async function markNotificationAsRead(
  UserAuthenticationService: UserAuthenticationService,
  notificationId: number
) {
  const response = await fetch(`/api/notifications/${notificationId}/read`, {
    method: 'PATCH',
    headers: new Headers(UserAuthenticationService.getAuthorizationHeader()),
  });

  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
  return response;
}
