import { Assets, Internal } from '@gleamer/types';
import { isReadonly, labelPass } from '@gleamer/ui';
import { ServicesManager } from '@ohif/core';
import Form, { FormProps } from '@rjsf/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentDisplaySet } from '../../../../hooks/useCurrentDisplaySet';
import { useCurrentImageIndex } from '../../../../hooks/useCurrentImageIndex';
import { defaultFormValidator } from '../../../../utils/validation.utils';

type AssetCharacterisationSpecifications =
  Internal.AssetCharacterisationSpecifications;
type AssetCharacterisationSubmission = Internal.AssetCharacterisationInternal;
type ObservationSubmission = Internal.ObservationInternal;

type AssetKind = Assets.AssetKind;
type AssetRef = Assets.AssetRef;
type InstanceAssetRef = Assets.InstanceAssetRef;

const { updateObservationCharacterisations } = labelPass.actions;
const { getRelatedAsset, groupAndFilterCharacterisationsByLevel } = Assets;

type ObservationCharacterisationFormProps = {
  characterisations: AssetCharacterisationSpecifications;
  observation: ObservationSubmission;
  servicesManager: ServicesManager;
};
export function ObservationCharacterisationForm({
  characterisations,
  observation,
  servicesManager,
}: ObservationCharacterisationFormProps) {
  const readOnly = isReadonly();
  const { displaySetService } = servicesManager.services;

  const displaySet = useCurrentDisplaySet(displaySetService);
  const imageIndex = useCurrentImageIndex(servicesManager);
  const dispatch = useDispatch();

  if (!displaySet || !displaySet.images?.[imageIndex]) {
    return null;
  }

  const image = displaySet.images[imageIndex];
  const { SOPInstanceUID, SeriesInstanceUID, StudyInstanceUID, PatientID } =
    image;

  const asset: InstanceAssetRef = {
    kind: 'instance',
    patientId: PatientID,
    sopInstanceUID: SOPInstanceUID,
    seriesInstanceUID: SeriesInstanceUID,
    studyInstanceUID: StudyInstanceUID,
  };

  const assetKinds = ['patient', 'study', 'series', 'instance'] as AssetKind[];

  if (Object.values(characterisations).every(val => !val?.json)) {
    return null;
  }

  const formData = getFormDataFor(asset, observation.characterisations);

  type HandleChange = (asset: AssetRef) => FormProps<any>['onChange'];
  const handleChange: HandleChange = assetRef => event => {
    if (event.errors.length > 0) {
      return;
    }

    dispatch(
      updateObservationCharacterisations({
        observationUid: observation.uid,
        asset: assetRef,
        formData: event.formData,
      })
    );
  };

  return (
    <div
      className="text-white"
      data-testid="classification-form"
      key={displaySet.displaySetInstanceUID}
    >
      {assetKinds
        .filter(kind => !!characterisations[kind]?.json)
        .map(kind => (
          <div key={kind}>
            <p className="my-1 text-sm uppercase text-gray-400">{kind} wise:</p>
            <Form
              readonly={readOnly}
              schema={characterisations[kind].json}
              uiSchema={{
                ...characterisations[kind].ui,
                'ui:submitButtonOptions': {
                  norender: true,
                },
              }}
              onChange={handleChange(getRelatedAsset(asset, kind))}
              formData={formData[kind]}
              validator={defaultFormValidator}
            />
          </div>
        ))}
    </div>
  );
}

function getFormDataFor(
  asset: InstanceAssetRef,
  characterisations: AssetCharacterisationSubmission[] = []
) {
  return groupAndFilterCharacterisationsByLevel(asset, characterisations);
}
