import { Internal, OHIF } from '@gleamer/types';

type UserAuthenticationService = OHIF.UserAuthenticationService;
type UserEvent = Internal.UserEvent;

export async function pushEvents(
  userAuthenticationService: UserAuthenticationService,
  taskId: number,
  itemId: string,
  events: UserEvent[]
): Promise<void> {
  const response = await fetch(`/api/label-tasks/${taskId}/items/${itemId}/events`, {
    method: 'POST',
    headers: new Headers({
      ...userAuthenticationService.getAuthorizationHeader(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    body: JSON.stringify(events),
    keepalive: true,
  });
  if (response.status >= 400) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
}
