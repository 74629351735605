import {
  useHasPreviousUnsavedChanges,
  useHasUnsavedChanges,
  useIsLastSeenItem,
  useNextItemId,
  usePreviousItemId,
} from '@gleamer/ui';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

type ItemNavigatorProps = HTMLAttributes<HTMLButtonElement> & {
  itemId: string | null;
  hasOtherUnsavedChanges?: boolean;
};

function ItemNavigator({
  className,
  itemId,
  children,
  hasOtherUnsavedChanges,
  ...props
}: ItemNavigatorProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasUnsavedChanges = useHasUnsavedChanges();
  const isLastSeenItem = useIsLastSeenItem();

  const onButtonClick = () => {
    if (isLastSeenItem || !hasUnsavedChanges) {
      searchParams.set('item', itemId);
      return setSearchParams(searchParams);
    }

    const confirm = window.confirm(
      'You have unsaved changes. Are you sure you want to see another case?'
    );

    if (confirm) {
      searchParams.set('item', itemId);
      return setSearchParams(searchParams);
    }
  };

  if (!itemId) {
    return (
      <div className={classNames(`rounded-md p-2 text-gray-500`, className)}>
        {children}
      </div>
    );
  }

  return (
    <button
      onClick={onButtonClick}
      className={classNames(
        'relative rounded-md text-white p-2 hover:bg-primary-main transition ease-in-out duration-300',
        className
      )}
      {...props}
    >
      {children}
      {hasOtherUnsavedChanges && (
        <span className="absolute top-[-8px] text-xl text-red-700">•</span>
      )}
    </button>
  );
}

export function PreviousItemButtons() {
  const { t } = useTranslation();

  const previousItemId = usePreviousItemId();
  const nextItemId = useNextItemId();
  const hasPreviousUnsavedChanges = useHasPreviousUnsavedChanges();

  if (!previousItemId && !nextItemId) {
    return null;
  }

  return (
    <div className="flex items-center">
      <ItemNavigator
        itemId={previousItemId}
        title={t('Previous case')}
        hasOtherUnsavedChanges={hasPreviousUnsavedChanges}
      >
        <ChevronLeftIcon className="w-4 h-4" />
      </ItemNavigator>
      <ItemNavigator itemId={nextItemId} title={t('Next case')}>
        <ChevronRightIcon className="w-4 h-4" />
      </ItemNavigator>
    </div>
  );
}
