import { useTask } from '@gleamer/ui';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import { Tooltip, useModal } from '@ohif/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InstructionsModal } from '../../molecules/InstructionsModal';

export function MetadataButton() {
  const { t } = useTranslation() as { t: (key: string) => string };
  const { show } = useModal();

  const task = useTask();
  const instructions = task?.instructions;

  if (!instructions) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Tooltip content={t('Instructions')}>
        <button
          onClick={() =>
            show({
              content: InstructionsModal,
              title: 'Instructions',
              contentProps: { content: instructions },
            })
          }
          className="inline-flex items-center justify-center rounded-md p-2 text-center text-lg font-bold text-white outline-none transition duration-300 ease-in-out hover:bg-primary-light hover:text-black focus:bg-primary-light focus:text-black focus:outline-none active:opacity-80"
        >
          <LightBulbIcon className="h-auto w-5" />
        </button>
      </Tooltip>
    </div>
  );
}
