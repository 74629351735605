import { Assets } from '@gleamer/types';
import { isReadonly, useAssetCharacterisations, useTask } from '@gleamer/ui';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { ExtensionManager, ServicesManager, classes } from '@ohif/core';
import { Tooltip } from '@ohif/ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentDisplaySet } from '../../../hooks/useCurrentDisplaySet';
import { useCurrentImageIndex } from '../../../hooks/useCurrentImageIndex';
import { isValidInstance } from '../../../utils/validation.utils';
import { CharacterisationButton } from '../../atoms/CharacterisationButton';
import { AssetCharacterisationForm } from '../modals/CharacterisationForm/AssetCharacterisationForm';
import getImageSrcFromImageId from '../panels/StudyBrowser/getImageSrcFromImageId';
import { BUTTON_IDS } from './constants';

type InstanceAssetRef = Assets.InstanceAssetRef;

type InstanceCharacterisationButtonProps = {
  extensionManager: ExtensionManager;
  servicesManager: ServicesManager;
};

type AsyncThumbnailProps = {
  imageId: string;
  extensionManager: any;
};

function AsyncThumbnail({ imageId, extensionManager }: AsyncThumbnailProps) {
  const [imageSrc, setImageSrc] = useState<string>(null);
  const utilities = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.common'
  );
  const { cornerstone } = utilities.exports.getCornerstoneLibraries();

  useEffect(() => {
    getImageSrcFromImageId(cornerstone, imageId).then(source => {
      setImageSrc(source);
    });
  }, [cornerstone, imageId]);

  if (!imageSrc) {
    return <div className="min-h-32 w-full object-none" />;
  }

  return <img src={imageSrc} className="min-h-32 object-none" alt="" />;
}

// This component is displayed only when the current task has instance charac schema
function DisplayedInstanceCharacterisationButton({
  extensionManager,
  servicesManager,
}: InstanceCharacterisationButtonProps) {
  const task = useTask();
  const { t } = useTranslation();
  const {
    uiModalService,
    displaySetService,
    cornerstoneViewportService,
    viewportGridService,
  } = servicesManager.services;

  const characterisations = useAssetCharacterisations();
  const readOnly = isReadonly();

  const displaySet = useCurrentDisplaySet(displaySetService);
  const imageIndex = useCurrentImageIndex(servicesManager);
  const [image, setImage] = useState();

  useEffect(() => {
    const image = displaySet?.images[imageIndex];
    setImage(image);
  }, [displaySet, imageIndex]);

  if (!image) {
    return null;
  }

  const { SOPInstanceUID, SeriesInstanceUID, StudyInstanceUID } = image;

  const onClick = () => {
    const { activeViewportId } = viewportGridService.getState();
    const viewport =
      cornerstoneViewportService.getCornerstoneViewport(activeViewportId);

    if (!viewport) {
      return;
    }

    const imageId = viewport.getCurrentImageId();
    if (!imageId) {
      return;
    }

    const image = classes.MetadataProvider.getInstance(imageId);
    const { SOPInstanceUID, SeriesInstanceUID, StudyInstanceUID, PatientID } =
      image;

    const asset: InstanceAssetRef = {
      kind: 'instance',
      sopInstanceUID: SOPInstanceUID,
      seriesInstanceUID: SeriesInstanceUID,
      studyInstanceUID: StudyInstanceUID,
      patientId: PatientID,
    };

    uiModalService.show({
      content: AssetCharacterisationForm,
      contentProps: {
        jsonSchema: task.characterisations.instance.json,
        uiSchema: task.characterisations.instance.ui,
        asset,
        readOnly,
        children: (
          <AsyncThumbnail
            imageId={imageId}
            extensionManager={extensionManager}
          />
        ),
        servicesManager,
      },
      shouldCloseOnEsc: true,
      closeButton: true,
      title: t('Instance Characterisation'),
    });
  };

  const isValid = isValidInstance(
    task,
    characterisations,
    StudyInstanceUID,
    SeriesInstanceUID,
    SOPInstanceUID
  );

  return (
    <div className="flex items-center">
      <Tooltip content={t('Image Characterisation')}>
        <CharacterisationButton
          id={BUTTON_IDS.INSTANCE}
          onClick={onClick}
          className="inline-flex items-center justify-center rounded-md p-2 text-center text-lg font-bold text-white outline-none transition duration-300 ease-in-out hover:bg-primary-light hover:text-black focus:bg-primary-light focus:text-black focus:outline-none active:opacity-80"
        >
          <PhotoIcon className="h-6 w-6" />
          {isValid && (
            <CheckBadgeIcon className="absolute bottom-0 right-0 h-4 w-4 group-hover:text-secondary-dark" />
          )}
        </CharacterisationButton>
      </Tooltip>
    </div>
  );
}

export function InstanceCharacterisationButton(
  props: InstanceCharacterisationButtonProps
) {
  const task = useTask();

  if (!task?.characterisations?.instance?.json) {
    return null;
  }

  return <DisplayedInstanceCharacterisationButton {...props} />;
}
