import { NavBar } from '@ohif/ui';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  useLastNotifications,
  useMarkNotificationAsRead,
} from '../../../hooks/useNotifications';
import { NotificationTooltip } from '../Notifications/NotificationTooltip';
import { LoginButton } from './LoginButton';

export function GdataHeader() {
  const { data, isLoading } = useLastNotifications();
  const lastNotifications = data?.content ?? [];

  const { mutate: markAsRead } = useMarkNotificationAsRead();

  return (
    <NavBar className="justify-between border-b-4 border-black" isSticky>
      <div className="flex flex-1 justify-between">
        <div className="flex items-center">
          <Link to="/" className="mr-3 inline-flex cursor-pointer items-center">
            <div className="ml-4">
              <h1 className="text-white">GData</h1>
            </div>
          </Link>
        </div>
        <div className="flex items-center">
          <NotificationTooltip
            disabled={isLoading || !data}
            notifications={lastNotifications}
            onNotificationRead={id => {
              markAsRead(id);
            }}
          />

          <div className="ml-4 pr-2 text-white">
            <LoginButton />
          </div>
        </div>
      </div>
    </NavBar>
  );
}
