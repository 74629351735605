import {
  Accordion,
  labelPass,
  RootState,
  useAssetCharacterisations,
  useTask,
} from '@gleamer/ui';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { DicomMetadataStore } from '@ohif/core';
import { Icon } from '@ohif/ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCurrentItem } from '../../../../hooks/useCurrentItem';
import { isValidSeries } from '../../../../utils/validation.utils';
import { InstanceThumbnail } from './InstanceThumbnail';
import { ObservationsIndicators } from './ObservationsIndicators';
import { SeriesCharacterisation } from './SeriesCharacterisation';

const EXPANDABLES_MODALITIES = ['CR', 'DX', 'MG', 'SC'];
const EXPANDABLES_SOP_CLASS_UIDS = [
  '1.2.840.10008.5.1.4.1.1.1.1',
  '1.2.840.10008.5.1.4.1.1.1',
  '1.2.840.10008.5.1.4.1.1.1.1.1',
  '1.2.840.10008.5.1.4.1.1.1.2',
  '1.2.840.10008.5.1.4.1.1.1.2.1',
  '1.2.840.10008.5.1.4.1.1.7',
];

function showInstanceThumbnails({ StudyInstanceUID, SeriesInstanceUID }) {
  const seriesMetadata = DicomMetadataStore.getSeries(
    StudyInstanceUID,
    SeriesInstanceUID
  );

  if (!seriesMetadata) {
    return false;
  }

  const { Modality, instances } = seriesMetadata;
  if (!EXPANDABLES_MODALITIES.includes(Modality)) {
    return false;
  }

  return instances.every(instance =>
    EXPANDABLES_SOP_CLASS_UIDS.includes(instance.SOPClassUID)
  );
}

function InstancesNumber({ numInstances }: { numInstances: number }) {
  return (
    <div className="flex flex-1 flex-row items-center">
      <PhotoIcon className="mr-2 w-4" /> {numInstances}
    </div>
  );
}

function Thumbnail({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  seriesNumber,
  numInstances,
  dragData,
  isActive,
  onClick,
  onDoubleClick,
  onDoubleClickInstanceThumbnail,
  SopInstanceUIDs,
  imageIds,
  inverted,
  getImageSrc,
  StudyInstanceUID,
  SeriesInstanceUID,
  servicesManager,
}) {
  const { t } = useTranslation();
  // TODO: We should wrap our thumbnail to create a "DraggableThumbnail", as
  // this will still allow for "drag", even if there is no drop target for the
  // specified item.
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function (monitor) {
      return Object.keys(dragData).length !== 0;
    },
  });

  const matchingObservations = useSelector((state: RootState) => {
    const observations = labelPass.selectors.getObservations(state);
    return observations.filter(obs => {
      return obs.regions.some(
        region =>
          region.referenceStudyUID === StudyInstanceUID &&
          region.referenceSeriesUID === SeriesInstanceUID
      );
    });
  });

  const ref = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState<boolean>(false);
  const chevronName = expanded ? 'chevron-down' : 'chevron-left';
  const [thumbnailImageSrcMap, setThumbnailImageSrcMap] = useState({});
  const isMounted = useRef(true);
  const task = useTask();
  const item = useCurrentItem({ servicesManager });
  const characterisations = useAssetCharacterisations();

  const hasCharacterisationForm = !!task?.characterisations?.series?.json;

  const isValid = isValidSeries(
    task,
    characterisations,
    StudyInstanceUID,
    SeriesInstanceUID
  );

  const toggleExpanded = useCallback(() => {
    setExpanded(wasExpanded => !wasExpanded);
  }, []);

  useEffect(() => {
    if (!expanded || SopInstanceUIDs.length <= 1) {
      return;
    }

    const instances = item.studies
      .flatMap(({ series }) => series)
      .flatMap(({ instances }) => instances);

    const newImagesLoaders = SopInstanceUIDs.map(async sopInstanceUID => {
      const matchingInstance = instances.find(
        instance => instance.metadata.SOPInstanceUID === sopInstanceUID
      );

      if (matchingInstance) {
        const imageSrc = await getImageSrc(matchingInstance.url);
        return {
          imageSrc,
          sopInstanceUID,
        };
      }
      return null;
    });

    Promise.allSettled(newImagesLoaders).then(imageLoaders => {
      imageLoaders.forEach(source => {
        if (
          source.status === 'fulfilled' &&
          isMounted.current &&
          source.value
        ) {
          const { sopInstanceUID, imageSrc } = source.value;
          setThumbnailImageSrcMap(prevState => {
            return { ...prevState, [sopInstanceUID]: imageSrc };
          });
        }
      });
    });
  }, [expanded]);

  const shouldShowInstances = useMemo(() => {
    return showInstanceThumbnails({ StudyInstanceUID, SeriesInstanceUID });
  }, [SeriesInstanceUID, StudyInstanceUID]);

  return (
    <div
      ref={ref}
      className={classnames(
        className,
        'group mb-8 flex flex-1 select-none flex-col px-3 outline-none'
      )}
    >
      <div className="relative">
        <ObservationsIndicators
          observations={matchingObservations}
          className="bottom-[20px]"
        />
        <div
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          role="button"
          tabIndex={0}
          id={`thumbnail-${displaySetInstanceUID}`}
          data-cy={`study-browser-thumbnail`}
          ref={drag}
          className={classnames(
            'flex min-h-32 flex-1 cursor-pointer items-center justify-center overflow-hidden rounded-md bg-black text-base text-white',
            isActive
              ? 'border-2 border-primary-light'
              : 'border border-secondary-light hover:border-blue-300 group-focus:border-blue-300'
          )}
          style={{
            margin: isActive ? '0' : '1px',
          }}
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={imageAltText}
              className="min-h-32 object-none"
            />
          ) : (
            <div>{imageAltText}</div>
          )}
        </div>
        <div className="flex items-center justify-between pt-2 text-sm text-blue-300">
          <div className="mr-4">
            <span className="font-bold text-primary-main">{'S: '}</span>
            {seriesNumber}
          </div>
          {numInstances > 1 && (
            <div>
              {shouldShowInstances ? (
                <button
                  className="flex cursor-pointer items-center"
                  onClick={toggleExpanded}
                  title={t('Expand to see all instances')}
                >
                  <Icon
                    name={chevronName}
                    className="mr-1 h-4 w-4 text-blue-300"
                  />
                  <InstancesNumber numInstances={numInstances} />
                </button>
              ) : (
                <InstancesNumber numInstances={numInstances} />
              )}
            </div>
          )}
        </div>
      </div>
      <Accordion initialOpen>
        <Accordion.Title
          className="flex cursor-pointer items-center justify-start break-words text-left text-sm font-normal tracking-normal text-white"
          title={t('Expand to see characterisations')}
          chevronClassName="mr-0"
          inactiveClassName="ml-0"
          inactive={!hasCharacterisationForm}
        >
          {description}
          {isValid && (
            <CheckBadgeIcon className="ml-1 inline h-4 w-4 text-white" />
          )}
        </Accordion.Title>
        <Accordion.Content>
          <SeriesCharacterisation
            SeriesInstanceUID={SeriesInstanceUID}
            StudyInstanceUID={StudyInstanceUID}
            servicesManager={servicesManager}
          />
        </Accordion.Content>
        {expanded && shouldShowInstances && (
          <div className="mt-4 grid grid-cols-2 gap-4">
            {SopInstanceUIDs.map((sopInstanceUID, index) => (
              <InstanceThumbnail
                key={sopInstanceUID}
                sopInstanceUID={sopInstanceUID}
                index={index}
                onDoubleClick={() =>
                  onDoubleClickInstanceThumbnail(
                    displaySetInstanceUID,
                    sopInstanceUID,
                    inverted
                  )
                }
                thumbnailSrc={thumbnailImageSrcMap[sopInstanceUID]}
                dragData={dragData}
              />
            ))}
          </div>
        )}
      </Accordion>
    </div>
  );
}

Thumbnail.propTypes = {
  displaySetInstanceUID: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  /**
   * Data the thumbnail should expose to a receiving drop target. Use a matching
   * `dragData.type` to identify which targets can receive this draggable item.
   * If this is not set, drag-n-drop will be disabled for this thumbnail.
   *
   * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
   */
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  imageAltText: PropTypes.string,
  description: PropTypes.string.isRequired,
  seriesNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  numInstances: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onDoubleClickInstanceThumbnail: PropTypes.func.isRequired,
  SopInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  imageIds: PropTypes.arrayOf(PropTypes.string),
  inverted: PropTypes.bool,
  getImageSrc: PropTypes.func.isRequired,
  StudyInstanceUID: PropTypes.string,
  SeriesInstanceUID: PropTypes.string,
  servicesManager: PropTypes.object.isRequired,
};

Thumbnail.defaultProps = {
  dragData: {},
};

export default Thumbnail;
