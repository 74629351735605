import { useQuery } from '@tanstack/react-query';
import { Internal } from '@gleamer/types';
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;

type UseFileReportArgs = {
  report: EnhancedDicomJSONReport;
  fetchReport: (report: EnhancedDicomJSONReport) => Promise<Blob>;
  enabled?: boolean;
};

export function useFileReport({
  report,
  fetchReport,
  enabled,
}: UseFileReportArgs) {
  return useQuery({
    queryKey: ['report', report.url],
    queryFn: async () => {
      const blob = await fetchReport(report);
      return URL.createObjectURL(blob);
    },

    // This caches data for the whole duration of the session
    staleTime: Infinity,
    enabled,
  });
}
