import { Enums } from '@cornerstonejs/tools';
import { OHIF } from '@gleamer/types';
import classNames from 'classnames';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getMouseBindingsConfigs } from '../../../../utils/tools/mouse-bindings';
import { isEqual } from 'lodash';

type Binding = OHIF.ToolConfigBinding;

const baseInputClasses =
  'shadow transition duration-300 appearance-none border border-primary-main hover:border-gray-500 focus:border-gray-500 focus:outline-none rounded w-full py-2 px-3 text-base text-white leading-tight focus:outline-none bg-transparent max-w-xs';

const enumByButtons: Record<number, string> = {
  1: 'Left',
  2: 'Right',
  3: 'Left and Right',
  4: 'Wheel',
  5: 'Left and wheel',
  6: 'Right and wheel',
  7: 'Left, Right and wheel',
  8: 'Fourth Button',
  16: 'Fifth Button',
};

const modifiers = [
  { name: 'Shift', shiftKey: true },
  { name: 'Ctrl', ctrlKey: true },
  { name: 'Alt', altKey: true },
  { name: 'Meta', metaKey: true },
  { name: 'ShiftCtrl', shiftKey: true, ctrlKey: true },
  { name: 'ShiftAlt', shiftKey: true, altKey: true },
  { name: 'ShiftMeta', shiftKey: true, metaKey: true },
  { name: 'CtrlAlt', ctrlKey: true, altKey: true },
  { name: 'CtrlMeta', ctrlKey: true, metaKey: true },
  { name: 'AltMeta', altKey: true, metaKey: true },
];

function getModifierKeyName(event: MouseEvent) {
  return modifiers
    .map(modifier => {
      const { name, ...keys } = modifier;
      const matches = Object.keys(keys).filter(
        key => event[key] === keys[key]
      ).length;
      return {
        name,
        matches,
      };
    })
    .filter(({ matches }) => matches > 0)
    .sort((a, b) => b.matches - a.matches)
    .at(0)?.name;
}

function getModifierKey(modifierKey: string): number | undefined {
  if (!modifierKey) {
    return undefined;
  }

  return Enums.KeyboardBindings[modifierKey];
}

function getMouseButton(buttons: number): string {
  return enumByButtons[buttons] || 'unknown';
}

function getDisplayedValue(preferences: Binding | undefined): string {
  if (!preferences) {
    return '';
  }

  const { mouseButton, modifierKey } = preferences;

  if (mouseButton === undefined) {
    return '';
  }

  const mouseButtonName = getMouseButton(mouseButton);
  if (!modifierKey) {
    return mouseButtonName;
  }

  const modifierKeyName = Enums.KeyboardBindings[modifierKey];
  return `${mouseButtonName} + ${modifierKeyName}`;
}

type MouseInputProps = {
  binding: Binding;
  onChange: (binding: Binding) => void;
  className?: string;
  configs: ReturnType<typeof getMouseBindingsConfigs>;
  toolName: string;
};

export function MouseInput({
  binding,
  onChange,
  className,
  configs,
  toolName,
}: MouseInputProps) {
  const [isCapturing, setIsCapturing] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const overlappingConfig = configs
    .filter(config => config.toolName !== toolName)
    .find(config => config.bindings.some(bd => isEqual(bd, binding)));

  const mousedownHandler = useCallback(
    (event: MouseEvent) => {
      if (isCapturing) {
        event.preventDefault();
        event.stopPropagation();

        const { buttons } = event;
        const modifierKey = getModifierKeyName(event);

        const binding: Binding = {
          mouseButton: buttons,
        };
        if (getModifierKey(modifierKey)) {
          binding.modifierKey = getModifierKey(modifierKey);
        }

        onChange(binding);
      }
    },
    [isCapturing, onChange]
  );

  const mouseupHandler = useCallback(() => {
    if (isCapturing) {
      setIsCapturing(false);
    }
  }, [isCapturing]);

  const handleClick: MouseEventHandler<HTMLInputElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!isCapturing) {
      setIsCapturing(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', mousedownHandler);
    document.addEventListener('mouseup', mouseupHandler);

    return () => {
      document.removeEventListener('mousedown', mousedownHandler);
      document.removeEventListener('mouseup', mouseupHandler);
    };
  }, [mousedownHandler, mouseupHandler]);

  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        setIsCapturing(false);
      }
    }

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    if (isCapturing) {
      inputRef.current.value = '';
      inputRef.current.placeholder = 'Recording...';
    } else {
      inputRef.current.value = getDisplayedValue(binding);
    }
  }, [binding, isCapturing]);

  return (
    <div className="flex flex-col gap-1">
      <input
        className={classNames(baseInputClasses, className, {
          'border-red-600': !!overlappingConfig,
        })}
        onClick={handleClick}
        ref={inputRef}
        placeholder="Click to define the shortcut"
      />
      {overlappingConfig && (
        <span className="text-red-600 text-xs">
          This overlaps with {overlappingConfig.toolName}
        </span>
      )}
    </div>
  );
}
