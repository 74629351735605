import { Assets } from '@gleamer/types';
import {
  ActionButton,
  ActionButtonProps,
  useAssetCharacterisations,
  useHasUnsavedChanges,
  useIsLastSeenItem,
  useObservationSubmissions,
  useTask,
} from '@gleamer/ui';
import { DicomMetadataStore } from '@ohif/core';
import { ErrorObject } from 'ajv';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useReports } from '../../contexts/ReportsContext';
import {
  validateAssetCharacterisations,
  validateObservation,
} from '../../utils/validation.utils';

type AssetKind = Assets.AssetKind;

export type ErrorsByKind = Record<AssetKind, ErrorObject[]>;

type ValidationErrorsProps = {
  validationErrors: Record<AssetKind, ErrorObject[]>;
};

function ValidationErrors({ validationErrors }: ValidationErrorsProps) {
  const { t } = useTranslation();

  return (
    <div>
      {Object.entries(validationErrors)
        .filter(([, errors]) => errors.length > 0)
        .map(([kind, errors]) => {
          return (
            <div key={kind} className="mt-2">
              <h3 className="mb-1 uppercase text-gray-400">{t(kind)}</h3>
              <ul>
                {errors.map((error, index) => {
                  if (Array.isArray(error)) {
                    return error.map((err, idx) => (
                      <li key={`${index}-${idx}`}>
                        {err.message.replace(/"/g, '')}
                      </li>
                    ));
                  }

                  return (
                    <li key={index}>
                      {error.message
                        ? error.message.replace(/"/g, '')
                        : 'Error'}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
    </div>
  );
}

type ValidateButtonProps = PropsWithChildren<
  Pick<ActionButtonProps, 'className' | 'onClick' | 'disabled'> & {
    mode: string;
  }
>;

function containsErrors(errors: ErrorsByKind | null) {
  return errors && Object.values(errors).some(errors => errors.length > 0);
}

function DisabledValidateButtonContent({
  className,
  children = 'Validate',
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <span
      className={classNames(
        'inline-flex h-full w-full min-w-md items-center justify-center overflow-hidden bg-gray-500 px-2 py-2 text-center font-sans text-base leading-none text-white transition duration-300 ease-in-out',
        className
      )}
    >
      {children}
    </span>
  );
}

export function ValidateButton({
  className,
  onClick,
  disabled,
  children = 'Validate',
  mode = 'label',
}: ValidateButtonProps) {
  const { t } = useTranslation();

  const task = useTask();
  const { reports = {}, patientReports = [] } = useReports();
  const characterisations = useAssetCharacterisations();
  const observations = useObservationSubmissions();

  const isLastSeenItem = useIsLastSeenItem();
  const hasUnsavedChanges = useHasUnsavedChanges();

  const studyInstanceUIDs = DicomMetadataStore.getStudyInstanceUIDs();
  const series = studyInstanceUIDs.flatMap(
    studyInstanceUID =>
      DicomMetadataStore.getStudy(studyInstanceUID)?.series || []
  );
  const instances = series.flatMap(series => series.instances || []);

  const lengthByKind: { kind: AssetKind; length: number }[] = [
    { kind: 'patient', length: 1 },
    { kind: 'study', length: DicomMetadataStore.getStudyInstanceUIDs().length },
    { kind: 'series', length: series.length },
    { kind: 'instance', length: instances.length },
    { kind: 'studyReport', length: Object.keys(reports).length },
    { kind: 'patientReport', length: patientReports.length },
  ];

  const characterisationErrors = lengthByKind
    .map(({ kind, length }) => {
      return {
        kind,
        length,
        errors: validateAssetCharacterisations(
          task,
          characterisations,
          length,
          kind
        ),
      };
    })
    .filter(({ errors }) => {
      return errors && errors.length > 0;
    })
    .reduce((acc, { kind, errors }) => {
      return {
        ...acc,
        [kind]: errors,
      };
    }, {} as ErrorsByKind);

  const observationsErrors = new Map();
  observations.forEach(observation => {
    const errors = validateObservation(task, observation);
    observationsErrors.set(observation, errors);
  });

  const hasCharacterisationErrors = containsErrors(characterisationErrors);
  const hasObservationErrors =
    observationsErrors &&
    Array.from(observationsErrors.values()).some(containsErrors);

  const hasErrors = hasCharacterisationErrors || hasObservationErrors;

  if (mode !== 'review' && !isLastSeenItem && !hasUnsavedChanges) {
    return (
      <ActionButton
        disabled
        disabledReason={<div>{t('No changes to save')}</div>}
        className={className}
      >
        <DisabledValidateButtonContent>
          {children}
        </DisabledValidateButtonContent>
      </ActionButton>
    );
  }

  if (!hasErrors) {
    return (
      <ActionButton
        onClick={onClick}
        disabled={disabled}
        className={classNames(className, {
          'bg-gray-500 hover:bg-gray-500': disabled,
        })}
      >
        {children}
      </ActionButton>
    );
  }

  return (
    <ActionButton
      className={className}
      disabled
      disabledReason={
        <div>
          <p>{t('Pass cannot be saved')}</p>
          {hasCharacterisationErrors && (
            <>
              <h2 className="mt-3 font-bold">{t('Characterisations')}</h2>
              <ValidationErrors validationErrors={characterisationErrors} />
            </>
          )}
          {hasObservationErrors && (
            <>
              <h2 className="mt-3 font-bold">{t('Observations')}</h2>
              {Array.from(observationsErrors).map(([obs, errors]) => (
                <div key={`${obs.code}-${obs.status}-${obs.index}`}>
                  <h3 className="my-1 uppercase text-gray-400">{obs.label}</h3>
                  <ValidationErrors validationErrors={errors} />
                </div>
              ))}
            </>
          )}
        </div>
      }
    >
      <DisabledValidateButtonContent>{children}</DisabledValidateButtonContent>
    </ActionButton>
  );
}
