import { Button, HotkeysPreferences, Typography } from '@ohif/ui';
import { isEqual, uniqWith } from 'lodash';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultMouseBindings } from '../../../../utils/tools/mouse-bindings';
import { MouseBindings, MouseToolConfig } from './MouseBindings';

type HotkeyDefinition = {
  commandName: string;
  commandOptions: any;
  label: string;
  keys: string[];
  isEditable: boolean;
};

type UserPreferencesState = {
  isDisabled: boolean;
  hotkeyErrors: Record<string, string>;
  hotkeyDefinitions: HotkeyDefinition[];
  configs: MouseToolConfig[];
};

type UserPreferencesProps = {
  disabled?: boolean;
  hotkeyDefaults: HotkeyDefinition[];
  hotkeyDefinitions: HotkeyDefinition[];
  onCancel?: () => void;
  onSubmit?: (state: UserPreferencesState) => void;
  onReset?: () => void;
  configs: ReturnType<typeof getDefaultMouseBindings>;
  hotkeysModule: {
    initialize: () => void;
    pause: () => void;
    unpause: () => void;
    startRecording: () => void;
    record: () => void;
  };
};

function hasOverlappingBindings(configs: MouseToolConfig[]) {
  const allBindings = configs.flatMap(config => config.bindings);
  const uniqueBindings = uniqWith(allBindings, isEqual);
  return allBindings.length !== uniqueBindings.length;
}

function Section({ title, children }: PropsWithChildren<{ title: string }>) {
  return (
    <>
      <div className="border-b-2 border-black mb-2">
        <Typography
          variant="h5"
          className="flex grow text-primary-light font-light pb-2"
        >
          {title}
        </Typography>
      </div>
      <div className="mt-4 mb-8">{children}</div>
    </>
  );
}

export function UserPreferences({
  disabled,
  hotkeyDefinitions,
  hotkeyDefaults,
  onCancel,
  onSubmit,
  onReset,
  hotkeysModule,
  configs,
}: UserPreferencesProps) {
  const { t } = useTranslation('UserPreferencesModal');
  const [state, setState] = useState<UserPreferencesState>({
    isDisabled: disabled,
    hotkeyErrors: {},
    hotkeyDefinitions,
    configs,
  });

  const onMouseBindingsChange = (
    toolName: string,
    bindings: MouseToolConfig['bindings']
  ) => {
    setState(state => {
      const configs = state.configs.map(config => {
        if (config.toolName === toolName) {
          return {
            ...config,
            bindings,
          };
        }
        return config;
      });

      const isOverlapping = hasOverlappingBindings(configs);
      const hasHotkeyErrors = Object.values(state.hotkeyErrors).some(
        error => error !== undefined
      );

      return {
        ...state,
        isDisabled: isOverlapping || hasHotkeyErrors,
        configs,
      };
    });
  };

  const onSubmitHandler = () => {
    onSubmit(state);
  };

  const onResetHandler = () => {
    setState(state => ({
      ...state,
      hotkeyDefinitions: hotkeyDefaults,
      hotkeyErrors: {},
      isDisabled: disabled,
      configs: getDefaultMouseBindings(),
    }));
    onReset();
  };

  const onCancelHandler = () => {
    setState(prevState => ({ ...prevState, hotkeyDefinitions }));
    onCancel();
  };

  const onHotkeysChangeHandler = (id, definition, errors) => {
    setState(state => {
      const isOverlapping = hasOverlappingBindings(state.configs);

      const hasHotkeysErrors = Object.values(errors).every(
        e => e !== undefined
      );

      return {
        ...state,
        isDisabled: hasHotkeysErrors || isOverlapping,
        hotkeyErrors: errors,
        hotkeyDefinitions: {
          ...state.hotkeyDefinitions,
          [id]: definition,
        },
      };
    });
  };

  return (
    <div className="p-2">
      <Section title={t('Mouse bindings')}>
        <MouseBindings
          configs={state.configs}
          onChange={onMouseBindingsChange}
        />
      </Section>
      <Section title={t('Hotkeys')}>
        <HotkeysPreferences
          disabled={disabled}
          hotkeyDefinitions={state.hotkeyDefinitions}
          onChange={onHotkeysChangeHandler}
          errors={state.hotkeyErrors}
          hotkeysModule={hotkeysModule}
        />
      </Section>
      <div className="flex flex-row justify-between">
        <Button
          variant="outlined"
          border="light"
          onClick={onResetHandler}
          disabled={disabled}
        >
          {t('Reset to Defaults')}
        </Button>
        <div className="flex flex-row">
          <Button variant="outlined" border="light" onClick={onCancelHandler}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={state.isDisabled}
            color="light"
            border="light"
            className="ml-2"
            onClick={onSubmitHandler}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const noop = () => {};

UserPreferences.defaultProps = {
  onCancel: noop,
  onSubmit: noop,
  onReset: noop,
  disabled: false,
};
