import React from 'react';
import { Internal } from '@gleamer/types';
import { OHIF } from '@gleamer/types';
import { ReportFileEntry } from './ReportFileEntry';
import { ReportStudyEntry } from './ReportStudyEntry';

type ServicesManager = OHIF.ServicesManager;
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;
type LabelTask = Internal.LabelTask;

type PanelReportEntryProps = {
  servicesManager: ServicesManager;
  patientId?: string;
  report: EnhancedDicomJSONReport;
  fetchReport: (report: EnhancedDicomJSONReport) => Promise<Blob>;
  className?: string;
  task?: LabelTask;
};

export function PanelReportEntry({ report, ...props }: PanelReportEntryProps) {
  if (report.StudyInstanceUID) {
    return <ReportStudyEntry report={report} {...props} />;
  }

  return <ReportFileEntry report={report} {...props} />;
}
