import { Assets, Internal } from '@gleamer/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssetCharacterisations,
  getFeedbacks,
  getItemLabelPass,
  getObservations,
  hasNextUnsavedChanges,
  hasPreviousUnsavedChanges,
  hasUnsavedChanges,
} from './labelPass.selectors';
import { actions } from './labelPass.slice';

type ObservationSubmission = Internal.ObservationInternal;
type AssetRef = Assets.AssetRef;
type CharacterisationValues = Internal.CharacterisationValues;

export function useLabelPassSubmission() {
  return useSelector(getItemLabelPass);
}

export function useObservationSubmissions(): ObservationSubmission[] {
  return useSelector(getObservations);
}

export function useHasUnsavedChanges(): boolean {
  return useSelector(hasUnsavedChanges);
}

export function useUpdateAssetCharacterisations() {
  const dispatch = useDispatch();

  const updateCharacterisations = useCallback(
    (asset: AssetRef, formData: CharacterisationValues) => {
      dispatch(actions.updateAssetCharacterisations({ asset, formData }));
    },
    [dispatch]
  );

  return updateCharacterisations;
}

export function useAssetCharacterisations() {
  return useSelector(getAssetCharacterisations);
}

export function useHasPreviousUnsavedChanges() {
  return useSelector(hasPreviousUnsavedChanges);
}

export function useHasNextUnsavedChanges() {
  return useSelector(hasNextUnsavedChanges);
}

export function useFeedbacks() {
  return useSelector(getFeedbacks);
}
