import { offset, shift, useFloating } from '@floating-ui/react-dom';
import { Popover } from '@headlessui/react';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { NotificationList, NotificationListProps } from './NotificationList';

type NotificationTooltipProps = NotificationListProps & {
  disabled?: boolean;
};

export function NotificationTooltip({
  notifications,
  onNotificationRead,
  disabled,
}: NotificationTooltipProps) {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [offset(5), shift({ padding: 10 })],
  });

  return (
    <Popover className="relative">
      <Popover.Button
        ref={refs.setReference}
        disabled={disabled}
        title="Notifications"
        className="relative flex items-center rounded-md p-1 text-white outline-primary-light hover:bg-primary-light hover:text-secondary-dark disabled:cursor-not-allowed disabled:opacity-50"
      >
        <BellAlertIcon className="h-6 w-6" />
        {notifications.length > 0 && (
          <span className="absolute -top-3 right-0 text-xl text-red-700">
            •
          </span>
        )}
      </Popover.Button>

      <Popover.Panel
        style={floatingStyles}
        className="rounded-md shadow-md"
        ref={refs.setFloating}
      >
        <NotificationList
          notifications={notifications}
          onNotificationRead={onNotificationRead}
        />
      </Popover.Panel>
    </Popover>
  );
}
