import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

type PageTitleProps = PropsWithChildren<{
  className?: string;
  title: string;
}>;

export function PageTitle({ children, title, className }: PageTitleProps) {
  return (
    <div className={classNames('flex justify-between pb-2', className)}>
      <h2 className="text-2xl font-bold">{title}</h2>
      {children}
    </div>
  );
}
