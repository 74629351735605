import React, { useLayoutEffect, useRef } from 'react';
import Markdown from 'markdown-to-jsx';

type InstructionsModalProps = {
  content: string;
};

export function InstructionsModal({ content }: InstructionsModalProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    Array.from(contentRef.current.getElementsByTagName('a')).forEach(aTag => {
      aTag.setAttribute('target', '_blank');
    });
  }, []);

  return (
    <div className="mb-8 ml-4" ref={contentRef}>
      <Markdown className="prose prose-headings:text-white prose-a:text-gray-300 text-white">
        {content}
      </Markdown>
    </div>
  );
}
