import React, { useState } from 'react';
import {
  useInfiniteNotifications,
  useMarkNotificationAsRead,
} from '../../hooks/useNotifications';
import { Notification } from '../molecules/Notifications/Notification';
import classNames from 'classnames';
import { PageTitle } from '../atoms/PageTitle';
import { PageWrapper } from '../atoms/PageWrapper';

export function NotificationsPage() {
  const [read, setRead] = useState(false);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
    refetch,
  } = useInfiniteNotifications({ read });

  const { mutate: markAsRead } = useMarkNotificationAsRead();

  return (
    <PageWrapper className="max-w-xl">
      <PageTitle title="Notifications">
        <button onClick={() => refetch()}>Refresh</button>
      </PageTitle>
      <div className="my-2 pl-10 ">
        <div className="my-2 flex rounded-md border">
          <button
            onClick={() => setRead(false)}
            className={classNames('flex-1 rounded-l-md px-2 py-1', {
              'bg-primary-active text-white': read === false,
              'hover:bg-gray-200': read !== false,
            })}
          >
            Unread
          </button>
          <button
            onClick={() => setRead(true)}
            className={classNames('flex-1 px-2 py-1', {
              'bg-primary-active text-white': read === true,
              'hover:bg-gray-200': read !== true,
            })}
          >
            Read
          </button>
          <button
            onClick={() => setRead(undefined)}
            className={classNames('flex-1 rounded-r-md px-2 py-1', {
              'bg-primary-active text-white': read === undefined,
              'hover:bg-gray-200': read !== undefined,
            })}
          >
            All
          </button>
        </div>
        <div
          className={classNames('flex flex-col divide-y rounded-md', {
            border: !!data?.pages,
          })}
        >
          {error && <div>An error occured</div>}
          {!data && <div>Loading...</div>}
          {data?.pages?.map((page, i) => (
            <React.Fragment key={i}>
              {page.content.map(notification => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  variant="long"
                  onRead={() => {
                    markAsRead(notification.id);
                  }}
                  className="px-2 py-1 hover:bg-gray-200"
                />
              ))}
            </React.Fragment>
          ))}
        </div>
        {hasNextPage && (
          <div className="flex justify-center">
            <button
              className="my-2 rounded-md px-2 py-1 hover:bg-gray-200"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              Load more
            </button>
          </div>
        )}
      </div>
    </PageWrapper>
  );
}
