import { annotation as csToolsAnnotation } from '@cornerstonejs/tools';
import { OHIF } from '@gleamer/types';
import { configureStore } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import undoable, { StateWithHistory, combineFilters, excludeAction } from 'redux-undo';
import { getTaskItemId } from '../../utils';
import { storeLabelPass, storeTasksSeenItems } from './common/local-storage.middleware';
import * as labelPass from './labelPass';
import { DrawerMiddlewareConfig, drawerMiddleware } from './labelPass/drawer.middleware';
import { measurementsMiddleware } from './labelPass/measurements.middleware';
import { UndoRedoMiddlewareConfig, undoRedoMiddleware } from './labelPass/undo-redo.middleware';
import * as labelTask from './labelTask';
import * as tasksSeenItems from './tasksSeenItems';
import * as tools from './tools';
import { toolsMiddleware } from './tools/tools.middleware';

type LabelTaskState = labelTask.LabelTaskState;
type LabelPassState = labelPass.LabelPassState;
type ToolsState = tools.ToolsState;
type TasksSeenItems = tasksSeenItems.TasksSeenItems;

type CreateStoreOptions = {
  preloadedState?: {
    labelPassState?: LabelPassState;
    labelTask?: LabelTaskState;
    tools?: ToolsState;
    tasksSeenItems?: TasksSeenItems;
  };
  servicesManager: OHIF.ServicesManager;
  annotation?: typeof csToolsAnnotation;
  drawRegion?: UndoRedoMiddlewareConfig['drawRegion'];
} & DrawerMiddlewareConfig;

function shouldFilterUpdateRegion(action, currentState, previousHistory) {
  if (!labelPass.actions.updateRegion.match(action)) {
    return true;
  }

  const { taskId, itemId } = getTaskItemId();
  if (!taskId || !itemId) {
    return false;
  }

  const observationsState = previousHistory.present.data[taskId][itemId];
  if (!observationsState) {
    return false;
  }

  const region = observationsState.observations
    .flatMap(obs => obs.regions)
    .find(reg => reg.uid === action.payload.annotationUID);

  if (!region) {
    return false;
  }

  return !isEqual(region.data.handles, action.payload.data.handles);
}

export function createStore({
  preloadedState,
  servicesManager,
  // We do not import annotation directly as this breaks tests
  annotation,
  drawRegion,
}: CreateStoreOptions) {
  return configureStore({
    reducer: {
      labelPassState: undoable(labelPass.reducer, {
        filter: combineFilters(
          excludeAction(labelPass.undoExcludedActions),
          shouldFilterUpdateRegion
        ),
        syncFilter: true,
      }),
      labelTask: labelTask.reducer,
      tools: tools.reducer,
      tasksSeenItems: tasksSeenItems.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        ...[
          storeLabelPass,
          storeTasksSeenItems,
          toolsMiddleware,
          // In tests, we do not have annotation
          drawRegion &&
            annotation &&
            undoRedoMiddleware({ servicesManager, drawRegion, annotation }),
          annotation && drawerMiddleware({ servicesManager, annotation }),
          measurementsMiddleware(servicesManager),
        ].filter(Boolean)
      ),
  });
}

type CreateStoreReturn = ReturnType<typeof createStore>;
export type AppDispatch = CreateStoreReturn['dispatch'];
export type RootState = {
  labelPassState: StateWithHistory<LabelPassState>;
  labelTask: LabelTaskState;
  tools: ToolsState;
  tasksSeenItems: TasksSeenItems;
};
