import { eventsApis } from '@gleamer/apis';
import { Internal, OHIF } from '@gleamer/types';
import { ChannelListener } from '../../utils/ChannelListener';
import { ChannelPublisher } from '../../utils/ChannelPublisher';
import { DocumentEventsListener } from '../../utils/DocumentEventListner';
import { StartStop, combine } from '../../utils/StartStop';

const { pushEvents } = eventsApis;

type UserAuthenticationService = OHIF.UserAuthenticationService;
type DocumentEventType = Internal.DocumentEventType;
type UserEvent = Internal.UserEvent;
type EventType = Internal.EventType;

export class UserActivityService implements StartStop {
  public static REGISTRATION = {
    name: 'userActivityService',
    altName: 'UserActivityService',
    create: ({
      configuration = {},
      servicesManager: {
        services: { UserAuthenticationService },
      },
    }) => {
      return new UserActivityService(UserAuthenticationService);
    },
  };
  private static readonly EVENTS: DocumentEventType[] = [
    'keydown',
    'keyup',
    'mousedown',
    'mouseup',
    'mousemove',
    'wheel',
  ];
  private static readonly CHANNEL = 'user-activity';
  private static readonly SAMPLED_EVENTS: DocumentEventType[] = [
    'mousemove',
    'wheel',
  ];
  private readonly eventListener: StartStop;
  private readonly authorizationHeaderProvider: UserAuthenticationService;
  private beforeUnloadListener = this.onBeforeUnload.bind(null, this);
  private eventsBuffer: UserEvent[] = [];
  private started = false;
  private timer: ReturnType<typeof setInterval> | null = null;
  private taskItemId: { taskId: number; itemId: string } | null = null;

  private constructor(authorizationHeaderProvider: UserAuthenticationService) {
    this.authorizationHeaderProvider = authorizationHeaderProvider;
    const receiver = UserActivityService.receiver(this.onEvent.bind(this));
    const companion = UserActivityService.companion();
    this.eventListener = combine(receiver, companion);
  }

  public static companion(): StartStop {
    const publisher = new ChannelPublisher<UserEvent>(
      UserActivityService.CHANNEL
    );
    const listener = new DocumentEventsListener(
      ev => {
        publisher.publish({
          type: ev.type as EventType,
          timestamp: Date.now(),
        });
      },
      ...UserActivityService.EVENTS
    );
    return combine(listener, publisher);
  }

  private static receiver(onEvent: (ev: UserEvent) => void): StartStop {
    return new ChannelListener<UserEvent>(UserActivityService.CHANNEL, onEvent);
  }

  public start(): void {
    if (this.started) {
      return;
    }
    this.eventListener.start();
    this.timer = setInterval(this.sendEvents.bind(this), 10_000);
    window.addEventListener('beforeunload', this.beforeUnloadListener);
    this.started = true;
  }

  public stop(): void {
    if (!this.started) {
      return;
    }
    this.eventListener.stop();
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.started = false;
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  public sessionStarted(taskId: number, itemId: string): void {
    if (this.taskItemId) {
      throw new Error('Session already started');
    }
    this.taskItemId = { taskId, itemId };
    this.onEvent({ type: 'session-started', timestamp: Date.now() });
  }

  public sessionEnded(): void {
    this.onEvent({ type: 'session-ended', timestamp: Date.now() });
    this.sendEvents();
    this.taskItemId = null;
  }

  private async sendEvents(): Promise<void> {
    if (!this.taskItemId) {
      return;
    }
    const buffer = this.eventsBuffer;
    this.eventsBuffer = [];
    if (buffer.length === 0) {
      buffer.push({ type: 'idle', timestamp: Date.now() });
    }
    const { taskId, itemId } = this.taskItemId;
    const key = `${taskId}-${itemId}`;
    try {
      if (buffer.length === 2) {
        // React rendering can cause a session-started and session-ended event to be sent in quick succession.
        const first = buffer[0];
        const last = buffer[1];
        if (
          first.type === 'session-started' &&
          last.type === 'session-ended' &&
          last.timestamp - first.timestamp < 1000
        ) {
          return;
        }
      }
      await pushEvents(
        this.authorizationHeaderProvider,
        taskId,
        itemId,
        buffer
      );
    } catch (e) {
      const { taskId, itemId } = this.taskItemId;
      const newKey = `${taskId}-${itemId}`;
      if (newKey === key) {
        this.eventsBuffer = buffer.concat(this.eventsBuffer);
      } else {
        console.error('Events could not be sent', buffer);
      }
      throw e;
    }
  }

  private onEvent(ev: UserEvent): void {
    if (!this.started || !this.taskItemId) {
      return;
    }
    if (
      UserActivityService.SAMPLED_EVENTS.includes(ev.type as DocumentEventType)
    ) {
      const lastEvent = this.eventsBuffer?.[this.eventsBuffer.length - 1];
      if (
        lastEvent?.type === ev.type &&
        ev.timestamp - lastEvent.timestamp < 1000
      ) {
        return;
      }
    }
    this.eventsBuffer.push(ev);
  }

  private onBeforeUnload(service: UserActivityService, ev: Event): void {
    service.sessionEnded();
  }
}
