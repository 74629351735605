import { Internal } from '@gleamer/types';
import React, { useCallback, useEffect, useState } from 'react';
import { ReportsByStudyInstanceUID } from '../../contexts/ReportsContext/ReportsContext';
import { useReportsBroadcastChannel } from '../../hooks/useReportsBroadcastChannel';
import { ReportsMessage } from '../organisms/panels/report/constants';
import { SecondaryWindowReportWrapper } from '../organisms/panels/report/secondary/SecondaryWindowReportWrapper';
import { UserActivityService } from '../../services/UserActivityService';

function useBroadcastMessageHandler(callback: (ev: MessageEvent<any>) => any) {
  const bc = useReportsBroadcastChannel();
  useEffect(() => {
    bc.addEventListener('message', callback);

    return () => {
      bc.removeEventListener('message', callback);
    };
  }, [bc, callback]);
}

export function ReportsWindow() {
  const bc = useReportsBroadcastChannel();

  const [patientReports, setPatientReports] = useState([]);
  const [reports, setReports] = useState<ReportsByStudyInstanceUID>({});
  const [activeStudyInstanceUID, setActiveStudyInstanceUID] =
    useState<string>('');
  const [authenticationHeader, setAuthenticationHeader] = useState<HeadersInit>(
    {}
  );
  const [task, setTask] = useState<Internal.LabelTask | null>(null);

  useEffect(() => {
    const companion = UserActivityService.companion();
    companion.start();
    return () => {
      companion.stop();
    };
  }, []);

  useEffect(() => {
    const message: ReportsMessage = {
      type: 'reports::window_created',
    };
    bc.postMessage(message);
  }, [bc]);

  const handleReportsMessage = useCallback(
    ({ data }: MessageEvent<ReportsMessage>) => {
      if (data.type === 'reports::data') {
        setReports(data.payload.reports);
        setPatientReports(data.payload.patientReports);
      }
    },
    []
  );

  const handleActiveStudyMessage = useCallback(
    ({ data }: MessageEvent<ReportsMessage>) => {
      if (data.type === 'reports::active_study') {
        setActiveStudyInstanceUID(data.payload.activeStudyInstanceUID);
      }
    },
    []
  );

  const handleAuthenticationHeaderMessage = useCallback(
    ({ data }: MessageEvent<ReportsMessage>) => {
      if (data.type === 'reports::authentication') {
        setAuthenticationHeader(data.payload.header);
      }
    },
    []
  );

  const handleTaskMessage = useCallback(
    ({ data }: MessageEvent<ReportsMessage>) => {
      if (data.type === 'reports::task') {
        setTask(data.payload.task);
      }
    },
    []
  );

  useBroadcastMessageHandler(handleReportsMessage);
  useBroadcastMessageHandler(handleActiveStudyMessage);
  useBroadcastMessageHandler(handleAuthenticationHeaderMessage);
  useBroadcastMessageHandler(handleTaskMessage);

  return (
    <SecondaryWindowReportWrapper
      activeStudyInstanceUID={activeStudyInstanceUID}
      reports={reports}
      patientReports={patientReports}
      authenticationHeader={authenticationHeader}
      task={task}
    />
  );
}
