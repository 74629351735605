import { labelPass, RootState } from '@gleamer/ui';
import classnames from 'classnames';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { ObservationsIndicators } from './ObservationsIndicators';

type InstanceThumbnailProps = {
  sopInstanceUID: string;
  index: number;
  onDoubleClick: () => any;
  thumbnailSrc: string;
  dragData: any;
};

export function InstanceThumbnail({
  sopInstanceUID,
  index,
  onDoubleClick,
  thumbnailSrc,
  dragData,
}: InstanceThumbnailProps) {
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: {
      ...dragData,
      imageIndex: index,
    },
    canDrag: true,
  });

  const matchingObservations = useSelector((state: RootState) => {
    const observations = labelPass.selectors.getObservations(state);
    return observations.filter(obs => {
      return obs.regions.some(
        region => region.SOPInstanceUID === sopInstanceUID
      );
    });
  });

  return (
    <div
      key={sopInstanceUID}
      ref={drag}
      className={classnames(
        'm-1 flex flex-1 items-center justify-center rounded-md bg-black text-base text-white min-h-32 border border-secondary-light group-focus:border-blue-300 hover:border-blue-300 relative'
      )}
      id={`thumbnail-${sopInstanceUID}`}
      role="button"
      onDoubleClick={onDoubleClick}
      data-instance-index={index}
    >
      <ObservationsIndicators
        observations={matchingObservations}
        className="bottom-[-0.5rem] left-0"
      />
      {thumbnailSrc ? (
        <img src={thumbnailSrc} className="object-none min-h-32 rounded-md" />
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}
