import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

export function PageButton({
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={classNames(
        'mx-1 cursor-pointer rounded border border-gray-200 px-2 py-1 disabled:cursor-auto disabled:bg-gray-200',
        className
      )}
      {...props}
    />
  );
}
