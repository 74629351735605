import {
  Accordion,
  ActionButton,
  ActionButtonProps,
  Button,
  PrimaryButton,
  RangeSlider,
} from './components';
import * as redux from './contexts/ReduxProvider';
import { READONLY_MODES, isReadonly } from './readonly-state';

const useObservationSubmissions = redux.labelPass.hooks.useObservationSubmissions;
const useHasUnsavedChanges = redux.labelPass.hooks.useHasUnsavedChanges;
const useUpdateAssetCharacterisations = redux.labelPass.hooks.useUpdateAssetCharacterisations;
const useAssetCharacterisations = redux.labelPass.hooks.useAssetCharacterisations;
const useHasPreviousUnsavedChanges = redux.labelPass.hooks.useHasPreviousUnsavedChanges;
const useLabelPassSubmission = redux.labelPass.hooks.useLabelPassSubmission;
const useFeedbacks = redux.labelPass.hooks.useFeedbacks;

const useIsLastSeenItem = redux.tasksSeenItems.hooks.useIsLastSeenItem;
const useNextItemId = redux.tasksSeenItems.hooks.useNextItemId;
const usePreviousItemId = redux.tasksSeenItems.hooks.usePreviousItemId;

const useTask = redux.labelTask.hooks.useTask;
const useTaskDoneLoading = redux.labelTask.hooks.useTaskDoneLoading;

const loadFromCompressedLocalStorage = redux.loadFromCompressedLocalStorage;

export * from './contexts/ReduxProvider';
export {
  Accordion,
  ActionButton,
  Button,
  PrimaryButton,
  RangeSlider,
  READONLY_MODES,
  isReadonly,
  loadFromCompressedLocalStorage,
  useAssetCharacterisations,
  useFeedbacks,
  useHasPreviousUnsavedChanges,
  useHasUnsavedChanges,
  useIsLastSeenItem,
  useLabelPassSubmission,
  useNextItemId,
  useObservationSubmissions,
  usePreviousItemId,
  useTask,
  useTaskDoneLoading,
  useUpdateAssetCharacterisations,
};
export type { ActionButtonProps };

export * as utils from './utils';
