import { addTool, annotation } from '@cornerstonejs/tools';
import { Keypoint } from './utils/measurementServiceMappings/Keypoint';
import { Rectangle } from './utils/measurementServiceMappings/Rectangle';
import GleamerKeypointTool from './utils/tools/GleamerKeypointTool';
import GleamerRectangleTool from './utils/tools/GleamerRectangleTool';

const CORNERSTONE_3D_TOOLS_SOURCE_NAME = 'Cornerstone3DTools';
const CORNERSTONE_3D_TOOLS_SOURCE_VERSION = '0.1';

const measurementServiceRectangleFactory = (
  MeasurementService,
  DisplaySetService,
  CornerstoneViewportService
) => {
  /**
   * Maps measurement service format object to cornerstone annotation object.
   *
   * @param {Measurement} measurement The measurement instance
   * @param {string} definition The source definition
   * @return {Object} Cornerstone annotation data
   */

  return {
    toAnnotation: Rectangle.toAnnotation,
    toMeasurement: csToolsAnnotation =>
      Rectangle.toMeasurement(
        csToolsAnnotation,
        DisplaySetService,
        CornerstoneViewportService,
        () => MeasurementService.VALUE_TYPES.RECTANGLE
      ),
    matchingCriteria: [
      {
        valueType: MeasurementService.VALUE_TYPES.RECTANGLE,
      },
    ],
  };
};

const measurementServiceKeypointFactory = (
  MeasurementService,
  DisplaySetService,
  CornerstoneViewportService
) => {
  /**
   * Maps measurement service format object to cornerstone annotation object.
   *
   * @param {Measurement} measurement The measurement instance
   * @param {string} definition The source definition
   * @return {Object} Cornerstone annotation data
   */

  return {
    toAnnotation: Keypoint.toAnnotation,
    toMeasurement: csToolsAnnotation =>
      Keypoint.toMeasurement(
        csToolsAnnotation,
        DisplaySetService,
        CornerstoneViewportService,
        () => MeasurementService.VALUE_TYPES.POINT
      ),
    matchingCriteria: [
      {
        valueType: MeasurementService.VALUE_TYPES.POINT,
      },
    ],
  };
};

export const initMeasurementService = (
  MeasurementService,
  DisplaySetService,
  CornerstoneViewportService
) => {
  /* Initialization */
  const csTools3DVer1MeasurementSource = MeasurementService.createSource(
    CORNERSTONE_3D_TOOLS_SOURCE_NAME,
    CORNERSTONE_3D_TOOLS_SOURCE_VERSION
  );

  const defaultStyles = annotation.config.style.getDefaultToolStyles();

  annotation.config.style.setDefaultToolStyles({
    global: {
      ...defaultStyles.global,
      textBoxFontSize: '1',
      textBoxLinkLineWidth: '0',
    },
  });

  const RectangleAnnotate = measurementServiceRectangleFactory(
    MeasurementService,
    DisplaySetService,
    CornerstoneViewportService
  );

  const KeypointAnnotate = measurementServiceKeypointFactory(
    MeasurementService,
    DisplaySetService,
    CornerstoneViewportService
  );

  /* Mappings */
  MeasurementService.addMapping(
    csTools3DVer1MeasurementSource,
    GleamerRectangleTool.toolName,
    RectangleAnnotate.matchingCriteria,
    RectangleAnnotate.toAnnotation,
    RectangleAnnotate.toMeasurement
  );

  MeasurementService.addMapping(
    csTools3DVer1MeasurementSource,
    GleamerKeypointTool.toolName,
    KeypointAnnotate.matchingCriteria,
    KeypointAnnotate.toAnnotation,
    KeypointAnnotate.toMeasurement
  );

  return csTools3DVer1MeasurementSource;
};

export function initCustomTools() {
  addTool(GleamerRectangleTool);
  addTool(GleamerKeypointTool);
}
