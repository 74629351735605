import { Internal } from '@gleamer/types';
import { compact, sortBy, uniq, uniqueId } from 'lodash';

type DicomJSONInstance = Internal.DicomJSONInstance;
type DicomJSONSeries = Internal.DicomJSONSeries;
type DicomJSONStudy = Internal.DicomJSONStudy;
type LabelTaskApiItem = Internal.LabelTaskApiItem;
type LabelTaskItem = Internal.LabelTaskItem;

function getFieldFromInstances<T>(
  instances: DicomJSONInstance[],
  field: string,
  defaultValue: T = null
): T {
  return (
    instances.find(({ metadata }) => metadata[field])?.metadata[field] ??
    defaultValue
  );
}

function extractSeries(instances: DicomJSONInstance[]): DicomJSONSeries[] {
  const series = new Map<string, Partial<DicomJSONSeries>>();

  instances.forEach(instance => {
    const { metadata } = instance;
    const { SeriesInstanceUID } = metadata;

    if (series.has(SeriesInstanceUID)) {
      series.get(SeriesInstanceUID).instances.push(instance);
    } else {
      const newSeries = {
        SeriesInstanceUID,
        instances: [instance],
      };
      series.set(SeriesInstanceUID, newSeries);
    }
  });

  // @ts-ignore
  for (const [SeriesInstanceUID, currentSeries] of series) {
    currentSeries.SeriesInstanceUID = SeriesInstanceUID;
    currentSeries.SeriesNumber = getFieldFromInstances(
      currentSeries.instances,
      'SeriesNumber'
    );
    currentSeries.Modality = getFieldFromInstances(
      currentSeries.instances,
      'Modality'
    );
    currentSeries.SliceThickness = getFieldFromInstances(
      currentSeries.instances,
      'SliceThickness'
    );
    const ViewCodeValueInSeries = compact(
      uniq(
        currentSeries.instances.map(({ metadata }) => metadata.ViewCodeValue)
      )
    );
    const ProcedureCodeValueInSeries = compact(
      uniq(
        currentSeries.instances.flatMap(
          ({ metadata }) => metadata.ProcedureCodeValue
        )
      )
    );
    const ImageTypeInSeries = compact(
      uniq(
        currentSeries.instances.flatMap(({ metadata }) => metadata.ImageType)
      )
    );
    currentSeries.instances.forEach(({ metadata }) => {
      metadata.ViewCodeValueInSeries = ViewCodeValueInSeries;
      metadata.ProcedureCodeValueInSeries = ProcedureCodeValueInSeries;
      metadata.ImageTypeInSeries = ImageTypeInSeries;
    });
  }

  return Array.from(series.values()) as DicomJSONSeries[];
}

function extractCodeValues(
  sequence?: {
    CodeValue: string;
  }[]
): string[] {
  return compact(uniq((sequence || []).map(item => item.CodeValue)));
}

function extractStudies(instances: DicomJSONInstance[]): DicomJSONStudy[] {
  const studies = new Map<
    string,
    Partial<DicomJSONStudy> & {
      instances: DicomJSONInstance[];
    }
  >();

  instances.forEach(instance => {
    const { metadata } = instance;
    const { StudyInstanceUID } = metadata;

    if (studies.has(StudyInstanceUID)) {
      studies.get(StudyInstanceUID).instances.push(instance);
    } else {
      const study = {
        StudyInstanceUID,
        instances: [instance],
      };
      studies.set(StudyInstanceUID, study);
    }
  });

  // @ts-ignore
  for (const [StudyInstanceUID, study] of studies) {
    const modalities = compact(
      uniq(study.instances.map(({ metadata }) => metadata.Modality))
    );
    study.instances.forEach(({ metadata }) => {
      metadata.ProcedureCodeValue = extractCodeValues(
        metadata.ProcedureCodeSequence
      );
      metadata.ViewCodeValue = extractCodeValues(metadata.ViewCodeSequence);
    });
    study.StudyInstanceUID = StudyInstanceUID;
    study.ModalitiesInStudy = modalities;
    study.StudyDate = getFieldFromInstances(study.instances, 'StudyDate');
    study.StudyTime = getFieldFromInstances(study.instances, 'StudyTime');
    study.PatientName = getFieldFromInstances(study.instances, 'PatientName');
    study.PatientID = getFieldFromInstances(study.instances, 'PatientID');
    study.AccessionNumber = getFieldFromInstances(
      study.instances,
      'AccessionNumber'
    );
    study.PatientAge = getFieldFromInstances(study.instances, 'PatientAge');
    study.PatientSex = getFieldFromInstances(study.instances, 'PatientSex');
    study.NumInstances = study.instances.length;
    study.series = extractSeries(study.instances);

    study.instances.forEach(({ metadata }) => {
      metadata.ModalitiesInStudy = study.ModalitiesInStudy;
      metadata.ImageType = compact(metadata.ImageType);
    });

    delete study.instances;
  }

  return sortBy(Array.from(studies.values()), 'StudyDate') as DicomJSONStudy[];
}

const resolves = {};
const rejects = {};

const workers = new Array(Math.min(5, window.navigator.hardwareConcurrency))
  .fill(1)
  .map(() => {
    // URL cannot be set as a module constant
    const worker = new Worker(
      new URL(
        './formatInstance.ts',
        // @ts-ignore
        import.meta.url
      ),
      { type: 'module' }
    );
    worker.addEventListener('message', handleInstanceFormattedMessage);
    return worker;
  });

async function sendFormatInstanceMessage(
  instance: Record<string, any>,
  worker: Worker
): Promise<DicomJSONInstance> {
  return new Promise((resolve, reject) => {
    const id = uniqueId('format-instance-');
    resolves[id] = resolve;
    rejects[id] = reject;
    worker.postMessage({ id, instance: JSON.stringify(instance) });
  });
}

function handleInstanceFormattedMessage({ data }: MessageEvent) {
  const { id, instance, error } = data;

  if (error) {
    rejects[id](error);
  } else {
    resolves[id](JSON.parse(instance));
  }

  delete resolves[id];
  delete rejects[id];
}

export async function formatItem(
  input: LabelTaskApiItem
): Promise<LabelTaskItem> {
  const { instances: inputInstances, ...item } = input;

  const formattedInstances = await Promise.all(
    inputInstances.map((instance, index) => {
      const usedWorker = workers[index % workers.length];
      return sendFormatInstanceMessage(instance, usedWorker);
    })
  );

  const studies = extractStudies(formattedInstances);

  return {
    ...item,
    studies,
  };
}
