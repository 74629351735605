import { Internal } from '@gleamer/types';
import { Enums } from '@cornerstonejs/core';
import { Types } from '@ohif/core';

type VolumeOrientation = Internal.VolumeOrientation;

export const VOLUME_ORIENTATIONS: VolumeOrientation[] = [
  Enums.OrientationAxis.AXIAL,
  Enums.OrientationAxis.SAGITTAL,
  Enums.OrientationAxis.CORONAL,
];

export type Layout = {
  main: VolumeOrientation;
  top: VolumeOrientation;
  bottom: VolumeOrientation;
};

export function isMprProtocol(protocolId: string): boolean {
  return protocolId.startsWith(
    '@gleamer/gdata-extension.hangingProtocolModule.mpr'
  );
}

const POSSIBLE_LAYOUTS: Layout[] = [
  {
    main: 'axial',
    top: 'sagittal',
    bottom: 'coronal',
  },
  {
    main: 'axial',
    top: 'coronal',
    bottom: 'sagittal',
  },
  {
    main: 'sagittal',
    top: 'axial',
    bottom: 'coronal',
  },
  {
    main: 'sagittal',
    top: 'coronal',
    bottom: 'axial',
  },
  {
    main: 'coronal',
    top: 'axial',
    bottom: 'sagittal',
  },
  {
    main: 'coronal',
    top: 'sagittal',
    bottom: 'axial',
  },
];

export function getMPRLayoutId(layout: Layout) {
  return `@gleamer/gdata-extension.hangingProtocolModule.mpr-${layout.main}-${layout.top}-${layout.bottom}`;
}

function generateMPR(layout: Layout): Types.HangingProtocol.Protocol {
  const protocolId = getMPRLayoutId(layout);
  return {
    id: protocolId,
    hasUpdatedPriorsInformation: false,
    name: 'mpr',
    protocolMatchingRules: [],
    imageLoadStrategy: 'nth',
    callbacks: {
      // Switches out of MPR mode when the layout change button is used
      onLayoutChange: [
        {
          commandName: 'toggleHangingProtocol',
          commandOptions: { protocolId },
          context: 'DEFAULT',
        },
      ],
      // Turns off crosshairs when switching out of MPR mode
      onProtocolExit: [
        {
          commandName: 'toolbarServiceRecordInteraction',
          commandOptions: {
            interactionType: 'tool',
            commands: [
              {
                commandOptions: {
                  toolName: 'WindowLevel',
                },
                context: 'CORNERSTONE',
              },
            ],
          },
        },
      ],
    },
    displaySetSelectors: {
      activeDisplaySet: {
        seriesMatchingRules: [
          {
            id: 'isReconstructable',
            weight: 1,
            attribute: 'isReconstructable',
            constraint: {
              equals: {
                value: true,
              },
            },
            required: true,
          },
        ],
      },
    },
    stages: [
      {
        id: 'mpr3Stage',
        name: 'mpr',
        viewportStructure: {
          layoutType: 'grid',
          properties: {
            rows: 2,
            columns: 3,
            layoutOptions: [
              {
                x: 0,
                y: 0,
                width: 2 / 3,
                height: 1,
              },
              {
                x: 2 / 3,
                y: 0,
                width: 1 / 3,
                height: 1 / 2,
              },
              {
                x: 2 / 3,
                y: 1 / 2,
                width: 1 / 3,
                height: 1 / 2,
              },
            ],
          },
        },
        viewports: [
          {
            viewportOptions: {
              toolGroupId: 'mpr',
              viewportType: 'volume',
              orientation: layout.main,
              initialImageOptions: {
                preset: 'middle',
              },
              syncGroups: [
                {
                  type: 'voi',
                  id: 'mpr',
                  source: true,
                  target: true,
                },
              ],
            },
            displaySets: [
              {
                id: 'activeDisplaySet',
              },
            ],
          },
          {
            viewportOptions: {
              toolGroupId: 'mpr',
              viewportType: 'volume',
              orientation: layout.top,
              initialImageOptions: {
                preset: 'middle',
              },
              syncGroups: [
                {
                  type: 'voi',
                  id: 'mpr',
                  source: true,
                  target: true,
                },
              ],
            },
            displaySets: [
              {
                id: 'activeDisplaySet',
              },
            ],
          },
          {
            viewportOptions: {
              toolGroupId: 'mpr',
              viewportType: 'volume',
              orientation: layout.bottom,
              initialImageOptions: {
                preset: 'middle',
              },
              syncGroups: [
                {
                  type: 'voi',
                  id: 'mpr',
                  source: true,
                  target: true,
                },
              ],
            },
            displaySets: [
              {
                id: 'activeDisplaySet',
              },
            ],
          },
        ],
      },
    ],
  };
}

export function generateMPRProtocols(): Types.HangingProtocol.Protocol[] {
  return POSSIBLE_LAYOUTS.map(generateMPR);
}
