export const NO_MORE_ITEMS_ERROR = 'NoMoreItemsError';
export const NOT_FOUND_ERROR = 'NotFoundError';

export class NoMoreItemsError extends Error {
  constructor() {
    super('No more items');
    this.name = NO_MORE_ITEMS_ERROR;
  }
}

export function isNoMoreItemsError(error: unknown): boolean {
  if (error instanceof Error) {
    return error.name === NO_MORE_ITEMS_ERROR;
  }
  return false;
}

export class NotFoundError extends Error {
  constructor() {
    super('Not found');
    this.name = NOT_FOUND_ERROR;
  }
}
