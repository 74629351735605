import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TaskAndItemId } from './types';
import formatISO from 'date-fns/formatISO';
import { isAfter, parseISO, sub } from 'date-fns';

export type SeenItem = {
  id: string;
  date: string;
};

export type TasksSeenItems = {
  [key: number]: SeenItem[];
};

const initialState: TasksSeenItems = {};

const tasksSeenItemsSlice = createSlice({
  name: 'tasksSeenItems',
  initialState: () => {
    const tasksSeenItems = localStorage.getItem('tasksSeenItems');
    if (tasksSeenItems) {
      return JSON.parse(tasksSeenItems);
    }
    return initialState;
  },
  reducers: {
    addItem: (state, action: PayloadAction<TaskAndItemId>) => {
      const { taskId, itemId } = action.payload;
      if (!taskId || !itemId) {
        return;
      }

      const date = formatISO(new Date());

      if (state[taskId]) {
        const seen = state[taskId].find(item => item.id === itemId);
        if (!seen) {
          state[taskId].push({ id: itemId, date });
        }
      } else {
        state[taskId] = [{ id: itemId, date }];
      }

      const sevenDaysAgo = sub(new Date(), { days: 7 });
      for (const taskId in state) {
        state[taskId] = state[taskId].filter(item => {
          return isAfter(parseISO(item.date), sevenDaysAgo);
        });
      }
    },

    clearPreviousItems: state => {
      state = {};
    },
  },
});

export const actions = tasksSeenItemsSlice.actions;
export const reducer = tasksSeenItemsSlice.reducer;
