import { annotation } from '@cornerstonejs/tools';

const { getAnnotations: csGetAnnotations, getAnnotationManager } =
  annotation.state;

export default function getAnnotations(
  toolName: string,
  element: HTMLDivElement
) {
  const manager = getAnnotationManager();
  const annotationGroup = manager.getGroupKey(element);
  let annotations;
  if (annotationGroup) {
    annotations = csGetAnnotations(toolName, annotationGroup);
  } else {
    annotations = manager
      .getAllAnnotations()
      .filter(
        annotation =>
          !annotation.metadata.FrameOfReferenceUID &&
          annotation.metadata.toolName === toolName
      );
  }
  return annotations;
}
