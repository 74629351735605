import { Types } from '@ohif/core';
import React from 'react';
import { GleamerViewerLayout } from './components/organisms/ViewerLayout/GleamerViewerLayout';
import { CompositeToolbarButton } from './components/organisms/toolbar/CompositeToolbarButton';
import GleamerViewport from './components/organisms/viewports/GleamerViewport';
import { useReports } from './contexts/ReportsContext';
import { getCommandsModule } from './getCommandsModule';
import { getContextModule } from './getContextModule';
import { getDataSourcesModule } from './getDataSourcesModule';
import { getHangingProtocolModule } from './getHangingProtocolModule';
import { ANNOTATION_PANEL_NAME, getPanelModule } from './getPanelModule';
import getSopClassHandlerModule from './getSopClassHandlerModule';
import { id } from './id';
import { initCustomTools, initMeasurementService } from './init';
import { initDefaultValues } from './initDefaultValues';
import { DicomJSONService } from './services/DicomJSONService';
import { ReduxStoreService } from './services/ReduxStoreService';
import { UserActivityService } from './services/UserActivityService';
import { drawRegion } from './utils/drawRegion';
import GleamerKeypointTool from './utils/tools/GleamerKeypointTool';
import GleamerRectangleTool from './utils/tools/GleamerRectangleTool';
import * as mouseBindings from './utils/tools/mouse-bindings';
import * as utils from './utils/utils';

export * from './components';
export { gdataRouteInit } from './gdataRouteInit';
export { getMPRLayoutId } from './hanging-protocols/mpr';
export { useCurrentDisplaySet } from './hooks/useCurrentDisplaySet';
export { useCurrentItem } from './hooks/useCurrentItem';
export { useReviewSession } from './hooks/useReviewSession';
export { useReports };

let pubsubSubscriptions = [];

/**
 * You can remove any of the following modules if you don't need them.
 */
const gdataExtension: Types.Extensions.Extension = {
  /**
   * Only required property. Should be a unique value across all extensions.
   * You ID can be anything you want, but it should be unique.
   */
  id,
  onModeEnter: ({ servicesManager }) => {
    const { panelService, measurementService } = servicesManager.services;

    pubsubSubscriptions = panelService.addActivatePanelTriggers(
      `${id}.panelModule.${ANNOTATION_PANEL_NAME}`,
      [
        {
          sourcePubSubService: measurementService,
          sourceEvents: [
            measurementService.EVENTS.MEASUREMENT_ADDED,
            measurementService.EVENTS.RAW_MEASUREMENT_ADDED,
          ],
        },
      ],
      true
    );
  },

  onModeExit: ({ servicesManager }) => {
    pubsubSubscriptions.forEach(sub => sub.unsubscribe());
    pubsubSubscriptions = [];
  },

  /**
   * Perform any pre-registration tasks here. This is called before the extension
   * is registered. Usually we run tasks such as: configuring the libraries
   * (e.g. cornerstone, cornerstoneTools, ...) or registering any services that
   * this extension is providing.
   */
  preRegistration: async ({
    servicesManager,
    commandsManager,
    configuration = {},
  }) => {
    servicesManager.registerServices([
      ReduxStoreService.REGISTRATION,
      DicomJSONService.REGISTRATION,
      UserActivityService.REGISTRATION,
    ]);

    const {
      stateSyncService,
      measurementService,
      displaySetService,
      cornerstoneViewportService,
    } = servicesManager.services;

    stateSyncService.register('previousHangingProtocol', {
      clearOnModeExit: true,
    });
    stateSyncService.register('taskLayout', {
      clearOnModeExit: true,
    });
    stateSyncService.register('previousPresentationId', {
      clearOnModeExit: true,
    });
    stateSyncService.register('slabThickness', {
      clearOnModeExit: true,
    });

    initCustomTools();
    initMeasurementService(
      measurementService,
      displaySetService,
      cornerstoneViewportService
    );

    initDefaultValues(servicesManager);
  },
  /**
   * PanelModule should provide a list of panels that will be available in OHIF
   * for Modes to consume and render. Each panel is defined by a {name,
   * iconName, iconLabel, label, component} object. Example of a panel module
   * is the StudyBrowserPanel that is provided by the default extension in OHIF.
   */
  getPanelModule,
  /**
   * ViewportModule should provide a list of viewports that will be available in OHIF
   * for Modes to consume and use in the viewports. Each viewport is defined by
   * {name, component} object. Example of a viewport module is the CornerstoneViewport
   * that is provided by the Cornerstone extension in OHIF.
   */
  getViewportModule: ({
    servicesManager,
    commandsManager,
    extensionManager,
  }) => {
    return [
      {
        name: 'gleamer-viewport',
        component: function GleamerViewportWithServices(props) {
          return (
            <GleamerViewport
              servicesManager={servicesManager}
              commandsManager={commandsManager}
              extensionManager={extensionManager}
              {...props}
            />
          );
        },
      },
    ];
  },

  /**
   * LayoutTemplateMOdule should provide a list of layout templates that will be
   * available in OHIF for Modes to consume and use to layout the viewer.
   * Each layout template is defined by a { name, id, component}. Examples include
   * the default layout template provided by the default extension which renders
   * a Header, left and right sidebars, and a viewport section in the middle
   * of the viewer.
   */
  getLayoutTemplateModule: ({
    servicesManager,
    commandsManager,
    extensionManager,
    hotkeysManager,
  }) => {
    return [
      {
        name: 'gleamer-viewer-layout',
        id: 'gleamer-viewer-layout',
        component: function GleamerViewerLayoutWithServices(props) {
          return (
            <GleamerViewerLayout
              servicesManager={servicesManager}
              commandsManager={commandsManager}
              extensionManager={extensionManager}
              hotkeysManager={hotkeysManager}
              {...props}
            />
          );
        },
      },
    ];
  },
  /**
   * SopClassHandlerModule should provide a list of sop class handlers that will be
   * available in OHIF for Modes to consume and use to create displaySets from Series.
   * Each sop class handler is defined by a { name, sopClassUids, getDisplaySetsFromSeries}.
   * Examples include the default sop class handler provided by the default extension
   */
  getSopClassHandlerModule,
  /**
   * HangingProtocolModule should provide a list of hanging protocols that will be
   * available in OHIF for Modes to use to decide on the structure of the viewports
   * and also the series that hung in the viewports. Each hanging protocol is defined by
   * { name, protocols}. Examples include the default hanging protocol provided by
   * the default extension that shows 2x2 viewports.
   */
  getHangingProtocolModule,
  /**
   * CommandsModule should provide a list of commands that will be available in OHIF
   * for Modes to consume and use in the viewports. Each command is defined by
   * an object of { actions, definitions, defaultContext } where actions is an
   * object of functions, definitions is an object of available commands, their
   * options, and defaultContext is the default context for the command to run against.
   */
  getCommandsModule,
  /**
   * ContextModule should provide a list of context that will be available in OHIF
   * and will be provided to the Modes. A context is a state that is shared OHIF.
   * Context is defined by an object of { name, context, provider }. Examples include
   * the measurementTracking context provided by the measurementTracking extension.
   */
  getContextModule,
  /**
   * DataSourceModule should provide a list of data sources to be used in OHIF.
   * DataSources can be used to map the external data formats to the OHIF's
   * native format. DataSources are defined by an object of { name, type, createDataSource }.
   */
  getDataSourcesModule,

  getCustomizationModule: () => {},

  getUtilityModule: () => {
    return [
      {
        name: 'tools',
        exports: {
          gdataToolNames: {
            [GleamerRectangleTool.toolName]: GleamerRectangleTool.toolName,
            [GleamerKeypointTool.toolName]: GleamerKeypointTool.toolName,
          },
        },
      },
      {
        name: 'utils',
        exports: {
          utils,
        },
      },
      {
        name: 'mouseBindings',
        exports: {
          mouseBindings,
        },
      },
      {
        name: 'drawing',
        exports: {
          drawRegion,
        },
      },
    ];
  },

  getToolbarModule: ({ servicesManager }) => {
    return [
      {
        name: 'gleamer.composite',
        defaultComponent: props => (
          <CompositeToolbarButton
            servicesManager={servicesManager}
            {...props}
          />
        ),
        clickHandler: () => {},
      },
    ];
  },
};

export default gdataExtension;
