import { format } from 'date-fns';
import { Internal } from '@gleamer/types';
type EnhancedDicomJSONReport = Internal.EnhancedDicomJSONReport;

const ISO_FORMAT = 'yyyy-MM-dd';

function iso(date: Date | undefined) {
  try {
    return date ? format(date, ISO_FORMAT) : '';
  } catch (error) {
    return '';
  }
}

export function getReportKey(report: EnhancedDicomJSONReport) {
  const assignedNumber = report.StudyInstanceUID ? 0 : 1;
  return `${iso(report.date) || 'z-' + report.name}-${assignedNumber}-${
    report.type
  }-${report.id}`;
}

export function byReportKey(
  r1: EnhancedDicomJSONReport,
  r2: EnhancedDicomJSONReport
) {
  return getReportKey(r1).localeCompare(getReportKey(r2));
}
