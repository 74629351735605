import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, PropsWithChildren, useState } from 'react';

type AlertLevel = 'error' | 'default';

type AlertDialogProps = PropsWithChildren<{
  level?: AlertLevel;
  onClose?: () => void;
}>;

export function AlertDialog({
  children,
  level = 'default',
  onClose,
}: AlertDialogProps) {
  const [isOpen, setIsOpen] = useState(true);

  const onClick = () => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <Dialog
        open={isOpen}
        onClose={onClick}
        className="absolute top-0 left-0 z-50 w-full"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
        <div className="relative flex min-h-full items-center justify-center py-20 px-4 text-center">
          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg p-6 text-left align-middle shadow-xl transition-all bg-white">
            <div className="mt-2">{children}</div>
            <div className="mt-4">
              <button
                type="button"
                className={classNames(
                  'inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
                  {
                    'bg-gray-300 text-gray-900 hover:bg-gray-400 focus-visible:ring-gray-500':
                      level === 'default',
                    'bg-red-300 text-red-900 hover:bg-red-400 focus-visible:ring-red-500':
                      level === 'error',
                  }
                )}
                onClick={onClick}
              >
                OK
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
