import React from 'react';
import { Provider } from 'react-redux';
import { UserActivityTaskListener } from './components/organisms/TaskListener';
import { ObservationContextProvider } from './contexts/ObservationContext/ObservationContext';
import {
  OverlayStateContext,
  OverlayStateContextProvider,
} from './contexts/OverlayStateContext/OverlayStateContext';
import {
  ReportsContext,
  ReportsContextProvider,
} from './contexts/ReportsContext';

export function getContextModule({
  servicesManager,
  commandsManager,
  extensionManager,
}) {
  // Be careful, the order matters
  return [
    {
      name: 'ReduxProvider',
      provider: ({ children }) => {
        const { ReduxStoreService } = servicesManager.services;
        const store = ReduxStoreService.getStore();
        return <Provider store={store}>{children}</Provider>;
      },
    },
    {
      name: 'ObservationContext',
      provider: ({ children }) => {
        return (
          <ObservationContextProvider servicesManager={servicesManager}>
            {children}
          </ObservationContextProvider>
        );
      },
    },
    {
      name: 'ReportsContext',
      context: ReportsContext,
      provider: ({ children }) => {
        return (
          <ReportsContextProvider servicesManager={servicesManager}>
            {children}
          </ReportsContextProvider>
        );
      },
    },
    {
      name: 'OverlayStateContext',
      context: OverlayStateContext,
      provider: ({ children }) => {
        return (
          <OverlayStateContextProvider>{children}</OverlayStateContextProvider>
        );
      },
    },
    {
      name: 'UserActivityTaskListener',
      provider: ({ children }) => {
        return (
          <UserActivityTaskListener servicesManager={servicesManager}>
            {children}
          </UserActivityTaskListener>
        );
      },
    },
  ];
}
