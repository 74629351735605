import { IWebApiDataSource, ServicesManager } from '@ohif/core';

type GenerateDicomJsonDatasourceParams = {
  servicesManager: ServicesManager;
  name: string;
  initialize: (args: { query: URLSearchParams }) => Promise<string[]>;
  getStorageKey: (args?: { query: URLSearchParams }) => string;
};

export function generateDicomJsonDatasource({
  servicesManager,
  name,
  initialize,
  getStorageKey,
}: GenerateDicomJsonDatasourceParams) {
  const { DicomJSONService } = servicesManager.services;
  return {
    name,
    type: 'jsonApi',
    createDataSource: datasourceConfiguration => {
      const implementation = {
        initialize,
        query: {
          studies: {
            mapParams: () => {},
            search: async param => {
              return DicomJSONService.queryStudies(getStorageKey(), param);
            },
            processResults: () => {
              console.debug(' DICOMJson QUERY processResults');
            },
          },
          series: {
            // mapParams: mapParams.bind(),
            search: () => {
              console.debug(' DICOMJson QUERY SERIES SEARCH');
            },
          },
          instances: {
            search: () => {
              console.debug(' DICOMJson QUERY instances SEARCH');
            },
          },
        },
        retrieve: {
          series: {
            metadata: ({ StudyInstanceUID, madeInClient = false, customSort } = {}) => {
              return DicomJSONService.retrieveSeriesMetadata(getStorageKey(), {
                StudyInstanceUID,
                madeInClient,
                customSort,
              });
            },
          },
        },
        store: {
          dicom: () => {
            console.debug(' DICOMJson store dicom');
          },
        },
        getImageIdsForDisplaySet(displaySet) {
          return DicomJSONService.getImageIdsForDisplaySet(displaySet, datasourceConfiguration);
        },
        getImageIdsForInstance({ instance, frame }) {
          return DicomJSONService.getImageIdsForInstance({ instance, frame });
        },
        getStudyInstanceUIDs({ query }) {
          return DicomJSONService.getStudyInstanceUIDs(getStorageKey({ query }));
        },
      };
      return IWebApiDataSource.create(implementation);
    },
  };
}
